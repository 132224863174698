import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { IoMdPaperPlane } from "react-icons/io";

const ResendAccountConfirmModal: React.FC<{
    receiverAccountToResendConfirm: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, receiverAccountToResendConfirm, setSwalProps }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const closeModal = async () => {
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const postData = {
                account_id: receiverAccountToResendConfirm.id,
                username: localStorage.getItem("username"),
                userRole: localStorage.getItem("userRole")?.toLowerCase(),
                fn: "account"
            }

            await axios.post(
                process.env.REACT_APP_API_URL + `/api/receiver/resend`,
                postData
            )
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Account confirmation has been successfully resent",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const fieldsRendering = (fields: Record<string, any>) => {
        const renderInput = (fieldName: string, fieldValue: any) => {
            const capitalizeLabel = (label: string) => {
                let modifiedLabel = label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiedLabel = "BSB";
                        break;
                    case "bic":
                        modifiedLabel = "BIC";
                        break;
                    case "iban":
                        modifiedLabel = "IBAN";
                        break;
                }

                return modifiedLabel;
            };

            return (
                <div className="mb-1" key={fieldName}>
                    <span className="mr-1 font-bold">{capitalizeLabel(fieldName)}:</span> {fieldValue}
                </div>
            );
        };

        return Object.entries(fields).map(([key, value]) => renderInput(key, value));
    };

    return (
        <>
            {receiverAccountToResendConfirm &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to resend confirmation link?</h1>
                                <div className="mb-[20px] text-sm">
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Asset:</span> {receiverAccountToResendConfirm.asset}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Method:</span> {receiverAccountToResendConfirm.method_name}
                                    </div>
                                    {fieldsRendering(receiverAccountToResendConfirm.method_details)}
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        loadingText='Sending...'
                                        className="btn btn-primary">
                                        <IoMdPaperPlane className="h-4 w-4" />&nbsp;Resend
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default ResendAccountConfirmModal;
