/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { Spinner } from "@chakra-ui/react";
import Layout from "layouts/layout";
import PublicLayout from "layouts/public";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useRoutes from "../routes";
import NotFound from "../views/public/NotFound";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Footer from "components/footer/FooterAuthDefault";
import { ProfileContext } from "../store/ProfileProvider";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type Route = {
  isSideBar: boolean;
  layout: string;
  name: string;
  path: string;
};

export const ProtectedRoute = ({ ...rest }) => {
  const {
    isAuthenticated,
    isAuthenticating,
    checkAuthStatus,
    fetchPermissions,
    updateAuthStatus,
  } = useAuth();
  const { profileTimeZone } = useContext(ProfileContext);
  const location = useLocation();
  const userRole = localStorage.getItem("userRole");
  const roleRoute = userRole ? userRole.toLowerCase() : '';
  const merchantId = localStorage.getItem("merchantId");
  const subaccountId = localStorage.getItem("subaccountId");
  const apiToken = localStorage.getItem("apiToken");
  const [userPermissions, setUserPermissions] = useState([]);
  const { routes } = useRoutes(userPermissions);
  const navigate = useNavigate();

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + apiToken;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {

    if (!location.pathname.includes("/confirm") && !location.pathname.includes("/404") && !location.pathname.includes("/500")) {
      document.title = "Payswiftly | Settlement System";
    }

    const apiTokenExpiresFromStorage = localStorage.getItem("apiTokenExpires");
    const lastSignInFromStorage = localStorage.getItem("lastSignIn");

    const getPermissions = async () => {
      const currentUser = await checkAuthStatus();
      if (currentUser) {
        const permissions = await fetchPermissions(currentUser, () => { });
        if (permissions) {
          updateAuthStatus();
          setUserPermissions(permissions);
        } else {
          navigateToSignin();
        }
      } else {
        navigateToSignin();
      }
    };

    const getTokenStatus = async () => {
      if (apiToken) {
        let stilOnTime = true;
        const apiTokenExpires = moment(apiTokenExpiresFromStorage);
        const currentTime = moment();
        const differenceInSeconds = apiTokenExpires.diff(currentTime, 'seconds');
        if (differenceInSeconds <= 3600) {
          extendTokenExpires();
        } else if (differenceInSeconds <= 0) {
          stilOnTime = false;
        }

        if (stilOnTime) {
          getPermissions();
        } else {
          navigateToSignin();
        }
      } else {
        navigateToSignin();
      }
    };

    localStorage.removeItem("startIdleCheck");

    const startIdleCheckId = localStorage.getItem("startIdleCheckId");
    clearInterval(startIdleCheckId);
    localStorage.removeItem("startIdleCheckId");

    const startCountdownId = localStorage.getItem("startIdleCheckId");
    clearInterval(startCountdownId);
    localStorage.removeItem("startCountdownId");

    const date = dayjs.utc(lastSignInFromStorage).tz(profileTimeZone).add(1, 'hour').startOf('day');
    const today = dayjs().tz(profileTimeZone).startOf('day');
    const isPastDate = date.isBefore(today);

    if (!location.pathname.includes("/confirm") && !location.pathname.includes("/404") && !location.pathname.includes("/500") && !location.pathname.includes("/expired")) {
      if (isPastDate) {
        navigateToSignin();
      } else {
        getTokenStatus();
      }
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigateToSignin();
    }
  }, [isAuthenticating, isAuthenticated]);

  const navigateToSignin = async () => {
    localStorage.clear();
    navigate(`/auth/sign-in`);
  };

  const extendTokenExpires = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/extend-auth`,
      {}
    );

    let { token, expires } = response.data;

    if (token != null) {
      localStorage.setItem("apiToken", token);
      localStorage.setItem("apiTokenExpires", expires);
    } else {
      navigateToSignin();
    }
  }

  const convertPathToRegex = (routePath: string) => {
    return new RegExp(`^${routePath.replace(/:\w+/g, '[^/]+')}$`);
  };

  const isRouteExist = (url: string, userRoutes: Route[]): boolean => {
    return userRoutes.some(route => {
      const fullPath = `${route.layout}/${route.path}`;
      const regex = convertPathToRegex(fullPath);
      return regex.test(url);
    });
  };

  if (location.pathname === "/") {
    if (userRole && merchantId && subaccountId) {
      return <Navigate to={`/${roleRoute}/dashboard`} replace />;
    } else if (!merchantId) {
      return <Navigate to="/auth/merchant-selection" replace />;
    } else if (!subaccountId) {
      return <Navigate to="/auth/subaccount-selection" replace />;
    }
  } else if (location.pathname.includes("/confirm") || location.pathname.includes("/500") || location.pathname.includes("/expired") || location.pathname.includes("/404")) {
    if (location.pathname.includes("/confirm")) {
      if (location.pathname.includes("/confirm/") && location.pathname.split('/')[2] !== "") {
        return <PublicLayout />;
      } else if (location.pathname.includes("/500")) {
        return <Navigate to="/500" replace />;
      } else {
        return <Navigate to="/404" replace />;
      }
    } else if (location.pathname.includes("/expired")) {
      if (location.pathname.includes("/expired/") && location.pathname.split('/')[2] !== "") {
        return <PublicLayout />;
      } else if (location.pathname.includes("/500")) {
        return <Navigate to="/500" replace />;
      } else {
        return <Navigate to="/404" replace />;
      }
    } else {
      return <PublicLayout />;
    }
  } else {
    if (isAuthenticated && userRole) {
      if (roleRoute && !location.pathname.startsWith(`/${roleRoute}`)) {
        return <Navigate to={`/${roleRoute}/dashboard`} replace />;
      }

      if (userPermissions) {
        const currentPath = window.location.pathname;
        if (isRouteExist(currentPath, routes[userRole.toLowerCase()])) {
          return <Layout userRole={userRole} userPermissions={userPermissions} />;
        } else {
          return (
            <div>
              <div className="relative h-auto min-h-screen w-full">
                <main className={`mx-auto min-h-screen`}>
                  <div className="relative flex items-center justify-center">
                    <div className="mx-auto flex flex-col pt-12 lg:h-screen lg:px-8 lg:pt-0 xl:h-full xl:min-h-screen xl:px-0">
                      <div className="mb-auto flex flex-col items-center justify-center md:pr-0 lg:pl-0 xl:max-w-full">
                        <NotFound hideFooter={true} isAuthenticated={true}/>
                      </div>
                      <Footer />
                    </div>
                  </div>
                </main>
              </div>
            </div>
          );
        }
      } else if (isAuthenticating) {
        return (
          <div className="flex h-full w-full min-h-screen items-center justify-center justify-items-center">
            <div>
              <Spinner className="h-6 w-6 text-green-500" size="xl" />
            </div>
          </div>
        );
      }
    } else if (isAuthenticating) {
      return (
        <div className="flex h-full w-full min-h-screen items-center justify-center justify-items-center">
          <div>
            <Spinner className="h-6 w-6 text-green-500" size="xl" />
          </div>
        </div>
      );
    }
  }

  return null;
};

export default ProtectedRoute;
