/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import Links from "./components/Links";
import Routes from "routes";
import LogoutButton from "./components/Logout";
import Logo from "assets/img/logo-light.svg";
import axios from "axios";
import { SubaccountContext } from "../../store/SubaccountProvider";
import { ImSpinner8 } from "react-icons/im";
import DisplayAmount from "../../views/admin/shared/DisplayAmount";

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  userRole: string | undefined;
  userPermissions: any | null
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, userRole, userPermissions }) => {
  const {
    subaccountBalance,
    setSubaccountBalance,
    isGettingBalance,
    setIsGettingBalance,
  } = useContext(SubaccountContext);
  const subaccountId = localStorage.getItem("subaccountId");
  const userRoleToCheck = localStorage.getItem("userRole")?.toLowerCase();
  const subaccountName = localStorage.getItem("subaccountName");

  useEffect(() => {
    const fetchData = async () => {
      await getSubaccountBalances();
    };

    if (userRoleToCheck !== "superadmin") {
      fetchData();
    }
  }, []);

  const getSubaccountBalances = async () => {
    setIsGettingBalance(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + `/api/subaccount/balance`, {
        subaccount_id: subaccountId
      });

      const balances = response.data?.balances;
      if (balances) {
        setSubaccountBalance(balances);
        setIsGettingBalance(false);
      }
    } catch (error) {
      console.error("Error get balance data", error);
      return false;
    }
  };

  const { routes } = Routes(userPermissions);
  const currentRoutes =
    userRole && routes[userRole.toLowerCase()]
      ? routes[userRole.toLowerCase()]
      : [];
  const profileRoute = currentRoutes.find(
    (route: any) => route.name === "Profile"
  );
  const mainRoutes = currentRoutes.filter(
    (route: any) =>
      route.name !== "Profile" && route.name !== "Sign In" && route.isSideBar
  );

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-sidebarPrimary pb-10 shadow-2xl shadow-white/5 transition-all dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span className="absolute top-4 right-4 block cursor-pointer xl:hidden" onClick={() => onClose()}>
        <svg stroke="currentColor" fill="white" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
      </span>
      <div className={`pl-[2em] pr-[8em] pt-[2em] pb-[2em] mb-[2em]  border-b border-gray-800`}>
        <div className="text-[26px] font-bold uppercase text-navy-700 ">
          <img src={Logo} alt="Payswiftly" width="160px" />
        </div>
      </div>

      {userRoleToCheck !== "superadmin" ? (
        <>
          {userRoleToCheck !== "superadmin" &&
            subaccountName &&
            subaccountName !== "" &&
            subaccountName !== undefined && (
              <div className="flex flex-col mx-8 mb-8">
                <div className="text-md mb-2 font-medium tracking-tighter text-gray-600">
                  Account
                </div>
                <div className="font-bold text-lg text-white ">
                  {subaccountName}
                </div>
              </div>
            )}
        </>
      ) : null}

      <ul className="mb-3 pt-1">
        <Links routes={mainRoutes} userRole={userRole} onClose={onClose} />
      </ul>

      {userRoleToCheck !== "superadmin" &&
        <div className="mx-8 mb-auto pt-4 border-t border-white border-opacity-10">
          <div className="text-sm text-gray-600 mb-2 w-full tracking-tighter">Available Balance</div>
          {subaccountBalance.length === 0 &&
            <div className="grid grid-cols-2 items-center">
              <ImSpinner8 className="spinner mt-1 text-green-500 " style={{ fontSize: '1.2rem' }} />
            </div>
          }
          {subaccountBalance && subaccountBalance.length > 0 && subaccountBalance.map((item: any) => (
            <div key={item.asset} className="grid grid-cols-2 items-center">
              <div className="col-start col-span-1 text-md text-white font-bold">{item.asset}</div>
              <div className="col-end flex justify-end col-span-1 text-md text-gray-500 font-medium">
                {isGettingBalance ? (
                  <ImSpinner8 className="spinner mt-1 text-green-500 " style={{ fontSize: '1.2rem' }} />
                ) : (
                  <div className="text-md text-green-500 font-bold">
                    <DisplayAmount amount={Number(item.available)} currency={item.asset} />
                  </div>
                )}
              </div>
            </div>
          ))}
          {/* <div className="!rounded custom-sidebar-available-balance-warpper max-h-[100px] overflow-y-auto overflow-x-hidden custom-sidebar-available-balance-scrollbar">
            {subaccountBalance && subaccountBalance.length > 0 && subaccountBalance.map((item: any) => (
              <div key={item.asset} className="grid grid-cols-2 items-center">
                <div className="col-start col-span-1 text-md text-white font-bold">{item.asset}</div>
                <div className="col-end flex justify-end col-span-1 text-md text-gray-500 font-medium">
                  {isGettingBalance ? (
                    <ImSpinner8 className="spinner mt-1 text-green-500 " style={{ fontSize: '1.2rem' }} />
                  ) : (
                    <div className="text-md text-green-500 font-bold">
                      <DisplayAmount amount={Number(item.available)} currency={item.asset} />
                    </div>
                  )}
                </div>
              </div>
            ))}
            {subaccountBalance && subaccountBalance.length > 0 && subaccountBalance.map((item: any) => (
              <div key={item.asset} className="grid grid-cols-2 items-center">
                <div className="col-start col-span-1 text-md text-white font-bold">{item.asset}</div>
                <div className="col-end flex justify-end col-span-1 text-md text-gray-500 font-medium">
                  {isGettingBalance ? (
                    <ImSpinner8 className="spinner mt-1 text-green-500 " style={{ fontSize: '1.2rem' }} />
                  ) : (
                    <div className="text-md text-green-500 font-bold">
                      <DisplayAmount amount={Number(item.available)} currency={item.asset} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div className="grid grid-cols-1">
            <div className="text-xs mt-10 text-gray-600 mb-6">If you would like to deposit funds, please<br />contact your account manager.</div>
          </div>
          <div className="flex text-sm text-gray-600">
            <LogoutButton />
          </div>
        </div>
      }
      {userRoleToCheck === "superadmin" &&
        <div className="flex flex-col h-full mx-8 text-sm text-gray-600 mt-auto">
          <div className="flex-grow">&nbsp;</div>
          <LogoutButton />
        </div>
      }
    </div>
  );
};

export default Sidebar;
