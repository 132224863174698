import { useEffect } from "react";
import Footer from "components/footer/FooterAuthDefault";
import NotFound from "../../views/public/NotFound";
import { Routes, Route, Navigate } from "react-router-dom";
import useRoutes from "routes";

export default function Auth() {
  const { routes } = useRoutes(null);
  const authRoutes = routes.auth;

  useEffect(() => {
    const updateFavicon = (newFavicon32: string, newFavicon16: string) => {
      const icon32 = document.querySelector('link[rel="icon"][sizes="32x32"]');
      const icon16 = document.querySelector('link[rel="icon"][sizes="16x16"]');

      if (icon32) {
        icon32.remove();
      }
      if (icon16) {
        icon16.remove();
      }

      const newIcon32 = document.createElement('link');
      newIcon32.rel = 'icon';
      newIcon32.type = 'image/png';
      newIcon32.setAttribute('sizes', '32x32');
      newIcon32.href = `${newFavicon32}?t=${new Date().getTime()}`;

      const newIcon16 = document.createElement('link');
      newIcon16.rel = 'icon';
      newIcon16.type = 'image/png';
      newIcon16.setAttribute('sizes', '16x16');
      newIcon16.href = `${newFavicon16}?t=${new Date().getTime()}`;

      document.head.appendChild(newIcon32);
      document.head.appendChild(newIcon16);
    };

    updateFavicon('/favicon-32x32.png', '/favicon-16x16.png');
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <div>
      <div className="relative h-auto min-h-screen w-full">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex items-center justify-center">
            <div className="mx-auto flex flex-col pt-12 lg:h-screen lg:px-8 lg:pt-0 xl:h-full xl:min-h-screen xl:px-0">
              <div className="mb-auto flex flex-col items-center justify-center md:pr-0 lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(authRoutes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                  <Route path="*" element={<NotFound hideFooter={true} />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}