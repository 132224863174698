/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import SubaccountList from "./SubaccountList";

const rowPerPage = 10;

interface PayloadType {
  [key: string]: any;
}

const SubaccountsTab: React.FC<{
  merchant: any;
}> = ({ merchant }) => {  
  const [merchantDetail, setMerchantDetail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);

  useEffect(() => {
    const fetchData = async (merchantId: string) => {
      setIsLoading(true);
      await fetchSubaccounts({ page: 0, limit: rowPerPage, lastkey: "", merchantId: merchantId });
      setIsLoading(false);
    };

    if(merchant !== null) {
      setMerchantDetail(merchant);
      fetchData(merchant.id);
    }
  }, [merchant]);

  const fetchSubaccounts = async (payload: PayloadType = {}) => {
    try {
      console.log('payload: ', payload);
      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        merchantId: payload?.merchantId
      };

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-all-subaccounts-for-merchant?${queryString}`
      );

      let newData = response.data.subaccounts?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);

      let newLastKey = response.data.subaccounts?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      merchantId: merchantDetail.id
    };

    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: ""
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchSubaccounts(newFilters);
  };

  return (
    <div>
      <SubaccountList
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
      />
    </div>
  );
};

export default SubaccountsTab;