import { useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import axios from "axios";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import GeneralTab from "./components/GeneralTab";
import AccessControlTab from "../shared/AccessControlTab";

const Settings = () => {
  const [subaccountDetails, setSubaccountDetails] = useState<any>(null);
  const [merchantDetails, setMerchantDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);  
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState<any>({});
  const merchantid = localStorage.getItem("merchantId");
  const subaccountid = localStorage.getItem("subaccountId");

  const fetchSubaccountDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-by-id/${subaccountid}`
      );

      const data = response.data.subaccounts?.payload;

      setSubaccountDetails(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchMerchantDetail = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/merchant/get-merchant-by-id/${merchantid}`
      );

      let merchantData = response.data.merchant?.payload;
      setMerchantDetails(merchantData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await fetchSubaccountDetails();
      await fetchMerchantDetail();
    }
    getData();
  }, []); // eslint-disable-line

  return (
    <>
      <Tabs className="mt-6 card">
        <TabList className="flex rounded-tl-custom rounded-tr-custom">
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || isUpdating || merchantDetails === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>General</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || isUpdating || merchantDetails === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Access Control</strong>
          </Tab>
        </TabList>
        <TabPanels className="border-t border-gray-200 bg-white rounded-bl-custom rounded-br-custom">
          <TabPanel>
            <GeneralTab data={subaccountDetails} setSubaccountDetails={setSubaccountDetails} setSwalProps={setSwalProps} isOtherTabUpdating={isUpdating} setIsUpdating={setIsUpdating} />
          </TabPanel>
          <TabPanel>
            <AccessControlTab data={merchantDetails} isProcessing={isUpdating || isLoading} setSwalProps={setSwalProps} fetchDetail={fetchMerchantDetail} isOtherTabUpdating={isUpdating} setIsUpdating={setIsUpdating} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default Settings;
