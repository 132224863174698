import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import { MdOutlineContentCopy, MdInfoOutline } from "react-icons/md";

const MerchantResetAPISecretConfirmModal: React.FC<{
    details: any;
    isOpen: boolean;
    onClose(): void;
}> = ({ details, isOpen, onClose }) => {

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const closeModal = async () => {
        setIsCopied(false);
        onClose();
    };

    const handleClickCopy = async (payoutId: string) => {
        try {
            setIsCopied(true);
            await navigator.clipboard.writeText(payoutId);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <>
            {details &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[250px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004] text-center">
                                <h1 className="mb-[20px] text-2xl font-bold">Merchant API Secret Key has been reset</h1>
                                <div className="mb-[20px] text-sm text-center">
                                    <div className="mb-3">
                                        A new API secret key has been generated. For security reasons, this key will only be shown once. Please copy and store it in a secure location.
                                    </div>
                                    <div className="mb-3 font-semibold flex items-center justify-center gap-2 text-red-600">
                                        <MdInfoOutline className="h-6 w-6" />
                                        Without this key, { userRole === "superadmin" ? "the merchant" : "you" } will lose access to API functionalities.
                                    </div>
                                    <div className="mb-3 font-semibold text-red-600">
                                        To confirm the update, please click <span className="underline">Save</span>. Failing to do so will result in the loss of the new API secret key.
                                    </div>
                                    <div className="mb-3">
                                        Make sure to copy the key before continuing.
                                    </div>
                                    <div className="mb-3 flex w-full max-w-md mx-auto items-center gap-2">
                                        <input
                                            type="text"
                                            value={details.auth2}
                                            readOnly
                                            className="custom-readonly flex h-10 w-full text-primary rounded-md border border-gray-300 bg-white p-4 shadow-sm outline-none !cursor-pointer"
                                        />
                                        <Tooltip title="Copy API Key" arrow placement="top">
                                            <IconButton
                                                id="button-copy-auth1"
                                                onClick={() => handleClickCopy(details.auth2)}
                                            >
                                                <MdOutlineContentCopy className="h-6 w-6 text-navy-800" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-center">
                                    <Button
                                        onClick={() => closeModal()}
                                        disabled={!isCopied}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default MerchantResetAPISecretConfirmModal;
