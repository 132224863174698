/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, ReactElement } from "react";
import { Button, Badge } from '@chakra-ui/react';
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Tooltip, IconButton } from "@mui/material";
import { MdOutlineContentCopy, MdInfoOutline } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import axios from "axios";
import InputField from "components/fields/InputField";
import MerchantUpdateStatusConfirmModal from "./MerchantUpdateStatusConfirmModal";
import MerchantResetAPISecretConfirmModal from "./MerchantResetAPISecretConfirmModal";
import MerchantResetAPISecretSuccessModal from "./MerchantResetAPISecretSuccessModal";

const AccessControlTab: React.FC<{
  data: any;
  setSwalProps: any;
  isProcessing: boolean;
  fetchDetail: any;
  isOtherTabUpdating: boolean;
  setIsUpdating: any;
}> = ({ data, setSwalProps, isProcessing, fetchDetail, isOtherTabUpdating, setIsUpdating }) => {
  const [isLocalUpdating, setIsLocalUpdating] = useState<boolean>(false);
  const [details, setDetails] = useState<any>(null);
  const [activationStatus, setActivationStatus] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [apiSecret, setApiSecret] = useState<string>("");
  const [statusToUpdate, setStatusToUpdate] = useState<string>("");
  const [isOpenMerchantUpdateStatusConfirmModal, setIsOpenMerchantUpdateStatusConfirmModal] = useState<boolean>(false);
  const [isOpenMerchantResetAPISecretConfirmModal, setIsOpenMerchantResetAPISecretConfirmModal] = useState<boolean>(false);

  const [resetAPISecretInfo, setResetAPISecretInfo] = useState<any>(null);
  const [isOpenMerchantResetAPISecretSuccessModal, setIsOpenMerchantResetAPISecretSuccessModal] = useState<boolean>(false);

  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  interface MerchantStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: MerchantStatusItems[] = [
    { status: "yes", value: 'yes', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
    { status: "no", value: 'no', display: <Badge className={`${statusClasses} status-inactive`}>Inactive</Badge> },
  ];

  const statusDisplay = (status: string) => {
    const statusItem = statusList.find(item => item.status === status);
    return statusItem ? statusItem.display : null;
  };

  useEffect(() => {
    if (data !== null) {
      setDetails(data);
      setActivationStatus(data.is_active);
      setApiKey(data.auth1);
    }
  }, [data]);

  const handleClickCopy = async (payoutId: string) => {
    try {
      await navigator.clipboard.writeText(payoutId);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const showMerchantUpdateStatusConfirmModal = (statusToUpdate: string) => {
    setStatusToUpdate(statusToUpdate);
    setIsOpenMerchantUpdateStatusConfirmModal(true);
  }

  const closeMerchantUpdateStatusConfirmModal = () => {
    setStatusToUpdate("");
    setIsOpenMerchantUpdateStatusConfirmModal(false);
  }

  const showMerchantResetAPISecretConfirmModal = () => {
    setIsOpenMerchantResetAPISecretConfirmModal(true);
  }

  const closeMerchantResetAPISecretConfirmModal = () => {
    setIsOpenMerchantResetAPISecretConfirmModal(false);
  }

  const showMerchantResetAPISecretSuccessModal = () => {
    setIsOpenMerchantResetAPISecretSuccessModal(true);
  }

  const closeMerchantResetAPISecretSuccessModal = () => {
    setResetAPISecretInfo(null);
    setIsOpenMerchantResetAPISecretSuccessModal(false);
  }

  const isFormValid = apiKey !== ""

  const handleSubmit = async () => {
    if (isFormValid) {

      const postData = {
        fn: "update-settings",
        id: data.id,
        merchant_name: data.name,
        username: localStorage.getItem("username"),
        userRole: localStorage.getItem("userRole"),
        is_active: activationStatus,
        auth1: apiKey,
        ...(apiSecret !== "" && { auth2: apiSecret })
      };

      try {
        setIsLocalUpdating(true);
        setIsUpdating(true);
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/merchant/action`,
          postData
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: `Settings has been updated`,
                showConfirmButton: false,
                timer: 2000,
                didClose: () => {
                  setApiSecret("");
                  fetchDetail();
                  setSwalProps({});
                  setIsLocalUpdating(false);
                  setIsUpdating(false);
                },
              });
            } else {
              setIsLocalUpdating(false);
              setIsUpdating(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsLocalUpdating(false);
                  setIsUpdating(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsLocalUpdating(false);
            setIsUpdating(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsLocalUpdating(false);
                setIsUpdating(false);
              },
            });
          });
      } catch (error) {
        setIsLocalUpdating(false);
        setIsUpdating(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsLocalUpdating(false);
            setIsUpdating(false);
          },
        });
      }
    }
  };

  return (
    <>
      {details &&
        <>
          <MerchantUpdateStatusConfirmModal details={details} statusToUpdate={statusToUpdate} isOpen={isOpenMerchantUpdateStatusConfirmModal} onClose={() => closeMerchantUpdateStatusConfirmModal()} triggerReload={fetchDetail} setSwalProps={setSwalProps} />
          <MerchantResetAPISecretConfirmModal details={details} isOpen={isOpenMerchantResetAPISecretConfirmModal} onClose={() => closeMerchantResetAPISecretConfirmModal()} triggerReload={fetchDetail} setSwalProps={setSwalProps} showMerchantResetAPISecretSuccessModal={showMerchantResetAPISecretSuccessModal} setResetAPISecretInfo={setResetAPISecretInfo} setApiSecret={setApiSecret} />
        </>
      }
      {resetAPISecretInfo &&
        <MerchantResetAPISecretSuccessModal details={resetAPISecretInfo} isOpen={isOpenMerchantResetAPISecretSuccessModal} onClose={() => closeMerchantResetAPISecretSuccessModal()} />
      }
      <div className="p-7">
        {details !== null ? (
          <>
            {userRole === "superadmin" &&
              <div className="flex flex-wrap justify-between">
                <div className="w-1/3">
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="mr-1">API Status</div>
                      {statusDisplay(details.is_active)}
                    </div>
                    <div className="flex gap-2">
                      {details.is_active === "yes" &&
                        <Tooltip title="Deactivate Merchant" arrow placement="top">
                          <IconButton
                            disabled={isProcessing || isOtherTabUpdating}
                            id={"button-deactivate"}
                            onClick={() => showMerchantUpdateStatusConfirmModal("no")}
                          >
                            <MdOutlineDoNotDisturbAlt className="h-6 w-6 text-red-500" />
                          </IconButton>
                        </Tooltip>
                      }
                      {details.is_active === "no" &&
                        <Tooltip title="Activate Merchant" arrow placement="top">
                          <IconButton
                            disabled={isProcessing || isOtherTabUpdating}
                            id={"button-activate"}
                            onClick={() => showMerchantUpdateStatusConfirmModal("yes")}
                          >
                            <AiOutlineCheckCircle className="h-6 w-6 text-green-500" />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="flex flex-wrap justify-between">
              <div className="w-1/3">
                {userRole === "superadmin" &&
                  <hr className="my-4 border-t border-gray-200" />
                }
                <div className="flex items-center justify-between">
                  <div>
                    <div className="mr-1">API Key</div>
                  </div>
                  <div className="flex gap-2">
                    <InputField
                      hideLabel
                      disabled={isProcessing || isOtherTabUpdating}
                      variant="auth"
                      extra="mb-3 shadow-sm"
                      label=""
                      placeholder=""
                      id="apiKey"
                      type="text"
                      autoComplete="off"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                    <Tooltip title="Copy API Key" arrow placement="top">
                      <div className="mt-[10px]">
                        <IconButton
                          id={"button-copy-auth1"}
                          onClick={() => handleClickCopy(apiKey)}
                        >
                          <MdOutlineContentCopy className="h-6 w-6 text-navy-800" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <div className="mr-1">API Secret Key</div>
                  </div>
                  <div className="flex gap-2">
                    <Tooltip title="You will only see the API Secret once when it is generated. Make sure to save it securely" arrow placement="top">
                      <div className="mt-[10px]">
                        <IconButton
                          id={"button-info-auth2"}
                        >
                          <MdInfoOutline className="h-6 w-6 text-navy-800" />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <input
                      type="text"
                      value="**** **** **** ****"
                      readOnly
                      className="custom-readonly flex h-10 w-full text-primary items-center rounded-md mt-2 border border-gray-300 bg-white p-4 shadow-sm outline-none mb-3 shadow-sm !cursor-pointer"
                    />
                    <Tooltip title="Reset API Secret" arrow placement="top">
                      <div className="mt-[10px]">
                        <IconButton
                          disabled={isProcessing || isOtherTabUpdating}
                          id={"button-reset-api-secret"}
                          onClick={showMerchantResetAPISecretConfirmModal}
                        >
                          <TbRefresh className="h-6 w-6 text-navy-800" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-start">
              <div className="mt-4">
                <Button
                  onClick={() => handleSubmit()}
                  isDisabled={!isFormValid || isProcessing || isOtherTabUpdating}
                  isLoading={isLocalUpdating}
                  loadingText='Processing...'
                  className="btn btn-primary"
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default AccessControlTab;
