import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../../../store/ProfileProvider";

const Clock = () => {
  const [time, setTime] = useState<string>("");
  const [utcOffset, setUtcOffset] = useState<string>("");
  const { profileTimeZone } = useContext(ProfileContext);

  useEffect(() => {
    let animationFrameId: any;

    const updateClock = () => {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = { timeZone: profileTimeZone };

      const day = now.toLocaleString("en-US", { day: "2-digit", ...options });
      const month = now.toLocaleString("en-US", { month: "short", ...options });
      const year = now.toLocaleString("en-US", { year: "2-digit", ...options });
      const formattedTime = now.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        ...options,
      });

      setTime(`${day} ${month} ${year} - ${formattedTime}`);

      // Get correct UTC offset
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: profileTimeZone,
        timeZoneName: "longOffset",
      });

      const parts = formatter.formatToParts(now);
      const offsetPart = parts.find((part) => part.type === "timeZoneName");

      if (offsetPart) {
        // Replace "GMT" with "UTC" to match expected format
        setUtcOffset(offsetPart.value.replace("GMT", "UTC"));
      }

      const nextSecond = 1000 - now.getMilliseconds();
      animationFrameId = setTimeout(updateClock, nextSecond);
    };

    updateClock();

    return () => clearTimeout(animationFrameId);
  }, [profileTimeZone]);

  return (
    <div className="flex flex-col items-end">
      <div className="font-bold text-navy-700">{time}</div>
      <div className="font-bold text-xs text-navy-700">
        {profileTimeZone} ({utcOffset})
      </div>
    </div>
  );
};

export default Clock;
