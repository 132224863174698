/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button } from '@chakra-ui/react';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ProfileContext } from "../../../store/ProfileProvider";

dayjs.extend(utc);
dayjs.extend(timezone);

const ReceiverAccountDetailsModal: React.FC<{
    detail: any;
    isOpen: boolean;
    onClose(): void;
}> = ({ isOpen, onClose, detail }) => {
    const { profileTimeZone } = useContext(ProfileContext);
    const [receiverAccountDetail, setReceiverAccountDetail] = useState<any>(detail);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const closeModal = async () => {
        onClose();
        setReceiverAccountDetail(null);
    };

    const fieldsRendering = (fields: Record<string, any>) => {
        const renderInput = (fieldName: string, fieldValue: any) => {
            const capitalizeLabel = (label: string) => {
                let modifiedLabel = label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiedLabel = "BSB";
                        break;
                    case "bic":
                        modifiedLabel = "BIC";
                        break;
                    case "iban":
                        modifiedLabel = "IBAN";
                        break;
                }

                return modifiedLabel;
            };

            return (
                <div className="mb-1" key={fieldName}>
                    <span className="mr-1 font-bold">{capitalizeLabel(fieldName)}:</span> {fieldValue}
                </div>
            );
        };

        return Object.entries(fields).map(([key, value]) => renderInput(key, value));
    };

    const formatDate = (timestamp: string) => {
        return dayjs(Number(timestamp)).tz(profileTimeZone).format("DD MMM YY - hh:mm A");
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={true}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[450px] !max-w-[85%] top-[1vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[20px] pt-[20px] pb-[10px] max-w-[650px] flex flex-col !z-[1004]">
                            <div className={
                                receiverAccountDetail.popv_acc_revr_detail && userRole === "superadmin" 
                                ? 
                                `grid grid-cols-1 md:grid-cols-2 gap-6` : ``}
                            >
                                {/* Left Block */}
                                <div>
                                    <div className="min-h-[175px]">
                                    <h4 className="mb-1 font-bold">Account Detail</h4>
                                    <div className="flex justify-center">
                                        <hr className="mb-1 w-full" />
                                    </div>
                                    <div className="mb-[15px] text-sm">
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Asset:</span> {receiverAccountDetail.asset}
                                        </div>
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Method:</span> {receiverAccountDetail.method_name}
                                        </div>
                                        {fieldsRendering(receiverAccountDetail.method_details)}
                                    </div>
                                    </div>

                                    {receiverAccountDetail.geolocation && receiverAccountDetail.confirmed_at && userRole === "superadmin" && (
                                        <div className="min-h-[175px]">
                                        <div className="mb-[10px]">
                                            <h4 className="mb-1 font-bold">Account Confirmation Info</h4>
                                            <div className="flex justify-center">
                                                <hr className="mb-1 w-full" />
                                            </div>
                                            <div className="mb-3 text-sm">
                                                <div className="mb-1">
                                                    <span className="mr-1 font-bold">Date:</span> {formatDate(receiverAccountDetail.confirmed_at)}
                                                </div>
                                                {fieldsRendering(receiverAccountDetail.geolocation)}
                                            </div>
                                        </div>                                        
                                    </div>
                                    )}
                                </div>

                                {/* Right Block */}
                                <div>
                                    {receiverAccountDetail.popv_acc_revr_detail && userRole === "superadmin" && (
                                        <>
                                            <div className="min-h-[175px]">
                                                <h4 className="mb-1 font-bold">Account Receiver Info</h4>
                                                <div className="flex justify-center">
                                                    <hr className="mb-1 w-full" />
                                                </div>
                                                <div className="mb-3 text-sm">
                                                    {fieldsRendering(receiverAccountDetail.popv_acc_revr_info)}
                                                </div>
                                            </div>
                                            <div className="min-h-[175px]">
                                                <h4 className="mb-1 font-bold">Account Receiver Detail</h4>
                                                <div className="flex justify-center">
                                                    <hr className="mb-1 w-full" />
                                                </div>
                                                <div className="mb-3 text-sm">
                                                    {fieldsRendering(receiverAccountDetail.popv_acc_revr_detail)}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ReceiverAccountDetailsModal;