/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { FaAngleLeft } from "react-icons/fa";
import SweetAlert2 from "react-sweetalert2";

import DetailsTab from "./components/DetailsTab";
import AccessControlTab from "../shared/AccessControlTab";
import SubaccountsTab from "./components/SubaccountsTab";
import { useParams, useNavigate } from "react-router-dom";

const MerchantDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [merchantDetailForEdit, setMerchantDetailForEdit] = useState(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState<any>({});
  const { merchant_id } = useParams();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      await fetchMerchantDetail();
    };

    fetchData();
  }, []);

  const fetchMerchantDetail = async () => {
    try {
      setIsProcessing(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/merchant/get-merchant-by-id/${merchant_id}`
      );

      let merchantData = response.data.merchant?.payload;
      setMerchantDetailForEdit(merchantData);
      setIsLoading(false);
      setIsProcessing(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  return (
    <>
      <div className="mt-3 mb-3 flex items-center justify-end">
        <Button
          onClick={() => navigate(`/${userRole}/merchants/manage`)}
          className="btn btn-default"
        >
          <FaAngleLeft />&nbsp;Back to Merchants
        </Button>
      </div>
      <Tabs className="mt-6 card">
        <TabList className="flex rounded-tl-custom rounded-tr-custom">
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || isUpdating || isProcessing || merchantDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Details</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || isUpdating || isProcessing || merchantDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Access Control</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || isUpdating || isProcessing || merchantDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Subaccounts</strong>
          </Tab>
        </TabList>
        <TabPanels className="border-t border-gray-200 bg-white rounded-bl-custom rounded-br-custom">
          <TabPanel>
            <DetailsTab data={merchantDetailForEdit} />
          </TabPanel>
          <TabPanel>
            <AccessControlTab data={merchantDetailForEdit} isProcessing={isProcessing} setSwalProps={setSwalProps} fetchDetail={fetchMerchantDetail} isOtherTabUpdating={isUpdating} setIsUpdating={setIsUpdating} />
          </TabPanel>
          <TabPanel>
            <SubaccountsTab merchant={merchantDetailForEdit} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default MerchantDetail;
