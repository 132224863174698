/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import BatchSearch from "./components/BatchSearch";
import BatchList from "./components/BatchList";
import moment from "moment";
import { AiOutlineUpload } from "react-icons/ai";
import { Button } from '@chakra-ui/react'
import BatchUploadModal from "./components/BatchUploadModal";

interface Filters {
  [key: string]: any;
}
interface PayloadType {
  [key: string]: any;
}

const rowPerPage = 10;

const initFrom = moment().subtract(1, 'month');
const initTo = moment();
const initialDateRangeFilter = [initFrom.toDate(), initTo.toDate()];

const initialFilters: Filters = {
  date_type: "c",
  from: initFrom,
  to: initTo,
  asset: "",
  status: "",
  provider_account: "",
  payout_method: ""
};

const Batch = ({ userPermissions }: { userPermissions: any }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDateRangeFilter);
  const [isOpenbatchUploadModal, setIsOpenbatchUploadModal] = useState<boolean>(false);
  const [subaccountList, setSubaccountList] = useState<any>(null);
  const [providerList, setProviderList] = useState<any>(null);
  const [assetList, setAssetList] = useState<any>(null);

  const subaccount_id = localStorage.getItem("subaccountId");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("subaccountIdToFilterBatch");

      setIsLoading(true);
      await fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
      await fetchProviders();
      if (userRole === 'superadmin') {
        await fetchAssetsForSuperadmin();
        await fetchSubaccounts();
      } else {
        await fetchAssets();
      }
      setIsLoading(false);
      setIsFilterLoading(false);
    };

    fetchData();
  }, []);

  const fetchSubaccounts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subaccount/get-all-subaccounts`
      );
      setSubaccountList(response.data.subaccounts.payload);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setSubaccountList([]);
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/api/batch/method/batch/all`
      );
      // const response = await axios.get(
      //   process.env.REACT_APP_API_URL + `/api/batch/method/batch/6250eca2-7f6d-40da-ad8b-31bb2d1e8b66`
      // );

      setProviderList(response.data.provider);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setProviderList([]);
    }
  };

  const fetchAssetsForSuperadmin = async () => {
    try {
      let requestUrl = `/api/datasource/assets`;

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response?.data;

      setAssetList(data);
    } catch (error) {
      console.error("Error fetching assets", error);
    }
  };

  const fetchAssets = async () => {
    const subaccountId = localStorage.getItem("subaccountId");
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-by-id/${subaccountId}`
      );
      const accountData = response.data.subaccounts?.payload;
      const buildUpAsset = accountData.account_methods.reduce((acc: string[], item: any) => {
        if (!acc.includes(item.asset)) {
          acc.push(item.asset);
        }
        return acc;
      }, []);
      const formattedAssets = buildUpAsset.map((name: any) => ({ name }));
      setAssetList(formattedAssets);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchBatch = async (payload: PayloadType = {}, isReset = false) => {
    try {

      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        subaccount: payload?.subaccount_id
      };

      if (payload?.date_type === "c") {
        queryObject.from = moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
        queryObject.to = moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');
      } else {
        queryObject.pfrom = moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
        queryObject.pto = moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');
      }

      if (payload?.status !== "" && payload?.status !== undefined) {
        queryObject.status = payload?.status
      }

      if (payload?.asset !== "" && payload?.asset !== undefined) {
        queryObject.asset = payload?.asset
      }

      if (payload?.provider_account !== "" && payload?.provider_account !== undefined) {
        queryObject.provider_account = payload?.provider_account
      }

      if (payload?.payout_method !== "" && payload?.payout_method !== undefined) {
        queryObject.payout_method = payload?.payout_method
      }

      if (userRole === 'superadmin') {
        delete queryObject.subaccount;

        const subaccountId = localStorage.getItem("subaccountIdToFilterBatch");

        if (subaccountId && subaccountId !== "" && subaccountId !== undefined) {
          queryObject.subaccount = subaccountId
        }
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/batch/get-all-batch?${queryString}`
      );

      let newData = response.data.batches?.payload;

      if (newData && newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);
      // Manage Last Key and Total Record
      let newLastKey = response.data.batches?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      subaccount_id: subaccount_id,
      ...filters
    };

    // If click prev pagination
    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        subaccount_id: subaccount_id,
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchBatch(newFilters);
  };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    setCurrentLastKey("");
    fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
    setIsFilterLoading(false);
  };

  const handleSearchClear = () => {
    localStorage.removeItem("subaccountIdToFilterBatch");

    handleResetTable();
  };

  const handleResetTable = () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    setFilters(initialFilters);
    setPage(0);
    setCurrentLastKey("");
    setLastKeys([]);
    setSelectedDates(initialDateRangeFilter);
    fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...initialFilters }, true);
    setIsFilterLoading(false);
  };

  const showBatchUploadModal = () => {
    setIsOpenbatchUploadModal(true);
  }

  const closeBatchUploadModal = () => {
    setIsOpenbatchUploadModal(false);
  }

  const triggerReloadBatchList = async () => {
    setIsLoading(true);
    fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...initialFilters }, true);
  }

  return (
    <>

      {/* { (userRole === 'admin' || userRole === 'merchant') &&
        <>
          <BatchUploadModal triggerReloadBatchList={triggerReloadBatchList} isOpen={isOpenbatchUploadModal} onClose={() => closeBatchUploadModal()} />

          <div className="flex justify-start mt-6 mb-3">
            <Button
              isDisabled={isLoading}
              onClick={() => showBatchUploadModal()}
              className="rounded-xl bg-green-500 px-3 py-2.5 text-sm font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
            >
              <AiOutlineUpload className="h-4 w-4" />&nbsp;UPLOAD
            </Button>
          </div>
        </>
      } */}
      <BatchSearch
        isLoading={isLoading}
        setIsFilterLoading={setIsFilterLoading}
        isFilterLoading={isFilterLoading}
        filters={filters}
        setFilters={setFilters}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        subaccountList={subaccountList}
        providerList={providerList}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
        assetList={assetList}
      />
      <BatchList
        userPermissions={userPermissions}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
        triggerReloadBatchList={triggerReloadBatchList}
      />
    </>
  );
};

export default Batch;
