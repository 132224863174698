/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Select, Button, Badge, Divider, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { FaAngleLeft } from "react-icons/fa";
import moment from "moment";
import SweetAlert2 from "react-sweetalert2";
import ReceiverAccountList from "./ReceiverAccountList";

const rowPerPage = 10;

interface PayloadType {
  [key: string]: any;
}

const ReceiverAccountsTab: React.FC<{
  userPermissions: any
  detail: any;
}> = ({ detail, userPermissions }) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      await fetchReceiverAccounts({ page: 0, limit: rowPerPage, lastkey: "", receiver_id: detail.id });
    };

    if(detail !== null) {
      fetchData();
    }    
  }, [detail]);

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      receiver_id: detail.id
    };

    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        receiver_id: detail.id
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchReceiverAccounts(newFilters);
  };

  const fetchReceiverAccounts = async (payload: PayloadType = {}) => {
    try {
      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        receiver_id: payload?.receiver_id,
        usertype: userRole === 'superadmin' ? "a" : "m"
      };

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/receiveraccount?${queryString}`
      );

      let newData = response.data.receiver_accounts?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }
      setIsLoading(false);

      let newLastKey = response.data.receivers?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      } else {
        setCurrentLastKey("");
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const triggerReloadReceiverAccountList = async () => {
    setIsLoading(true);
    await fetchReceiverAccounts({ page: 0, limit: rowPerPage, lastkey: "", receiver_id: detail.id });
  }

  return (
    <div>
      <ReceiverAccountList
        userPermissions={userPermissions}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
        receiverId={detail !== null ? detail.id : null}
        triggerReloadReceiverAccountList={triggerReloadReceiverAccountList}
      />
    </div>
  );
};

export default ReceiverAccountsTab;