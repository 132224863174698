/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import DynamicInputField from "components/fields/DynamicInputField";
import {
  Select,
  Button
} from '@chakra-ui/react';
import AutoComplete from 'react-select';
import { FaPlus } from "react-icons/fa";
import SweetAlert2 from "react-sweetalert2";
import { AiOutlineEdit } from "react-icons/ai";
import { Checkbox } from '@chakra-ui/react'
import { SubaccountContext } from "../../../store/SubaccountProvider";

const CreatePayout: React.FC = () => {
  const { setSubaccountBalance, setIsGettingBalance } = useContext(SubaccountContext);
  const initialData: {
    currency: string;
    receiver_id: string;
    account_id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    gender: string;
    email: string;
    dob: string;
    dialcode: string;
    phone: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    amount: number;
    reference: string;
    note: string;
    bank_code: string;
    bsb: string;
    account_number: string;
  } = {
    currency: "",
    receiver_id: "",
    account_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    email: "",
    dob: "",
    dialcode: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    amount: 0,
    reference: "",
    note: "",
    bank_code: "",
    bsb: "",
    account_number: ""
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isOpenAutoComplete, setIsOpenAutoComplete] = useState<boolean>(false);
  const [isDisableAutoComplete, setIsDisableAutoComplete] = useState<boolean>(false);
  const [isCreateNewReceiver, setIsCreateNewReceiver] = useState<boolean>(false);
  const [isIndicateTerms, setIsIndicateTerms] = useState<boolean>(false);
  const [isOpenReceiverDetailsForm, setIsOpenReceiverDetailsForm] = useState<boolean>(false);
  const [isReceiverDetailsFormReadOnly, setIsReceiverDetailsFormReadOnly] = useState<boolean>(false);
  const [currentAccountId, setCurrentAccountId] = useState<string>("");

  const [payout, setPayout] = useState<any>(initialData);
  const [swalProps, setSwalProps] = useState<any>({});
  const [selectedReceiverDetails, setSelectedReceiverDetails] = useState<any>(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any>(null);
  const [assetList, setAssetList] = useState<any>([]);
  const [receiverList, setReceiverList] = useState<any>(null);
  const [countries, setCountries] = useState<any>(null);
  const [dialcodeList, setDialcodeList] = useState<any>(null);
  const receiverAutocompleteRef = useRef(null);
  const nodeReceiverFormRef = useRef(null);
  const subaccountId = localStorage.getItem("subaccountId");
  const subaccountName = localStorage.getItem("subaccountName");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetchAccountDetail();
      await fetchReceiverAccounts();
      await fetchCountries();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const fetchAccountDetail = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-by-id/${subaccountId}`
      );
      const accountData = response.data.subaccounts?.payload;
      const buildUpAsset = accountData.account_methods.reduce((acc: string[], item: any) => {
        if (!acc.includes(item.asset)) {
          acc.push(item.asset);
        }
        return acc;
      }, []);
      setAssetList(buildUpAsset);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchReceiverAccounts = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/receiver/get-all-receivers?subaccount_id=${subaccountId}&with_account=true&usertype=m`
      );

      const data = response.data.receivers?.payload;
      setReceiverList(data);
    } catch (error) {
      console.error("Error fetching receiver accounts", error);
    }
  };

  const fetchCountries = async () => {
    try {

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/datasource/countries`
      );

      let countries = response?.data;
      let dialcodeList: any = [];
      if (countries.length > 0) {
        countries.forEach((c: any) => {
          if (c.dialcode !== null) {
            if (c.dialcode in dialcodeList) {
              dialcodeList[c.dialcode] = dialcodeList[c.dialcode] + ', ' + c.country_name;
            } else {
              dialcodeList[c.dialcode] = '(+' + c.dialcode + ') ' + c.country_name;
            }
          }
        });
      }

      const sortedDialcodeList: any = [];
      Object.keys(dialcodeList).sort().forEach((key: any) => {
        sortedDialcodeList[key] = dialcodeList[key];
      });

      setCountries(countries);
      setDialcodeList(sortedDialcodeList);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const checkSubaccountBalances = async (currency: string, amount: number) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + `/api/subaccount/balance`, {
        subaccount_id: subaccountId,
        currency: currency
      });

      const balances = response.data?.balances;
      if (balances && balances.available && balances.available >= amount) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error get balance data", error);
      return false;
    }
  };

  const getSubaccountBalances = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + `/api/subaccount/balance`, {
        subaccount_id: subaccountId
      });

      const balances = response.data?.balances;
      if (balances) {
        setSubaccountBalance(balances);
      }
    } catch (error) {
      console.error("Error get balance data", error);
      return false;
    }
  };

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setPayout({ ...payout, [id]: value });

    if (id === 'indicate_terms') {
      setIsIndicateTerms(event.target.checked);
    }
  };

  const handleAutoCompleteInputChange = (item: any) => {
    if (item !== "") {
      setIsOpenAutoComplete(true);
    } else {
      setIsOpenAutoComplete(false);
    }
  };

  const handleAutoCompleteChange = (item: any) => {
    if (item !== null) {
      const receiverData = receiverList.filter((receiver: any) => receiver.account.some((account: any) => account.id === item.value));
      let receiverDetails = {}
      if (receiverData.length > 0) {
        receiverDetails = {
          receiver_id: receiverData[0].id,
          account_id: item.value,
          first_name: receiverData[0].first_name,
          last_name: receiverData[0].last_name,
          email: receiverData[0].email,
          middle_name: receiverData[0].middle_name,
          gender: receiverData[0].gender,
          dob: receiverData[0].dob,
          dialcode: receiverData[0].dialcode,
          phone: receiverData[0].phone,
          address: receiverData[0].address,
          address2: receiverData[0].address2,
          city: receiverData[0].city,
          state: receiverData[0].state,
          postcode: receiverData[0].postcode,
          country: receiverData[0].country
        }
      }

      setPayout({ ...payout, ...receiverDetails });

      setSelectedReceiverDetails(item);
      setIsOpenReceiverDetailsForm(true);
      setIsReceiverDetailsFormReadOnly(true);
    } else {
      resetReceiverSelected();
    }
  };

  const resetReceiverSelected = () => {
    let dataToReset = JSON.parse(JSON.stringify(initialData));

    dataToReset.currency = payout.currency;
    dataToReset.amount = payout.amount;
    dataToReset.reference = payout.reference;
    dataToReset.note = payout.note;

    setPayout(dataToReset);
    setSelectedReceiverDetails(null);
    setIsOpenReceiverDetailsForm(false);
    setIsReceiverDetailsFormReadOnly(false);
  };

  const handleCreateNewReceiver = () => {
    setIsCreateNewReceiver(true);
    setIsDisableAutoComplete(true);
  };

  const handleCancelCreateNewReceiver = () => {
    setIsCreateNewReceiver(false);
    setIsDisableAutoComplete(false);
  };

  const noOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Receiver not found for "{inputValue}"</div>;
  };

  const isFormValid = payout !== null ? (
    payout.currency
    && payout.receiver_id
    && payout.account_id
    && payout.first_name
    && payout.last_name
    && payout.email
    && payout.dob
    && payout.dialcode
    && payout.phone
    && payout.address
    && payout.city
    && payout.postcode
    && payout.country
    && payout.amount
  ) : false;
  const handleSubmit = async () => {
    setIsCreating(true);
    let isFormReady = true;
    const username = localStorage.getItem("username");
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    const amount = parseFloat(payout.amount);

    if (!isIndicateTerms && isFormReady) {
      isFormReady = false;
      setIsCreating(false);
      setSwalProps({
        show: true,
        icon: "warning",
        title: "Attention!",
        html: "Please indicate that you accept the terms and conditions",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
        },
      });
    }

    if (isFormReady) {
      const sufficientBalance = await checkSubaccountBalances(payout.currency, amount);
      if (!sufficientBalance) {
        isFormReady = false;
        setIsCreating(false);
        setSwalProps({
          show: true,
          icon: "warning",
          title: "Attention!",
          html: "Insufficient Balance to process payout",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
          },
        });
      }
    }

    if (isFormReady) {
      payout.subaccount_id = subaccountId;
      payout.userRole = userRole;
      payout.username = username;

      if (payout.state === "") {
        delete payout.state;
      }

      try {
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/payout/create`,
          payout
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: "Create payout successfully",
                showConfirmButton: true,
                didClose: async () => {
                  setIsGettingBalance(true);
                  await getSubaccountBalances();
                  setSwalProps({});
                  setTimeout(() => {
                    setIsGettingBalance(false);
                    navigate(`/${userRole}/payouts`);
                  }, 2000);
                },
              });
            } else {
              setIsCreating(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsCreating(false);
                },
              });
            }
          })
          .catch(function (error) {
            let errorMessage = "Create payout has been failed";
            if (error.response && (error.response.status === 400 || error.response.status === 500)) {
              errorMessage = error.response.data.message;
            }

            setIsCreating(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: errorMessage,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsCreating(false);
              },
            });
          });
      } catch (error: any) {
        setIsCreating(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
          },
        });
      }
    }
  };

  const paymentDetailsFieldsRendering = (accountId: string) => {
    const receiverData = receiverList.filter((receiver: any) => receiver.account.some((account: any) => account.id === accountId));
    const receiverAccountData = receiverData[0].account.filter((account: any) => account.id === accountId);
    const arrayOfInfo = Object.entries(receiverAccountData[0]?.method_details).map(([key, value]) => ({
      name: key,
      value: value
    }));

    const renderInput = (field: any) => {
      const capitalizeLabel = (label: any) => {
        let modifiedLabel = label.split('_')
          .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        switch (label) {
          case "bsb":
            modifiedLabel = "BSB";
            break;
          case "bic":
            modifiedLabel = "BIC";
            break;
          case "iban":
            modifiedLabel = "IBAN";
            break;
        }

        return modifiedLabel;
      };

      return (
        <DynamicInputField
          key={field.name}
          disabled={isLoading}
          readonly={true}
          variant="auth"
          extra="mb-3 shadow-sm"
          label={capitalizeLabel(field.name)}
          id={field.name}
          name={field.name}
          value={field.value}
          type="text"
          required={false}
          autoComplete="off"
        />
      );
    };

    return arrayOfInfo.map(renderInput);
  };

  const buildUpRecevierAccountAutoCompleteOptions = (currencyToFilter: string) => {
    setIsOpenReceiverDetailsForm(false);
    receiverAutocompleteRef.current?.clearValue();

    let receiverAccountListArray: any = [];
    if (receiverList.length > 0 && currencyToFilter !== "") {
      receiverList.forEach((receiver: any) => {
        const nameInfo = `${receiver.first_name} ${receiver.last_name} (${receiver.email})`;
        if (receiver.account.length > 0) {
          receiver.account.forEach((account: any) => {
            if (receiver.kyc_status === 'completed' && account.method_details && account.asset === currencyToFilter) {
              const formattedInfo = Object.entries(account.method_details)
                .filter(([key, value]) => value !== "")
                .map(([key, value]) => `${formatLabel(key)}: ${value}`)
                .join(" / ");
              let newObj = {
                value: account.id,
                label: `${nameInfo} (${account.method_name} / ${formattedInfo})`
              }
              receiverAccountListArray.push(newObj);
            }
          });
        }
      });
    }
    setAutoCompleteOptions(receiverAccountListArray);
  };

  const formatLabel = (label: any) => {
    let modifiedLabel = label.split('_').join(' ');
    switch (label) {
      case "bsb":
        modifiedLabel = "BSB";
        break;
      case "bic":
        modifiedLabel = "BIC";
        break;
      case "iban":
        modifiedLabel = "IBAN";
        break;
    }

    return modifiedLabel;
  };

  useEffect(() => {
    if (payout.currency !== "") {
      buildUpRecevierAccountAutoCompleteOptions(payout.currency);
    }
  }, [payout.currency]);

  return (
    <form>
      <Card className="mt-6 bg-white p-8 shadow-md rounded-cs">
        <>
          <div className="flex justify-between w-full">
            <div style={{ width: '45%' }}>
              <div className="flex flex-wrap">
                <h3 className="mb-4 text-lg font-bold text-green-600 dark:text-white">
                  Payment Asset
                </h3>
              </div>

              <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                Asset *
              </label>

              <Select disabled={isCreating || isLoading} id="currency" value={payout.currency} onChange={handleInputChange} className="h-10 rounded-md mt-2 text-primary relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-12 shadow-sm">
                <option value="">{isLoading ? "Retrieving Available..." : "-- Please Select --"}</option>
                {assetList.map((assetItem: any) => (
                  <option key={String(assetItem).toLowerCase()} value={assetItem}>{assetItem}</option>
                ))}
              </Select>

              <div className="flex flex-wrap">
                <h3 className="mb-4 text-lg font-bold text-green-600 dark:text-white">
                  Payee Details
                </h3>
              </div>

              {payout.currency === "" &&
                <label className="mb-1 text-sm text-gray-500 dark:text-white font-medium">
                  Please select a payment asset before choosing the payee.
                </label>
              }
              {payout.currency !== "" &&
                <div className="!text-sm">
                  <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                    Search
                  </label>

                  <AutoComplete
                    ref={receiverAutocompleteRef}
                    isDisabled={isDisableAutoComplete || isCreating || isLoading === null || payout.currency === ""}
                    isClearable
                    placeholder=""
                    options={autoCompleteOptions}
                    classNamePrefix="create-payout-receiver border-gray-500 "
                    onInputChange={(item: any) => handleAutoCompleteInputChange(item)}
                    onChange={(item: any) => handleAutoCompleteChange(item)}
                    menuIsOpen={isOpenAutoComplete}
                    noOptionsMessage={noOptionsMessageRenderer}
                  />
                </div>
              }

              <CSSTransition in={isOpenReceiverDetailsForm} timeout={300} classNames="slide-down" unmountOnExit nodeRef={nodeReceiverFormRef} >
                <div ref={nodeReceiverFormRef}>

                  {isOpenReceiverDetailsForm ? <div className="flex justify-center"><hr className="mb-3 mt-4 w-1/3" /></div> : null}

                  <Card className="shadow-none border border-gray-200 p-8">
                    <h3 className="mb-6 text-md font-bold text-navy-700">
                      Receiver Details
                    </h3>
                    <div className="grid grid-cols-3 gap-y-2 text-sm text-primary">
                      <div className="col-span-1">First Name: </div><div className="col-span-2 font-bold">{payout.first_name}</div>
                      <div className="col-span-1">Middle Name: </div><div className="col-span-2 font-bold">{payout.middle_name !== "" ? payout.middle_name : "-"}</div>
                      <div className="col-span-1">Last Name: </div><div className="col-span-2 font-bold">{payout.last_name}</div>
                      <div className="col-span-1">Gender: </div><div className="col-span-2 font-bold">{payout.gender !== "" && payout.gender ? payout.gender === "M" ? "Male" : "Female" : "-"}</div>
                      <div className="col-span-1">Email: </div><div className="col-span-2 font-bold">{payout.email}</div>
                      <div className="col-span-1">Date of Birth: </div><div className="col-span-2 font-bold">{payout.dob}</div>
                      <div className="col-span-1">Phone: </div><div className="col-span-2 font-bold">{payout.dialcode} {payout.phone}</div>
                      <div className="col-span-1">Address: </div><div className="col-span-2 font-bold">{payout.address}</div>
                      {payout.address2 && payout.address2 !== "" ? (<><div className="col-span-1">&nbsp; </div><div className="col-span-2 font-bold">{payout.address2}</div></>) : null}
                      <div className="col-span-1">City: </div><div className="col-span-2 font-bold">{payout.city}</div>
                      <div className="col-span-1">State: </div><div className="col-span-2 font-bold">{payout.state !== "" ? payout.state : "-"}</div>
                      <div className="col-span-1">Postcode: </div><div className="col-span-2 font-bold">{payout.postcode}</div>
                      <div className="col-span-1">Country: </div><div className="col-span-2 font-bold">{payout.country}</div>
                    </div>
                  </Card>

                </div>
              </CSSTransition>

              {/* <Button
                  onClick={() => handleCreateNewReceiver()}
                  isDisabled={isCreating || selectedReceiverDetails !== null}
                  className="mr-1 rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                >
                  <AiOutlineEdit />&nbsp;CREATE NEW RECEIVER
                </Button>

                {isCreateNewReceiver ?
                  <Button
                    onClick={() => handleCancelCreateNewReceiver()}
                    isDisabled={isCreating}
                    className="rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-navy-700 transition duration-200 hover:bg-gray-300 active:bg-gray-400 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Cancel
                  </Button>
                  : null} */}

              {/* <Select isDisabled={isLoading} id="receiver_id" value={payout.receiver_id} onChange={handleInputChange} className="h-10 rounded-xl relative bg-white border border-gray-200 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm text-sm mb-3">
                  <option value="">-- Please Select --</option>
                  {receiverList !== null ? receiverList.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.first_name} {item.last_name} {`(` + item.email + `)`}
                    </option>
                  )) : (null)}
                </Select> */}
            </div>
            <div></div>
            <div style={{ width: '45%' }}>

              <div className="flex flex-wrap">
                <h3 className="mb-4 text-lg font-bold text-green-600 dark:text-white">
                  Payment Instruction
                </h3>
              </div>

              <InputField
                disabled={isCreating}
                variant="auth"
                extra="mb-3 shadow-sm number-input"
                label="Amount *"
                placeholder=""
                id="amount"
                type="number"
                autoComplete="off"
                value={payout.amount > 0 ? payout.amount : ""}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isCreating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Reference"
                placeholder=""
                id="reference"
                type="text"
                autoComplete="off"
                value={payout.reference}
                onChange={handleInputChange}
              />

              <label className="mb-1 text-sm text-navy-700 font-medium">
                Note
              </label>

              <textarea
                disabled={isCreating}
                className="mb-9 mt-2 text-md shadow-sm border text-primary rounded-md p-2 block w-full bg-white  border-gray-300 h-28 resize-none overflow-auto focus:outline-none"
                id="note"
                autoComplete="off"
                value={payout.note}
                onChange={handleInputChange}
              />

              <div className="flex flex-wrap">
                <h3 className="mb-4 text-lg font-bold text-green-600  dark:text-white">
                  Payment Details
                </h3>
              </div>

              {!selectedReceiverDetails &&
                <label className="mb-1 text-sm text-gray-500 dark:text-white font-medium">
                  Please select a payee to view the payment details. A payee must be selected before you can proceed payment.
                </label>
              }

              {selectedReceiverDetails && payout.currency !== "" &&
                <>
                  {paymentDetailsFieldsRendering(selectedReceiverDetails.value)}
                </>
              }

              <div className="flex justify-center"><hr className="mb-3 mt-4 w-1/3" /></div>

              <label className="flex items-start space-x-2 cursor-pointer">
                <input
                  disabled={isCreating}
                  id="indicate_terms"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-navy-600 dark:text-white mt-1 flex-shrink-0"
                  onChange={handleInputChange}
                />
                <span className="text-1xl text-navy-700 dark:text-white">
                  On behalf of <strong>'{subaccountName}'</strong>, I accept that the Payout details entered above are correct.
                </span>
              </label>

            </div>
          </div>
          <div className="flex flex-wrap justify-end">
            <div className="mt-4">
              <Button
                onClick={() => handleSubmit()}
                isDisabled={!isFormValid}
                isLoading={isCreating}
                loadingText='Processing...'
                className="rounded-md bg-green-600 px-3 py-2.5 text-sm font-medium text-white transition duration-200 hover:bg-green-700 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
              >
                Create Payout
              </Button>
            </div>
          </div>
        </>

      </Card >

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
    </form>
  );
};

export default CreatePayout;
