/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import Logo from "assets/img/logo-light.svg";
import Card from "components/card";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Footer from "components/footer/FooterAuthDefault";

const NotFound: React.FC<{
  hideFooter?: boolean;
  isAuthenticated?: boolean;
}> = ({ hideFooter, isAuthenticated }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  useEffect(() => {
    const initialize = async () => {
      document.title = "Not Found";

      const element = document.querySelector('.relative.float-right.h-full.min-h-screen.w-full.bg-white');
      if (element) {
        element.classList.replace('bg-white', 'bg-darkPrimary');
      }
    };

    initialize();
  }, []);

  const navigate = useNavigate();

  const navigateToOirinalPage = async () => {
    if(isAuthenticated) {
      window.location.href = `/${userRole.toLowerCase()}/dashboard`;
    } else {
      navigate(`/auth/sign-in`);
    }
  };

  return (
    <>
      <div className="mb-auto flex flex-col items-center justify-center md:pr-0 lg:pl-0 xl:max-w-full">
        <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
          <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
            <div className="mb-8 flex items-center justify-center">
              <img src={Logo} alt="Payswiftly" width="229px" />
            </div>
            <Card
              className="bg-white p-8 sm:rounded-md shadow-3xl"
            >
              <h4 className="text-xl font-bold text-navy-700 dark:text-white mb-6 text-center">
                Oops... Not Found
              </h4>
              <p className="mb-3 text-base text-center text-gray-600">
                The requested resource cloud not be found!
              </p>
              <Button
                onClick={navigateToOirinalPage}
                className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
              >
                Back to { isAuthenticated ? "dashboard" : "home"}
              </Button>
            </Card>
          </div>
        </div>
      </div>
      { !hideFooter && 
        <Footer />
      }
    </>
  );
}

export default NotFound;
