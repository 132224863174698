/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useState, useMemo, useCallback } from "react";
import SweetAlert2 from "react-sweetalert2";

interface Role {
  role: string;
  merchantId: string;
  subaccountId: string;
}
interface DeleteRoleModalProps {
  username: string;
  email: string;
  merchantName: string;
  subaccountname: string;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRole: any;
  setUserRoles: any;
  editUserChannel: BroadcastChannel;
  fetchUserRoles: any;
  setIsDeleting: any;
  userRoles: Role[];
}

const DeleteRoleModal: React.FC<DeleteRoleModalProps> = ({
  username,
  email,
  merchantName,
  subaccountname,
  showForm,
  setShowForm,
  selectedRole,
  setUserRoles,
  editUserChannel,
  fetchUserRoles,
  setIsDeleting,
  userRoles
}) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const sysUsername = localStorage.getItem("username")?.toLowerCase();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [swalProps, setSwalProps] = useState<any>({});

  const handleDelete = async () => {
    setIsSubmitting(true);
    setIsDeleting(true);
    const selectedRoles: Role[] = userRoles.filter(roleObj => roleObj.role === selectedRole.role);
    const selectedRolesCount: number = selectedRoles.length;
    if (selectedRolesCount === 1) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/user/delete-user-role`,
          {
            data: {
              email: email,
              username: username,
              role: selectedRole?.role,
              merchantId: selectedRole?.merchantId,
              subaccountId: selectedRole?.subaccountId,
              userRole,
              sysUsername,
            },
          }
        );

        setShowForm(false);
        setSwalProps({
          show: true,
          icon: "success",
          title: "Success",
          html: "Role successfully unassigned",
          showConfirmButton: true,
          didClose: () => {
            setIsDeleting(false);
            setSwalProps({});
            setIsSubmitting(false);
            fetchUserRoles(true);
          },
        });
      } catch (error) {
        console.error("Error deleting user role:", error);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Failed to delete role. Please try again.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsSubmitting(false);
            setIsDeleting(false);
          },
        });
      }
    } else {
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Please select row to remove",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
          setIsSubmitting(false);
          setIsDeleting(false);
        },
      });
    }
  };

  return (
    <>
      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
      <SweetAlert2
        show={showForm}
        title="Are you sure?"
        html={`Are you sure you want to unassign the role <b>${selectedRole?.role}</b> from merchant <b>${merchantName}</b> and subaccount <b>${subaccountname}</b>?`}
        icon="warning"
        showCancelButton
        showConfirmButton
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        reverseButtons
        didClose={() => {
          setShowForm(false);
          setIsSubmitting(false);
        }}
        onConfirm={handleDelete}
        customClass={{
          actions: "custom-swal2-popup-action",
          title: "custom-swal2-popup-title",
          confirmButton:
            "custom-swal2-popup-action custom-swal2-popup-action-confirm btn btn-primary",
          cancelButton:
            "custom-swal2-popup-action custom-swal2-popup-action-cancel btn btn-default",
        }}
      />
    </>
  );
};

export default DeleteRoleModal;
