import { useEffect, useState } from "react";
import axios from "axios";
import SweetAlert2 from "react-sweetalert2";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import InfoTab from "./components/InfoTab";
import SecurityTab from "./components/SecurityTab";
import SettingsTab from "./components/SettingsTab";

const Profile = () => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const [profileReload, setProfileReload] = useState<any>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [swalProps, setSwalProps] = useState<any>({});

  const userid = localStorage.getItem("userid")?.toLowerCase();

  const fetchUserProfile = async (isInit: boolean = true, isUpdate: boolean = false) => {
    try {

      if(isInit) {
        setIsFetching(true);
      }

      if(isUpdate) {
        setProfileReload(true);
      }

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/user/get-user-detail/${userid}`
      );

      const userData = response.data.user;

      setUserProfile(userData);
      setIsFetching(false);
      setProfileReload(false);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []); // eslint-disable-line

  const reloadProfile = async () => {
    fetchUserProfile(false, true);
  };

  return (
    <>
      <Tabs className="mt-6 card">
        <TabList className="flex rounded-tl-custom rounded-tr-custom">
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${!userProfile ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Info</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${!userProfile ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Security</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${!userProfile ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Settings</strong>
          </Tab>
        </TabList>
        <TabPanels className="border-t border-gray-200 bg-white rounded-bl-custom rounded-br-custom">
          <TabPanel>
            <InfoTab setSwalProps={setSwalProps} isFetching={isFetching || profileReload} userProfile={userProfile} />
          </TabPanel>
          <TabPanel>
            <SecurityTab setSwalProps={setSwalProps} isFetching={isFetching} />
          </TabPanel>
          <TabPanel>
            <SettingsTab setSwalProps={setSwalProps} isFetching={isFetching} userProfile={userProfile} triggerReload={reloadProfile}/>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default Profile;
