import React, { ReactElement, useState, useContext } from "react";
import { Box } from "@mui/material";
import { Badge } from '@chakra-ui/react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import PayoutCancelConfirmModal from "../../shared/PayoutCancelConfirmModal";
import ReceiverAccountDetailsModal from "../../shared/ReceiverAccountDetailsModal";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { ProfileContext } from "../../../../store/ProfileProvider";
import DisplayAmount from "../../shared/DisplayAmount";

dayjs.extend(utc);
dayjs.extend(timezone);

type Subaccount = {
  id: string;
  name: string;
};
type Merchant = {
  id: string;
  name: string;
};

type Receiver = {
  id: string;
  firs_tname: string;
  last_name: string;
  email: string;
};

type ReceiverAccount = {
  id: string;
  email: string;
  method_details: Object;
};

type PayoutProvider = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  status: string;
  amount: string;
  asset: string;
  created_at: string;
  note: string;
  merchant: Merchant;
  subaccount: Subaccount;
  transactions: any[];
  receiver: Receiver;
  receiver_account: ReceiverAccount;
  payout_provider: PayoutProvider;
  cause: any;
  reference_tracking: any;
  method_name: string;
};

const BatchPayoutList: React.FC<{
  userPermissions: any;
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  triggerReloadPayoutList: any
  batchType: any;
  batchFileBucket: any;
  batchFileKey: any;
  batchStatus: any;
}> = ({ userPermissions, isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey, triggerReloadPayoutList, batchType, batchFileBucket, batchFileKey, batchStatus }) => {

  const { profileTimeZone } = useContext(ProfileContext);
  const [payoutToCancel, setPayoutToCancel] = useState<any>(null);
  const [isOpenPayoutCancelConfirmModal, setIsOpenPayoutCancelConfirmModal] = useState<boolean>(false);

  const [isOpenReceiverAccountDetailModal, setIsOpenReceiverAccountDetailModal] = useState<boolean>(false);
  const [receiverAccountDetailToDisplay, setReceiverAccountDetailToDisplay] = useState<any>(null);

  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  interface PayoutStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }


  const statusClasses = 'status';

  const statusList: PayoutStatusItems[] = [
    { status: "awaiting", value: 'awaiting', display: <Badge className={`${statusClasses} status-awaiting`}>Awaiting</Badge> },
    { status: "sent_mail", value: 'sent_mail', display: <Badge className={`${statusClasses} status-sent_mail`}>Email Sent</Badge> },
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "unassessed", value: 'unassessed', display: <Badge className={`${statusClasses} status-unassessed`}>Unassessed</Badge> },
    { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
    { status: "successful", value: 'successful', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
    { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
    { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
    { status: "successful_pending_kyc", value: 'successful_pending_kyc', display: <Badge className={`${statusClasses} status-successful_pending_kyc`}>Successful Pending KYC</Badge> }
  ];

  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created Date",
      sortable: false,
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "subaccount",
      headerName: "Subaccount",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.subaccount.name,
    },
    {
      field: "amount", headerName: "Amount", minWidth: 80, sortable: false, headerAlign: 'right', align: 'right',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        <DisplayAmount amount={Number(params.row.amount)} currency={params.row.asset} />
    },
    { field: "asset", headerName: "Asset", minWidth: 60, sortable: false, headerAlign: 'center', align: 'center' },
    {
      field: "receiver",
      headerName: "Email",
      sortable: false,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.receiver.email,
    },
    {
      field: "account_detail",
      headerName: "Account",
      sortable: false,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Account Detail" arrow placement="right">
              <IconButton
                id={"button-details-" + params?.row?.id}
                onClick={() => handleAccountClickDetails(params.row.id)}
              >
                <AiOutlineInfoCircle className="h-5 w-5 text-navy-700" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    { field: "note", headerName: "Note", sortable: false, flex: 1 },
    { field: "reference", headerName: "Reference", minWidth: 170, sortable: false, flex: 1, },
    { field: "reference_tracking", headerName: "Ref. Tracking", minWidth: 170, sortable: false },
    {
      field: "status", headerName: "", sortable: false, flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const status = params.value as string;
        const statusItem = statusList.find(item => item.status === status);
        return (
          <>
            {userRole === 'superadmin' && status === 'failed' && params.row.cause && params.row.cause.fail ? (
              <Tooltip title={params.row.cause.fail} arrow placement="top">
                <div className="cursor-help">{statusItem ? statusItem.display : null}</div>
              </Tooltip>
            ) : (
              <>
                {statusItem ? statusItem.display : null}
              </>
            )}
          </>
        );
      }
    },
    {
      field: "actions", headerName: "", sortable: false, flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const { status } = params?.row || {};
        const canCancel = userPermissions.cancelPayout === true && (
          status === "awaiting"
          ||
          (
            userRole === "superadmin" &&
            status === "pending" &&
            ["open", "close", "queue"].includes(batchStatus) &&
            batchFileBucket === null &&
            batchFileKey === null
          )
          ||
          (
            (userRole === "merchant" || userRole === "admin") &&
            status === "pending" &&
            batchStatus === "open"
          )
        );
    
        return (
          <>
            {canCancel && (
              <Tooltip title="Cancel" arrow placement="right">
                <div>
                  <IconButton
                    id={`button-detail-${params?.row?.id}`}
                    onClick={() => handleClickCancel(params.row.id)}
                  >
                    <MdOutlineCancel className="h-5 w-5 status-cancelled" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </>
        );
      },
    }    
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(profileTimeZone).format("DD MMM YY - hh:mm A");
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start text-md',
    row: ''
  };

  const filterColumns = () => {
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    let rebuildColumns = [];
    if (userRole !== "superadmin") {
      if(userRole === "user") {
        const excludeFields = ["subaccount","actions"];
        rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
      } else {
        const excludeFields = ["subaccount"];
        rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
      }
    } else {
      rebuildColumns = columns;
    }
    return rebuildColumns;
  };

  const handleClickCancel = (id: any) => {
    const payout = tableData.filter((x: any) => x.id === id)[0];
    setPayoutToCancel(payout);
    showPayoutCancelConfirmModal();
  };

  const showPayoutCancelConfirmModal = () => {
    setIsOpenPayoutCancelConfirmModal(true);
  }

  const closePayoutCancelConfirmModal = () => {
    setPayoutToCancel(null);
    setIsOpenPayoutCancelConfirmModal(false);
  }

  const handleAccountClickDetails = (id: any) => {
    const payoutRecord = tableData.filter((x: any) => x.id === id)[0];
    const accountDetail = {
      asset: payoutRecord.asset,
      method_name: payoutRecord.method_name,
      method_details: payoutRecord.receiver_account?.method_details
    }
    setReceiverAccountDetailToDisplay(accountDetail);
    showAccountDetailsModal();
  };

  const showAccountDetailsModal = () => {
    setIsOpenReceiverAccountDetailModal(true);
  }

  const closeAccountDetailsModal = () => {
    setReceiverAccountDetailToDisplay(null);
    setIsOpenReceiverAccountDetailModal(false);
  }

  return (
    <>
      <PayoutCancelConfirmModal triggerReload={triggerReloadPayoutList} isOpen={isOpenPayoutCancelConfirmModal} payoutToCancel={payoutToCancel} onClose={() => closePayoutCancelConfirmModal()} />
      {receiverAccountDetailToDisplay &&
        <ReceiverAccountDetailsModal detail={receiverAccountDetailToDisplay} isOpen={isOpenReceiverAccountDetailModal} onClose={() => closeAccountDetailsModal()} />
      }
      <Box
        className="w-full card"
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={filterColumns()}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>
    </>
  );
};

export default BatchPayoutList;
