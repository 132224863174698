import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button, Select } from '@chakra-ui/react';
import DisplayAmount from "../../shared/DisplayAmount";

interface ProviderAccount {
    label: string;
    value: string;
}

interface Provider {
    id: string;
    name: string;
    provider_name: string;
    provider_account: ProviderAccount[];
    method: any[];  // Update this type according to your actual method structure
}

interface ProviderMethod {
    label: string;
    value: string;
}

const BatchAssignProviderModal: React.FC<{
    batchToAssignProvider: any;
    batchProviderList: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, batchToAssignProvider, batchProviderList, setSwalProps }) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [providerAccount, setProviderAccount] = useState<string>("");
    const [method, setMethod] = useState<string>("");
    const [providerAccountList, setProviderAccountList] = useState<any>([]);
    const [methodList, setMethodList] = useState<any>([]);
    const [providerAccountMethodMap, setProviderAccountMethodMap] = useState<any>([]);
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const isFormValid = providerAccount !== "" && method !== "" ? true : false;

    const closeModal = async () => {
        setProviderAccount("");
        setMethod("");
        setMethodList([]);
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const providerName = getProviderNameByAccountId(providerAccount);
            const providerAccountName = getProviderAccountNameById(providerAccount);
            const methodName = getMethodNameByMethodId(method);

            const postData = {
                batch_id: batchToAssignProvider.id,
                provider_account_id: providerAccount,
                method_id: method,
                provider: providerName,
                provider_account: providerAccountName,
                method: methodName,
                username: localStorage.getItem("username"),
                userRole: userRole,
                subaccount_id: batchToAssignProvider.subaccount.id
            }

            await axios.post(process.env.REACT_APP_API_URL + `/api/batch/assign-provider`, postData)
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "The provider has been assigned",
                            showConfirmButton: true,
                            didClose: () => {
                                setProviderAccount("");
                                setMethod("");
                                triggerReload();
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const handleProviderDropdownChange = async (event: any) => {
        setMethodList([]);
        setMethod("");

        const { value } = event.target;
        setProviderAccount(value);
        if(value !== "") {
            const getProviderAccountMethod = providerAccountMethodMap.find((x: any) => x.account_id === value);
            setMethodList(getProviderAccountMethod.methods);
        }
    };

    const handleProviderMethodDropdownChange = async (event: any) => {
        const { value } = event.target;
        setMethod(value);
    };

    function getProviderNameByAccountId(accountId: string): string | undefined {
        const provider = batchProviderList.find((provider: Provider) =>
            provider.provider_account.some((account: any) => account.id === accountId)
        );
        return provider?.name;
    }

    function getProviderAccountNameById(providerAccountId: string): string | undefined {
        const provider = batchProviderList.find((provider: any) =>
            provider.provider_account.some((account: any) => account.id === providerAccountId)
        );

        const account = provider?.provider_account.find((account: any) => account.id === providerAccountId);

        return account?.account_name;
    }

    function getMethodNameByMethodId(methodId: string): string | undefined {
        const provider = batchProviderList.find((provider: Provider) =>
            provider.method.some((method: any) => method.id === methodId)
        );
        const method = provider?.method.find((method: any) => method.id === methodId);
        return method?.classfile;
    }

    const buildUpProviderAndMethodList = (batchProviderList: any) => {
        const providerAccountList: ProviderAccount[] = [];
        const providerAccountMethodMap: { account_id: string; methods: ProviderMethod[] }[] = [];

        if (batchProviderList && Array.isArray(batchProviderList)) {
            batchProviderList.forEach((provider: any) => {
                if (provider?.provider_account && Array.isArray(provider.provider_account)) {
                    provider.provider_account.forEach((account: any) => {
                        if (account?.id && account?.account_name) {
                            providerAccountList.push({
                                label: `${provider.name} (${account.account_name})`,
                                value: account.id,
                            });

                            // Collect methods associated with this account's provider
                            const methodsForAccount = provider.method?.map((method: any) => ({
                                label: method.name,
                                value: method.id
                            })) || [];

                            providerAccountMethodMap.push({
                                account_id: account.id,
                                methods: methodsForAccount,
                            });
                        }
                    });
                }
            });

            setProviderAccountList(providerAccountList);
            setProviderAccountMethodMap(providerAccountMethodMap);
        }

        return batchProviderList;
    };

    useEffect(() => {
        buildUpProviderAndMethodList(batchProviderList);
    }, [batchProviderList]);

    return (
        <>
            {batchToAssignProvider &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Assign provider to batch</h1>
                                <div className="mb-[20px] text-sm">
                                    <div className="mb-4">
                                        You can assign provider until the batch is set to <strong className="text-sm">{batchToAssignProvider.is_manual === "true" ? "QUEUED" : "IN PROGRESS"}</strong>.
                                        <br />
                                        Assign a new provider will replace the current one.
                                    </div>
                                    {userRole === 'superadmin' && batchToAssignProvider.provider_name && batchToAssignProvider.provider_account_name && batchToAssignProvider.provider_method_name && 
                                        <>
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Current Provider:</span> {batchToAssignProvider !== null && batchToAssignProvider.provider_name !== null ? batchToAssignProvider.provider_name + " (" + batchToAssignProvider.provider_account_name + ")" : (<>{"-"}</>)}
                                            </div>
                                            <div className="mb-1">
                                                <span className="mr-1 font-bold">Current Provider Method:</span> {batchToAssignProvider !== null && batchToAssignProvider.provider_method_name !== null ? batchToAssignProvider.provider_method_name : (<>{"-"}</>)}
                                            </div>
                                        </>
                                    }
                                    {userRole !== 'superadmin' &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Reference:</span> {batchToAssignProvider.batch_reference}
                                        </div>
                                    }
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Record(s):</span> {batchToAssignProvider.record}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Amount:</span> <DisplayAmount amount={Number(batchToAssignProvider.amount)} currency={batchToAssignProvider.asset} /> {batchToAssignProvider.asset}
                                    </div>
                                    <div className="flex justify-center"><hr className="mb-3 mt-4 w-full" /></div>
                                    <div className="mb-[20px]">
                                        <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                                            Provider *
                                        </label>
                                        <Select name="provider" isDisabled={isProcessing} id="provider" value={providerAccount} onChange={handleProviderDropdownChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                                            <option value="">Please Select</option>
                                            {providerAccountList.map((providerAccount: any) => (
                                                <option key={providerAccount.value} value={providerAccount.value}>{providerAccount.label}</option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="mb-[20px]">
                                        <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                                            Provider Method *
                                        </label>
                                        <Select name="method" isDisabled={isProcessing} id="method" value={method} onChange={handleProviderMethodDropdownChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                                            <option value="">Please Select</option>
                                            {methodList.map((method: any) => (
                                                <option key={method.value} value={method.value}>{method.label}</option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isDisabled={!isFormValid}
                                        isLoading={isProcessing}
                                        loadingText='Processing...'
                                        className="btn btn-primary"
                                    >
                                        Assign
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default BatchAssignProviderModal;
