/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Button, Select } from '@chakra-ui/react';
import timezone from '../resources/timzone.json';
import { ProfileContext } from "../../../../store/ProfileProvider";

const SettingsTab: React.FC<{
  setSwalProps: any;
  userProfile: any;
  isFetching: boolean;
  triggerReload: any;
}> = ({ setSwalProps, userProfile, isFetching, triggerReload }) => {
  const { setProfileTimeZone } = useContext(ProfileContext);
  const [settingsData, setSettingsData] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const userid = localStorage.getItem("userid");
  const userRole = localStorage.getItem("userRole");
  const username = localStorage.getItem("username");

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setSettingsData({ ...settingsData, [id]: value });
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      const data = {
        userid: userid,
        userRole: userRole,
        sysUsername: username,
        fn: "update-settings",
        ...settingsData
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/user/update-user`, data)
        .then(function (response) {
          setIsProcessing(false);
          if (response && response.data?.status === 'ok') {
            setSwalProps({
              show: true,
              icon: "success",
              title: "Success",
              html: "Your settings has been update",
              timer: 2000,
              didClose: async () => {
                setSwalProps({});
                setProfileTimeZone(settingsData.time_zone);
                localStorage.setItem("time_zone", settingsData.time_zone);
                triggerReload();
              },
            });
          } else {
            setIsProcessing(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: "Something went wrong, please try again later.",
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsProcessing(false);
              },
            });
          }
        })
        .catch(function (error) {
          setIsProcessing(false);
          setSwalProps({
            show: true,
            icon: "error",
            title: "Oops!",
            html: error.response.data.message,
            showConfirmButton: true,
            didClose: () => {
              setSwalProps({});
              setIsProcessing(false);
            },
          });
        });
    } catch (error: any) {
      setIsProcessing(false);
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Something went wrong, please try again later.",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
          setIsProcessing(false);
        },
      });
    }
  };

  useEffect(() => {
    if (userProfile !== null) {
      setSettingsData(userProfile);
    }
  }, [userProfile]); // eslint-disable-line

  const getTimezoneOffset = (tzName: string): string => {
    try {
      const now = new Date();
      const standardTime = new Intl.DateTimeFormat('en-US', {
        timeZone: tzName,
        timeZoneName: 'longOffset',
      }).formatToParts(now).find(part => part.type === 'timeZoneName')?.value || '';

      return standardTime.replace('GMT', 'UTC');
    } catch (error) {
      console.error(`Invalid Timezone: ${tzName}`, error);
      return 'UTC+00:00';
    }
  };

  const replaceUnderscoreWithSpace = (str: string) => {
    return str.replace(/_/g, ' ');
  };

  return (
    <>
      <div className="p-7">
        {settingsData !== null ? (
          <>
            <div className="mb-[20px] text-sm">
              <div className="mb-3">
                <label className="text-sm text-navy-700 dark:text-white font-medium">
                  Time Zone
                </label>
                <Select
                  disabled={isProcessing || !settingsData}
                  id="time_zone"
                  value={settingsData.time_zone}
                  onChange={handleInputChange}
                  className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-[30%] shadow-sm"
                >
                  {timezone && timezone.length > 0 && timezone.map((item: any) => {
                    const currentOffset = getTimezoneOffset(item.name);
                    return (
                      <option key={item.name} value={item.name}>
                        {replaceUnderscoreWithSpace(item.name)} ({currentOffset})
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="flex gap-2 mt-3 justify-start">
              <Button
                disabled={isProcessing}
                onClick={() => handleSubmit()}
                isLoading={isProcessing}
                loadingText='Processing...'
                className="btn btn-primary"
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default SettingsTab;
