/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import UserTable from "./components/UserTable";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Box } from "@chakra-ui/react";

interface Filters {
  [key: string]: any;
}

const initialFilters: Filters = {
  email: "",
  username: "",
  status: "",
  createdDateRange: [null, null],
};

const UserManagement = () => {
  const role = localStorage.getItem("userRole");
  const [currentUsername, setCurrentUsername] = useState("");

  useEffect(() => {
    const init = async () => {
      const userInfo = await Auth.currentAuthenticatedUser();
      setCurrentUsername(userInfo.username);
    };
    init();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(10);

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (currentUsername) {
      fetchUsers();
    }
  }, [page, pageSize, currentUsername]);

  const fetchUsers = async (clear = false) => {
    try {
      setIsLoading(true);
      const { email, username, status, createdDateRange } = !clear ? filters : initialFilters;

      const startDate = createdDateRange[0]?.toISOString() || "";
      const endDate = createdDateRange[1]?.toISOString() || "";

      const params = {
        email,
        username,
        status,
        startDate,
        endDate,
        page: ((!clear ? page : 0) + 1).toString(),
        pageSize: pageSize.toString(),
        currentUsername,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/get-all-users`,
        { params }
      );
      let { data, totalCount } = response.data;

      setTableData(data);
      setRowCount(totalCount);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setTableData([]);
      setIsLoading(false);
      setRowCount(0);
    }
  };

  const handleSearch = () => {
    setFilters(filters);
    setPage(0);
    fetchUsers();
  };

  const handleClear = () => {
    setFilters(initialFilters);
    setTableData([]);
    setPage(0);
    fetchUsers(true);
  };

  const setPaginationModel = (newValue: any) => {
    setPage(newValue.page);
    setPageSize(newValue.pageSize);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <UserTable
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={tableData}
        setTableData={setTableData}
        setRowCount={setRowCount}
        setPaginationModel={setPaginationModel}
        currentUsername={currentUsername}
        currentUserRole={role}
        handleSearch={handleSearch}
        handleClear={handleClear}
        initialFilters={initialFilters}
        filters={filters}
        setFilters={setFilters}
        setIsLoading={setIsLoading}
        fetchUsers={fetchUsers}
      />
    </Box>
  );
};

export default UserManagement;
