/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import { Button, Radio, RadioGroup, Stack, FormLabel, Select } from '@chakra-ui/react';

const AccountLedgerAdjustmentTab: React.FC<{
  detail: any;
  setSwalProps: any;
  setIsProcessing: any;
  isProcessing: boolean;
  fetchAccountDetail: any;
}> = ({ detail, setSwalProps, setIsProcessing, isProcessing, fetchAccountDetail }) => {

  const [accountDetailForEdit, setAccountDetailForEdit] = useState<any>(null);
  const [isInvalidURL, setIsInvalidURL] = useState<boolean>(false);

  useEffect(() => {
    setAccountDetailForEdit(detail);
  }, [detail]);

  const isURL = (str: string): boolean => {
    const pattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?(\?.*)?(#.*)?$/i;
    return pattern.test(str);
  };

  const handleInputChange = async (event: any) => {
    setIsInvalidURL(false);
    const { id, value } = event.target;
    setAccountDetailForEdit({ ...accountDetailForEdit, [id]: value });
    if (id === "postback_url") {
      if (!isURL(value)) {
        setIsInvalidURL(true);
      }
    }
  };

  const isFormValid = accountDetailForEdit && accountDetailForEdit.postback_url

  const handleSubmit = async () => {
    if (isFormValid) {

      const postData = {
        ...accountDetailForEdit,
        fn: "update-settings",
        username: localStorage.getItem("username"),
        userRole: localStorage.getItem("userRole")
      };

      try {
        setIsProcessing(true);
        await axios.post(
          process.env.REACT_APP_API_URL + `/api/subaccount/action`,
          postData
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: `Settings has been updated`,
                showConfirmButton: false,
                timer: 2000,
                didClose: () => {
                  setSwalProps({});
                  setIsProcessing(false);
                },
              });
            } else {
              setIsProcessing(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsProcessing(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsProcessing(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsProcessing(false);
              },
            });
          });
      } catch (error) {
        setIsProcessing(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsProcessing(false);
          },
        });
      }
    }
  };

  return (
    <>
      {accountDetailForEdit !== null ? (
        <div className="pr-10 pl-10 pt-5 pb-5">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 md:pr-4">
              <InputField
                disabled={isProcessing}
                variant="auth"
                extra="mb-3 shadow-sm number-input"
                label="Postback URL *"
                placeholder=""
                id="postback_url"
                type="text"
                autoComplete="off"
                value={accountDetailForEdit.postback_url}
                onChange={handleInputChange}
                error={isInvalidURL ? "Invalid URL format. Please enter a valid url, e.g. https://example.com" : undefined}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="mt-4">
              <Button
                onClick={() => handleSubmit()}
                isDisabled={!isFormValid || isProcessing || isInvalidURL}
                isLoading={isProcessing}
                loadingText='Processing...'
                className="btn btn-primary"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="pr-10 pl-10 pt-5 pb-5">
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
        </div>
      )}
    </>
  );
};

export default AccountLedgerAdjustmentTab;
