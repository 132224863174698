/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import AutoComplete from 'react-select';

const InputStyle =
  "daterange-picker font-bold text-navy-700 h-10 mt-2 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#4fb06d", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#65e68d", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#4fb06d", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
  dateHeadingProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
};

interface ProviderAccount {
  label: string;
  value: string;
}

interface Provider {
  id: string;
  name: string;
  provider_name: string;
  provider_account: ProviderAccount[];
  method: any[];  // Update this type according to your actual method structure
}

interface ProviderMethod {
  label: string;
  value: string;
}

const BatchSearch: React.FC<{
  isLoading: boolean;
  setIsFilterLoading: any;
  isFilterLoading: boolean;
  filters: any;
  setFilters: any;
  setSelectedDates: any;
  selectedDates: any;
  subaccountList: any;
  providerList: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
  assetList: any;
}> = ({ isLoading, setIsFilterLoading, isFilterLoading, filters, setFilters, setSelectedDates, selectedDates, subaccountList, providerList, handleSearchSubmit, handleSearchClear, assetList }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const [maxDate, setMaxDate] = useState<any>(new Date());

  const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);
  const subaccountAutoCompleteRef = useRef(null);
  const [providerAccountList, setProviderAccountList] = useState<any>([]);
  const [methodList, setMethodList] = useState<any>([]);
  const [providerAccountMethodMap, setProviderAccountMethodMap] = useState<any>([]);

  const handleClear = () => {
    subaccountAutoCompleteRef.current?.clearValue();
    setMethodList([]);
    handleSearchClear();
  };

  useEffect(() => {
    if (subaccountList !== null) {
      let rebuildListArray: any = [];
      if (subaccountList.length > 0) {
        subaccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.suba_name
          }
          rebuildListArray.push(newObj);
        });
      }

      setSubaccountAutoCompleteOptions(rebuildListArray);
    }
  }, [subaccountList]);

  const handleSubaccountAutoCompleteChange = (item: any) => {
    localStorage.setItem("subaccountIdToFilterBatch", "");
    if (item !== null) {
      localStorage.setItem("subaccountIdToFilterBatch", item.value);
      setFilters({ ...filters, subaccount_id: "" });
    } else {
      setFilters({ ...filters, subaccount_id: "" });
    }
  };

  const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
  };

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });

    if (id === 'date_type') {
      if (value === 'p') {
        setMaxDate(false);
      } else {
        setMaxDate(new Date());
      }
    }
  };

  const handleProviderDropdownChange = async (event: any) => {
    setMethodList([]);
    setFilters({ ...filters, payout_method: "" });

    const { value } = event.target;
    setFilters({ ...filters, provider_account: value });
    if (value !== "") {
      const getProviderAccountMethod = providerAccountMethodMap.find((x: any) => x.account_id === value);
      setMethodList(getProviderAccountMethod.methods);
    }
  };

  const handleProviderMethodDropdownChange = async (event: any) => {
    const { value } = event.target;
    setFilters({ ...filters, payout_method: value });
  };

  const buildUpProviderAndMethodList = (providerList: any) => {
    setIsFilterLoading(true);
    const providerAccountList: ProviderAccount[] = [];
    const providerAccountMethodMap: { account_id: string; methods: ProviderMethod[] }[] = [];

    if (providerList && Array.isArray(providerList)) {
      providerList.forEach((provider: any) => {
        if (provider?.provider_account && Array.isArray(provider.provider_account)) {
          provider.provider_account.forEach((account: any) => {
            if (account?.id && account?.account_name) {
              providerAccountList.push({
                label: `${provider.name} (${account.account_name})`,
                value: account.id,
              });

              const methodsForAccount = provider.method?.map((method: any) => ({
                label: method.method_name,
                value: method.id
              })) || [];

              providerAccountMethodMap.push({
                account_id: account.id,
                methods: methodsForAccount,
              });
            }
          });
        }
      });

      setProviderAccountList(providerAccountList);
      setProviderAccountMethodMap(providerAccountMethodMap);
      setIsFilterLoading(false);
    }
  };

  useEffect(() => {
    if (providerList) {
      buildUpProviderAndMethodList(providerList);
    }
  }, [providerList]);

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Date Type
          </label>
          <Select isDisabled={isLoading} id="date_type" value={filters.date_type} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="c">Created Date</option>
            <option value="p">Process Date</option>
          </Select>
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Date
          </label>
          <RangeDatepicker
            disabled={isLoading}
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            propsConfigs={dateRangePropsConfigs}
            configs={{
              dateFormat: 'MMM d, yyyy'
            }}
            maxDate={maxDate}
          />
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-4 text-sm text-navy-700 dark:text-white font-bold">
            Asset
          </label>
          <Select isDisabled={isLoading || !assetList} id="asset" value={filters.asset} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">{assetList ? "All" : "Retrieving..."}</option>
            {assetList && assetList.map((asset: any) => (
              <option key={asset.name} value={asset.name}>{asset.name}</option>
            ))}
          </Select>
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Status
          </label>
          <Select isDisabled={isLoading} id="status" value={filters.status} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="-2">Open</option>
            <option value="-1">Closed</option>
            <option value="0">Queued</option>
            <option value="1">In Progress</option>
            <option value="2">Settled</option>
            <option value="3">Settled with Error</option>
            <option value="4">Cancelled</option>
            <option value="5">Failed</option>
          </Select>
        </div>
      </div>
      <div className="flex w-full items-end">
        {userRole === 'superadmin' && (
          <>
            <div className="flex-1 w-1/4 p-2 pt-0">
              <label className="mb-4 text-sm text-navy-700 dark:text-white font-bold">
                Provider
              </label>
              <Select isDisabled={isLoading} id="provider_account" value={filters.provider_account} onChange={handleProviderDropdownChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                <option value="">{isLoading ? "Retrieving..." : "All"}</option>
                {providerAccountList.map((providerAccount: any) => (
                  <option key={providerAccount.value} value={providerAccount.value}>{providerAccount.label}</option>
                ))}
              </Select>
            </div>
            <div className="flex-1 w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Method
              </label>
              <Select isDisabled={isLoading || !filters.provider_account} id="method" value={filters.method} onChange={handleProviderMethodDropdownChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                <option value="">All</option>
                {methodList.map((method: any) => (
                  <option key={method.value} value={method.value}>{method.label}</option>
                ))}
              </Select>
            </div>
            <div className="w-1/4 p-2 pt-0 flex items-end">
              <div className="w-full">
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Subaccount
                </label>
                <AutoComplete
                  ref={subaccountAutoCompleteRef}
                  className="autocomplete-override mt-1"
                  classNamePrefix="search-payout-receiver"
                  isDisabled={isLoading || isFilterLoading || subaccountAutoCompleteOptions === null}
                  placeholder={subaccountAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
                  options={subaccountAutoCompleteOptions}
                  onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
                  noOptionsMessage={noSubaccountOptionsMessageRenderer}
                />
              </div>
            </div>
          </>
        )}
        <div className="flex-1 p-2 pt-0 flex justify-end items-end mt-3">
          <Button
            isDisabled={isLoading || isFilterLoading}
            onClick={() => handleClear()}
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading || isFilterLoading}
            onClick={() => handleSearchSubmit()}
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Search Batches
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default BatchSearch;
