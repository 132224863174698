import { useState, useEffect } from 'react';
import axios from "axios";
import moment from 'moment';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button, Select } from '@chakra-ui/react';
import InputField from "components/fields/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface PayloadType {
    [key: string]: any;
}

const TransactionExportModal: React.FC<{
    setSwalProps: any;
    isOpen: boolean;
    onClose(): void;
    userPermissions: any;
}> = ({ setSwalProps, isOpen, onClose, userPermissions }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subaccountList, setSubaccountList] = useState<any>([]);
    const [isOpenDatePanel, setIsOpenDatePanel] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [dateToExport, setDateToExport] = useState<Date>(null);
    const [receiverEmail, setReceiverEmail] = useState<string>("");
    const [subaccountid, setSubaccountid] = useState<string>("");
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const isFormValid = receiverEmail !== "" && dateToExport;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await fetchSubaccounts();
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const fetchSubaccounts = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_URL +
                `/api/subaccount/get-all-subaccounts`
            );

            const data = response.data.subaccounts?.payload;

            setSubaccountList(data);
        } catch (error) {
            console.error("Error fetching subaccounts", error);
        }
    };

    const closeModal = async () => {
        setDateToExport(null);
        setReceiverEmail("");
        setSubaccountid("");
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const firstAndLastDateOfMonth = getFirstAndLastDateOfMonth(dateToExport);
            const formattedFromDate = moment(firstAndLastDateOfMonth.firstDate, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
            const formattedToDate = moment(firstAndLastDateOfMonth.lastDate, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');
            const queryObject: PayloadType = {
                is_export: true,
                email: receiverEmail,
                from: formattedFromDate,
                to: formattedToDate,
                userRole: userRole,
                username: localStorage.getItem("username")
            };

            if (subaccountid !== "") {
                if (userRole === "superadmin") {
                    queryObject.subaccount_id = subaccountid;
                }
            } else {
                if (userRole !== "superadmin" && userPermissions.exportTransaction) {
                    queryObject.subaccount_id = localStorage.getItem("subaccountId");
                }
            }

            const queryString = Object.keys(queryObject)
                .map(
                    (key) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
                )
                .join("&");

            await axios.get(process.env.REACT_APP_API_URL + `/api/transaction/get-all-transactions?${queryString}`)
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        closeModal();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Your request has been successfully submitted",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                        setSwalProps({
                            show: true,
                            icon: "error",
                            title: "Oops!",
                            html: "Your request could not be submitted successfully, please try again",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                            },
                        });
                    }
                })
                .catch(function (error) {
                    console.log('error.response: ', error.response);
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const getFirstAndLastDateOfMonth = (date: Date) => {
        // First date of the month
        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

        // Last date of the month
        const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return { firstDate, lastDate };
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Export Transaction</h1>
                            <div className="mb-[20px] text-sm">
                                <div className="flex justify-center"><hr className="mb-3 w-full" /></div>
                                <div className="mb-3 export-date-picker-wrapper">
                                    <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                                        Month & year you'd like to export *
                                    </label>
                                    <DatePicker
                                        disabled={isProcessing}
                                        selected={dateToExport}
                                        onInputClick={() => setIsOpenDatePanel(!isOpenDatePanel)}
                                        onSelect={() => setIsOpenDatePanel(false)}
                                        onChange={(date: any) => setDateToExport(date)}
                                        dateFormat="MMM, yyyy"
                                        placeholderText="Please select"
                                        showMonthYearPicker
                                        open={isOpenDatePanel}
                                        className="!rounded-md !shadow-sm !focus:outline-none !outline-none !cursor-pointer text-primary"
                                        onKeyDown={(e) => e.preventDefault()}
                                        readOnly={true}
                                        maxDate={new Date()}
                                    />
                                </div>
                                <InputField
                                    disabled={isProcessing}
                                    variant="auth"
                                    extra="mb-3 shadow-sm number-input"
                                    label="Please fill in email to receive report *"
                                    placeholder=""
                                    id="receiver_email"
                                    type="text"
                                    autoComplete="off"
                                    value={receiverEmail}
                                    onChange={(e) => setReceiverEmail(e.target.value)}
                                />
                                {userRole === "superadmin" &&
                                    <div className="mb-[20px]">
                                        <label className="mb-1 block text-sm font-medium text-navy-700 dark:text-white">
                                            Subaccount
                                        </label>
                                        <Select
                                            name="subaccountid"
                                            isDisabled={isProcessing || isLoading}
                                            id="subaccountid"
                                            value={subaccountid}
                                            onChange={(e) => setSubaccountid(e.target.value)}
                                            className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm"
                                        >
                                            <option value="">{isLoading ? "Retreiving..." : "All"}</option>
                                            {subaccountList.map((item: any) => (
                                                <option key={item.id} value={item.id}>{item.suba_name}</option>
                                            ))}
                                        </Select>
                                    </div>
                                }
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    isDisabled={isProcessing}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    isDisabled={!isFormValid}
                                    isLoading={isProcessing}
                                    loadingText='Processing...'
                                    className="btn btn-primary"
                                >
                                    Export
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default TransactionExportModal;
