/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const InfoTab: React.FC<{
    setSwalProps: any;
    userProfile: any;
    isFetching: boolean;
}> = ({ setSwalProps, userProfile, isFetching }) => {

    const formatDate = (timestamp: string, timeZone: string) => {
        return dayjs.utc(timestamp).tz(timeZone).format("DD MMM YY - hh:mm A");
    };

    return (
        <>
            <div className="p-7">
                {userProfile !== null ? (
                    <>
                        <div className="flex flex-wrap justify-between text-sm text-navy-700 dark:text-white">
                            <div className="w-1/2 pr-4">
                                <div>
                                    <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                                        Username
                                    </label>
                                    <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                                        {userProfile.username}
                                    </div>
                                </div>
                                <div>
                                    <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                                        Latest Login
                                    </label>
                                    <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                                        {userProfile.latest_login ? formatDate(userProfile.latest_login, userProfile.time_zone) : '-'}
                                    </div>
                                </div>
                                <div>
                                    <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                                        Email
                                    </label>
                                    <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                                        {userProfile.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
                    </>
                )}
            </div>
        </>
    );
};

export default InfoTab;
