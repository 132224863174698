/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import DisplayAmount from "../../shared/DisplayAmount";

type RowData = {
  asset: string;
  available: string;
  pending: string;
  total: string;
};

const AccountBalancesTab: React.FC<{
  data: any;
  fetchingBalance: boolean;
}> = ({ data, fetchingBalance }) => {
  const [balancesData, setBalancesData] = useState<any>(null);

  useEffect(() => {
    setBalancesData(data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "asset",
      headerName: "Asset",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.asset,
    },
    {
      field: "available",
      headerName: "Available",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        <DisplayAmount amount={Number(params.row.available)} currency={params.row.asset} />
    },
    {
      field: "pending",
      headerName: "Pending",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        <DisplayAmount amount={Number(params.row.pending)} currency={params.row.asset} />
    },
    {
      field: "total",
      headerName: "Total",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        <DisplayAmount amount={Number(params.row.total)} currency={params.row.asset} />
    }
  ];

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start text-md',
    row: ''
  };

  return (
    <div className="p-7">
      <Box
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={(!balancesData ? true : false) || fetchingBalance}
          rows={balancesData || []}
          columns={columns}
          getRowId={(row) => row.asset}
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={true}
          autoHeight
        />
      </Box>
    </div>
  );
};

export default AccountBalancesTab;
