/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/card";
import { ImSpinner8 } from "react-icons/im";
import DefaultLogo from "assets/img/logo.svg";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate, useParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export default function ExpiredLink() {
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [logo, setLogo] = useState<any>(null);
  const navigate = useNavigate();
  const { subaccountid } = useParams();

  useEffect(() => {
    const initialize = async () => {
      const element = document.querySelector('.relative.float-right.h-full.min-h-screen.w-full.bg-darkPrimary');
      if (element) {
        element.classList.replace('bg-darkPrimary', 'bg-white');
      }

      await getSubaccountLogo();
    };

    initialize();
  }, []);

  const getSubaccountLogo = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-logo-by-id/${subaccountid}`
      );
      if (response.data.status === 'ok') {
        setIsValidating(false);
        setLogo(response.data.logo);
      } else {
        navigateToNotFoundPage();
      }
    } catch (error) {
      navigateToNotFoundPage();
    }
  };

  const navigateToNotFoundPage = async () => {
    navigate(`/404`);
  };

  return (
    <>
      {(isValidating || logo === null) &&
        <div className="flex items-center justify-center h-screen">
          <ImSpinner8 className="spinner" style={{ fontSize: '2rem' }} />
        </div>
      }
      {!isValidating && logo !== null &&
        <div className="mb-auto flex flex-col items-center justify-center md:pr-0 lg:pl-0 xl:max-w-full">
          <input type="hidden" id="account-type-value" name="accountType" />
          <div className="mb-16 mt-4 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
            <div className="mt-[10vh] w-[550px] flex-col items-center md:pl-4 lg:pl-0">
              <div className="mb-8 flex items-center justify-center">
                <img src={logo !== "" ? logo : DefaultLogo} alt="Logo" width="229px" />
              </div>
              <Card
                className="bg-white p-8 sm:rounded-md shadow-3xl"
              >
                <h4 className="text-xl font-bold text-navy-700 dark:text-white mb-6 text-center">
                  Oops... This link has expired
                </h4>
                <p className="mb-3 text-base text-center text-gray-700">
                  The link you followed is no longer valid. Please request a new one or contact support for assistance.
                </p>
              </Card>
            </div>
          </div>
        </div>
      }
    </>
  );
}
