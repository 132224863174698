/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import PayoutSearch from "./components/PayoutSearch";
import PayoutList from "./components/PayoutList";
import { Box } from "@chakra-ui/react";
import moment from "moment";

interface Filters {
  [key: string]: any;
}

interface PayloadType {
  [key: string]: any;
}

const rowPerPage = 10;

const initFrom = moment().subtract(1, 'month');
const initTo = moment();
const initialDateRangeFilter = [initFrom.toDate(), initTo.toDate()];

const initialFilters: Filters = {
  from: initFrom,
  to: initTo,
  field: "",
  keyword: "",
  merchant_id: "",
  subaccount_id: "",
  receiver_id: "",
  receiver_account_id: "",
  asset: "",
  status: "",
};

const Payout = ({ userPermissions }: { userPermissions: any }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDateRangeFilter);
  const subaccount_id = localStorage.getItem("subaccountId");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [merchantIdToGetSubaccount, setMerchantIdToGetSubaccount] = useState<any>(null);
  const [subaccountIdToGetReceiver, setSubaccountIdToGetReceiver] = useState<any>(null);
  const [receiverIdToGetReceiverAccount, setReceiverIdToReceiverAccount] = useState<any>(null);

  const [merchantListForSuperadmin, setMerchantListForSuperadmin] = useState<any>(null);

  const [subaccountListForSuperadmin, setSubaccountListForSuperadmin] = useState<any>(null);
  const [isSubaccountListForSuperadminLoading, setIsSubaccountListForSuperadminLoading] = useState<boolean>(false);

  const [receiverListForSuperadmin, setReceiverListForSuperadmin] = useState<any>(null);
  const [isReceiverListForSuperadminLoading, setIsReceiverListForSuperadminLoading] = useState<boolean>(false);

  const [receiverAccountListForSuperadmin, setReceiverAccountListForSuperadmin] = useState<any>(null);
  const [isReceiverAccountListForSuperadminLoading, setIsReceiverAccountListForSuperadminLoading] = useState<boolean>(false);

  const [receiverList, setReceiverList] = useState<any>(null);
  const [isReceiverListLoading, setIsReceiverListLoading] = useState<boolean>(true);
  const [receiverAccountList, setReceiverAccountList] = useState<any>(null);
  const [isReceiverAccountListLoading, setIsReceiverAccountListLoading] = useState<boolean>(false);

  const [assetList, setAssetList] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("merchantIdToFilterPayout");
      localStorage.removeItem("subaccountIdToFilterPayout");
      localStorage.removeItem("receiverIdToFilterPayout");
      localStorage.removeItem("receiverAccountIdToFilterPayout");
      
      await fetchPayouts({ page: 0, limit: rowPerPage, lastkey: "", ...filters });
      if(userRole === 'superadmin') {
        await fetchMerchantsForSuperadmin();
        await fetchAssetsForSuperadmin();
        setIsSubaccountListForSuperadminLoading(false);
        setIsReceiverListForSuperadminLoading(false);
        setIsReceiverAccountListForSuperadminLoading(false);
      } else {
        await fetchReceivers();
        await fetchAssets();
        setIsReceiverAccountListLoading(false);
      }

      setIsLoading(false);
      setIsFilterLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {      
      fetchSubaccountsForSuperadmin();
    };

    if (merchantIdToGetSubaccount) {
      fetchData();
    }
  }, [merchantIdToGetSubaccount]);

  useEffect(() => {
    const fetchData = async () => {      
      fetchReceiversForSuperadmin();
    };

    if (subaccountIdToGetReceiver) {
      fetchData();
    }
  }, [subaccountIdToGetReceiver]);

  useEffect(() => {
    const fetchData = async () => {
      if(userRole === 'superadmin') {
        fetchReceiverAccountsForSuperadmin();
      } else {
        fetchReceiverAccounts();
      }      
    };

    if (receiverIdToGetReceiverAccount) {
      fetchData();
    }
  }, [receiverIdToGetReceiverAccount]);

  const fetchPayouts = async (payload: PayloadType = {}, isReset = false) => {    
    try {

      const formattedFromDate = moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
      const formattedToDate = moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');

      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        subaccount_id: subaccount_id,
        from: formattedFromDate,
        to: formattedToDate,
        usertype: userRole === 'superadmin' ? "a" : "m"
      };

      const merchantId = localStorage.getItem("merchantIdToFilterPayout");
      const subaccountId = localStorage.getItem("subaccountIdToFilterPayout");
      const receiverId = localStorage.getItem("receiverIdToFilterPayout");
      const receiverAccountId = localStorage.getItem("receiverAccountIdToFilterPayout");

      if (payload?.field !== "" && payload?.field !== undefined && payload?.keyword !== "" && payload?.keyword !== undefined) {
        queryObject[payload?.field] = payload?.keyword
      }

      if(payload?.status !== "" && payload?.status !== undefined) {
        queryObject.status = payload?.status
      }

      if(payload?.asset !== "" && payload?.asset !== undefined) {
        queryObject.asset = payload?.asset
      }

      if(userRole === 'superadmin') {
        delete queryObject.subaccount_id;

        if (merchantId && merchantId !== "" && merchantId !== undefined) {
          queryObject.merchant_id = merchantId
        }

        if (subaccountId && subaccountId !== "" && subaccountId !== undefined) {
          queryObject.subaccount_id = subaccountId
        }
      }

      if (receiverId && receiverId !== "" && receiverId !== undefined) {
        queryObject.receiver_id = receiverId
      }

      if(receiverAccountId && receiverAccountId !== "" && receiverAccountId !== undefined) {
        queryObject.receiver_account_id = receiverAccountId
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/api/payout/get-all-payouts?${queryString}`
      );

      let newData = response.data.payouts?.payload;

      if (newData && newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);
      setIsReceiverAccountListLoading(false);

      // Manage Last Key and Total Record
      let newLastKey = response.data.payouts?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
        // console.log("newPage", newPage);
        // console.log("newPageSize", newPageSize);
        // console.log("setRowCount", (newPage + 1) * newPageSize + newPageSize);
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setIsReceiverAccountListLoading(false);
      // Clear all
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const fetchMerchantsForSuperadmin = async () => {
    try {
      let requestUrl = `/api/merchant/get-all-merchants`;
  
      const response = await axios.get(
      process.env.REACT_APP_API_URL +
      requestUrl
      );
  
      const data = response.data.merchants?.payload;
  
      setMerchantListForSuperadmin(data);
    } catch (error) {
      console.error("Error fetching merchants", error);
    }
  };

  const fetchSubaccountsForSuperadmin = async () => {
    try {
      let requestUrl = `/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantIdToGetSubaccount}`

      setIsSubaccountListForSuperadminLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.subaccounts?.payload;

      setSubaccountListForSuperadmin(data);
      setIsSubaccountListForSuperadminLoading(false);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const fetchReceiversForSuperadmin = async () => {
    try {
      let requestUrl = `/api/receiver/get-all-receivers?subaccount_id=${subaccountIdToGetReceiver}`

      setIsReceiverListForSuperadminLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.receivers?.payload;

      setReceiverListForSuperadmin(data);
      setIsReceiverListForSuperadminLoading(false);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const fetchReceiverAccountsForSuperadmin = async () => {
    if(receiverIdToGetReceiverAccount !== null) {
      try {
        setIsReceiverAccountListForSuperadminLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/receiveraccount?receiver_id=${receiverIdToGetReceiverAccount}`
        );
  
        const data = response.data.receiver_accounts?.payload;
  
        setReceiverAccountListForSuperadmin(data);
        setIsReceiverAccountListForSuperadminLoading(false);
      } catch (error) {
        console.error("Error fetching countries", error);
      }
    }
  };

  const fetchAssetsForSuperadmin = async () => {
    try {
      let requestUrl = `/api/datasource/assets`;

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response?.data;

      setAssetList(data);
    } catch (error) {
      console.error("Error fetching assets", error);
    }
  };

  const fetchReceivers = async () => {
    try {
      setIsReceiverListLoading(true);
      let requestUrl = `/api/receiver/get-all-receivers?subaccount_id=${subaccount_id}`;      

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.receivers?.payload;

      setReceiverList(data);
      setIsReceiverListLoading(false);
    } catch (error) {
      console.error("Error fetching receivers", error);
    }
  };

  const fetchReceiverAccounts = async () => {
    if(receiverIdToGetReceiverAccount !== null) {
      try {
        setIsReceiverAccountListLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/receiveraccount?receiver_id=${receiverIdToGetReceiverAccount}&usertype=m`
        );
  
        const data = response.data.receiver_accounts?.payload;
  
        setReceiverAccountList(data);
        setIsReceiverAccountListLoading(false);
      } catch (error) {
        console.error("Error fetching receiver accounts", error);
      }
    }
  };

  const fetchAssets = async () => {
    const subaccountId = localStorage.getItem("subaccountId");
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/subaccount/get-subaccount-by-id/${subaccountId}`
      );
      const accountData = response.data.subaccounts?.payload;
      const buildUpAsset = accountData.account_methods.reduce((acc: string[], item: any) => {
        if (!acc.includes(item.asset)) {
          acc.push(item.asset);
        }
        return acc;
      }, []);
      const formattedAssets = buildUpAsset.map((name: any) => ({ name }));
      setAssetList(formattedAssets);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      subaccount_id: subaccount_id,
      ...filters
    };

    // If click prev pagination
    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        subaccount_id: subaccount_id,
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    // setFilters(newFilters);
    fetchPayouts(newFilters);
    // }
  };

  const handleSearchSubmit = async () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    setCurrentLastKey("");
    await fetchPayouts({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
    setIsFilterLoading(false);
  };

  const handleSearchClear = async () => {
    localStorage.removeItem("merchantIdToFilterPayout");
    localStorage.removeItem("subaccountIdToFilterPayout");
    localStorage.removeItem("receiverIdToFilterPayout");
    localStorage.removeItem("receiverAccountIdToFilterPayout");

    setIsLoading(true);
    setIsFilterLoading(true);
    setFilters(initialFilters);
    setSubaccountListForSuperadmin(null);
    setReceiverListForSuperadmin(null);
    setReceiverAccountListForSuperadmin(null);
    setMerchantIdToGetSubaccount(null);
    setSubaccountIdToGetReceiver(null);
    setReceiverIdToReceiverAccount(null);
    setPage(0);
    setCurrentLastKey("");
    setLastKeys([]);
    setSelectedDates(initialDateRangeFilter);
    await fetchPayouts({ page: 0, limit: rowPerPage, lastkey: "", ...initialFilters }, true);
    setIsFilterLoading(false);
  };

  const triggerReloadPayoutList = async () => {
    setIsLoading(true);
    await fetchPayouts({ page: page, limit: rowPerPage, lastkey: page !== 0 ? currentLastKey : "", ...filters });
  }

  return (
    <Box sx={{ position: "relative" }}>
      {userRole === 'superadmin' &&
        <PayoutSearch
          userRole={userRole}
          isFilterLoading={isFilterLoading}
          filters={filters}
          setFilters={setFilters}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}

          merchantList={merchantListForSuperadmin}
          subaccountList={subaccountListForSuperadmin}
          receiverList={receiverListForSuperadmin}
          receiverAccountList={receiverAccountListForSuperadmin}

          isSubaccountListLoading={isSubaccountListForSuperadminLoading}
          isReceiverListLoading={isReceiverListForSuperadminLoading}
          isReceiverAccountListLoading={isReceiverAccountListForSuperadminLoading}

          setMerchantIdToGetSubaccount={setMerchantIdToGetSubaccount}
          setSubaccountIdToGetReceiver={setSubaccountIdToGetReceiver}
          setReceiverIdToReceiverAccount={setReceiverIdToReceiverAccount}

          handleSearchSubmit={handleSearchSubmit}
          handleSearchClear={handleSearchClear}
          assetList={assetList}
        />
      }
      {userRole !== 'superadmin' &&
        <PayoutSearch
          userRole={userRole}
          isFilterLoading={isFilterLoading}
          filters={filters}
          setFilters={setFilters}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}

          merchantList={null}
          subaccountList={null}
          receiverList={receiverList}
          receiverAccountList={receiverAccountList}

          isSubaccountListLoading={false}
          isReceiverListLoading={isReceiverListLoading}
          isReceiverAccountListLoading={isReceiverAccountListLoading}

          setMerchantIdToGetSubaccount={null}
          setSubaccountIdToGetReceiver={null}
          setReceiverIdToReceiverAccount={setReceiverIdToReceiverAccount}

          handleSearchSubmit={handleSearchSubmit}
          handleSearchClear={handleSearchClear}
          assetList={assetList}
        />
      }
      <PayoutList
        userPermissions={userPermissions}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
        triggerReloadPayoutList={triggerReloadPayoutList}
      />
    </Box>
  );
};

export default Payout;
