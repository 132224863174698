/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from "react";
import { Auth } from "aws-amplify";
import QRCode from 'react-qr-code';

import InputField from "components/fields/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Button } from "@chakra-ui/react";
import Logo from "assets/img/logo-light.svg";
import Card from "components/card";
import { useAuth } from "./../../routes/hooks/useAuth";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Tooltip, IconButton } from "@mui/material";
import { MdOutlineContentCopy } from "react-icons/md";
import { ProfileContext } from "../../store/ProfileProvider";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const activityTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function SignIn() {
  const { setProfileTimeZone } = useContext(ProfileContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { checkAuthStatus } = useAuth();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [showAutoSignedOutMessage, setShowAutoSignedOutMessage] = useState<boolean>(queryParams.get("idle") === "true" ? true : false);

  const [isTwoFactorSetupRequired, setIsTwoFactorSetupRequired] = useState<boolean>(false);
  const [isTwoFactorStep, setIsTwoFactorStep] = useState<boolean>(false);
  const [showVerifyOTPError, setShowVerifyOTPError] = useState<boolean>(false);
  const [showVerifyOTPErrorByUseOnce, setShowVerifyOTPErrorByUseOnce] = useState<boolean>(false);
  const [showPrepareError, setShowPrepareError] = useState<boolean>(false);
  const [showExpiredMessage, setShowExpiredMessage] = useState<boolean>(false);
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const [isProcessForceResetMFA, setIsProcessForceResetMFA] = useState<boolean>(false);

  const [signedInUser, setSignedInUser] = useState<any>(null);
  const [totpSecret, setTotpSecret] = useState<string>("");
  const [otpAuthUrl, setOtpAuthUrl] = useState<string>("");
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const otpInputRefs = useRef<HTMLInputElement[]>([]);
  const isOTPFormValid = otp.length === 6 ? true : false;

  useEffect(() => {

    document.title = "Payswiftly | Settlement System";

    localStorage.removeItem("subaccountsCount")

    const checkUserState = async () => {
      const authStatus = await checkAuthStatus();
      if (authStatus) {
        localStorage.clear();

        sessionStorage.removeItem("attemptedRefresh");

        Auth.signOut();
      }
    };

    localStorage.removeItem("startIdleCheck");

    const startIdleCheckId = localStorage.getItem("startIdleCheckId");
    clearInterval(startIdleCheckId);
    localStorage.removeItem("startIdleCheckId");

    const startCountdownId = localStorage.getItem("startIdleCheckId");
    clearInterval(startCountdownId);
    localStorage.removeItem("startCountdownId");

    checkUserState();
  }, []);

  useEffect(() => {
    if (queryParams.has('idle') && queryParams.get('idle') === 'true') {
      queryParams.delete('idle');
      const updatedUrl = `${location.pathname}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
      window.history.replaceState({}, '', updatedUrl);
    }
  }, [location.search]);

  useEffect(() => {
    const otpToVerify = otp.join("");
    if (String(otpToVerify).length === 6) {
      handleOTPSubmit();
    }
  }, [otp]);

  const navigate = useNavigate();
  const newPasswordError = !newPassword ? "" : "";

  const isFormValid = email !== "" && password !== "";
  const isNewPasswordValid = !newPasswordError;

  const signInAndUpdate = async (isFirstSignin: boolean = false, isFirstMFASetup: boolean = false) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const cognitoId = idToken.payload.sub;
    const username = idToken.payload["cognito:username"];
    const userGroups = idToken.payload["cognito:groups"];

    const responseAuth = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/auth`,
      { email: email, cognitoId: cognitoId, isProcessFirstSigninCompleted: isFirstSignin, isProcessForceResetMFA: isProcessForceResetMFA, isProcessTwoFactorFirstSetupCompleted: isFirstMFASetup }
    );
    let { token, expires, status, userid, time_zone } = responseAuth.data;

    if (token != null) {
      localStorage.setItem("apiToken", token);
      localStorage.setItem("apiTokenExpires", expires);
      localStorage.setItem("lastSignIn", dayjs().tz(time_zone ? time_zone : activityTimeZone).format());
    } else {
      setError("Something went wrong, please contact administrator");
      setIsLoading(false);
      return;
    }

    if (status === "Inactive") {
      setError("Your account is inactive, please contact administrator");
      setIsLoading(false);
      return;
    }

    if (userGroups === undefined) {
      setError("User group does not exist. Please contact Admin");
      setIsLoading(false);
      return;
    }

    const userRoles = responseAuth.data.userRoles;

    if (userRoles.length === 1) {
      const { role, merchantId, subaccountId } = userRoles[0];
      localStorage.setItem("userRole", role);
      localStorage.setItem("username", username);
      localStorage.setItem("userid", userid);
      
      localStorage.setItem("time_zone", time_zone ? time_zone : activityTimeZone);
      setProfileTimeZone(time_zone ? time_zone : activityTimeZone);

      localStorage.setItem("merchantId", merchantId);
      localStorage.setItem("subaccountId", subaccountId);
      localStorage.setItem("merchantsCount", '1');
      localStorage.setItem("subaccountsCount", '1');

      let systemLogDetail = '';

      if (role.toLowerCase() !== 'superadmin') {
        const subaccountInfo = await fetchSubaccountById(subaccountId, token);
        localStorage.setItem("subaccountName", subaccountInfo.suba_name);
        systemLogDetail = subaccountInfo.suba_name;
      } else {
        localStorage.setItem("subaccountName", '');
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/systemlog/create`,
        { suba_id: subaccountId, action: 'login', details: systemLogDetail, logged_role: role.toLowerCase(), logged_username: username, current_time_zone: time_zone, activity_time_zone: activityTimeZone },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      navigate(`/${role.toLowerCase()}/dashboard`);
    } else {
      const merchantIds: any = Array.from(
        new Set(userRoles.map((role: any) => role.merchantId))
      );

      if (merchantIds.length === 1) {
        const merchantId: string = merchantIds[0];
        localStorage.setItem("merchantId", merchantId);
        localStorage.setItem("merchantsCount", '1');

        const subaccountIds: any = Array.from(
          new Set(
            userRoles
              .filter((role: any) => role.merchantId === merchantId)
              .map((role: any) => role.subaccountId)
          )
        );

        if (subaccountIds.length === 1) {
          const subaccountId: string = subaccountIds[0];
          const selectedRole = userRoles.find(
            (role: any) =>
              role.merchantId === merchantId &&
              role.subaccountId === subaccountId
          );
          if (selectedRole) {
            localStorage.setItem("userRole", selectedRole.role);
            localStorage.setItem("subaccountId", subaccountId);
            localStorage.setItem("subaccountsCount", '1');

            const subaccountInfo = await fetchSubaccountById(subaccountId, token);
            const systemLogDetail = subaccountInfo.suba_name;
            await axios.post(
              `${process.env.REACT_APP_API_URL}/api/admin/systemlog/create`,
              { suba_id: subaccountId, action: 'login', details: systemLogDetail, logged_role: selectedRole.role.toLowerCase(), logged_username: username, current_time_zone: time_zone, activity_time_zone: activityTimeZone },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );

            navigate(`/${selectedRole.role.toLowerCase()}/dashboard`);
          }
        } else {
          localStorage.setItem("username", username);
          localStorage.setItem("userid", userid);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("subaccountsCount", subaccountIds.length);
          if(time_zone) {
            localStorage.setItem("time_zone", time_zone);
            setProfileTimeZone(time_zone);
          }
          navigate("/auth/subaccount-selection", {
            state: { merchantId, cognitoId, userRole: userRoles },
          });
        }
      } else {
        localStorage.setItem("username", username);
        localStorage.setItem("userid", userid);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("merchantsCount", merchantIds.length);
        if(time_zone) {
          localStorage.setItem("time_zone", time_zone);
          setProfileTimeZone(time_zone);
        }
        navigate("/auth/merchant-selection", {
          state: { cognitoId, userRole: userRoles },
        });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setError(null);
    setShowAutoSignedOutMessage(false);
    if (isFormValid) {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
        setSignedInUser(user);

        const getUserTwoFactorInfo = await axios.post(
          process.env.REACT_APP_API_URL + `/api/user/get-user-two-factor-info`,
          {
            email: email,
            username: user.username
          }
        );

        if(getUserTwoFactorInfo.data.status !== "ok") {
          setError("Something went wrong, please try again");
          setIsLoading(false);
          return;
        }

        const isForceResetTwoFactor = getUserTwoFactorInfo.data.user.isForceResetTwoFactor;
        const isMFAEnabledInPool = getUserTwoFactorInfo.data.user.isMFAEnabledInPool;
        const isUserMFAEnabled = getUserTwoFactorInfo.data.user.isUserMFAEnabled;

        if (isForceResetTwoFactor) {
          setIsProcessForceResetMFA(true);
        }

        if ((isMFAEnabledInPool && isUserMFAEnabled && user.challengeName !== "NEW_PASSWORD_REQUIRED" && (user.challengeName === "MFA_SETUP" || user.preferredMFA === "NOMFA" || user.preferredMFA === "SOFTWARE_TOKEN_MFA" || user.challengeName === "SOFTWARE_TOKEN_MFA")) || isForceResetTwoFactor) {
          if (user.preferredMFA === "NOMFA" || isForceResetTwoFactor) {
            const totpSecret = await setupTOTP(user);
            generateQRCode(email, String(totpSecret));
            setTotpSecret(String(totpSecret));
            setIsTwoFactorSetupRequired(true);
          }
          setIsTwoFactorStep(true);
          setIsLoading(false);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setIsLoading(false);
          setIsFirstLogin(true);
        } else {
          await signInAndUpdate();
        }
      } catch (error: any) {
        console.error("Error signing in", error);
        if (String(error).includes("disabled")) {
          setError("Your account has been disabled, please contact the administrator for assistance");
        } else if (String(error).includes("NotAuthorizedException")) {
          setError("Incorrect username or password, please try again");
        } else {
          setError(error.message);
        }
        setIsLoading(false);
      }
    }
  };

  const handleNewPasswordSubmit = async () => {
    setError(null);
    setShowPrepareError(false);
    setShowAutoSignedOutMessage(false);
    if (isNewPasswordValid) {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
        await Auth.completeNewPassword(user, newPassword);
        await setupTOTP(user);
        await signInAndUpdate(true);
      } catch (error: any) {
        console.error("Error setting new password", error);
        setError(error.message);
        setShowPrepareError(true);
        setIsLoading(false);
      }
    }
  };

  const handleOTPSubmit = async () => {
    setIsLoading(true);
    setShowVerifyOTPError(false);
    setShowVerifyOTPErrorByUseOnce(false);
    const otpToVerify = otp.join("");
    if (isTwoFactorSetupRequired) {
      verifyNewMFA(otpToVerify);
    } else {
      verifyMFA(otpToVerify);
    }
  };

  const verifyNewMFA = async (otp: string) => {
    try {
      await Auth.verifyTotpToken(signedInUser, otp);
      await signInAndUpdate(false, true);
    } catch (error: any) {
      setIsLoading(false);
      if (String(error.message).includes('once')) {
        setShowVerifyOTPErrorByUseOnce(true);
      } else if (String(error.message).includes('expired')) {
        setShowExpiredMessage(true);
      } else {
        setShowVerifyOTPError(true);
      }
      console.error('Error verifying TOTP:', error);
    }
  };

  const verifyMFA = async (otp: string) => {
    try {
      await Auth.confirmSignIn(signedInUser, otp, 'SOFTWARE_TOKEN_MFA');
      await signInAndUpdate();
    } catch (error: any) {
      setIsLoading(false);
      if (String(error.message).includes('once')) {
        setShowVerifyOTPErrorByUseOnce(true);
      } else if (String(error.message).includes('expired')) {
        setShowExpiredMessage(true);
      } else {
        setShowVerifyOTPError(true);
      }
      console.error('Error during OTP verification:', error);
    }
  };

  const fetchSubaccountById = async (id: string, token: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subaccount/get-subaccount-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return response.data.subaccounts.payload;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const navigateToForgotPassword = async () => {
    navigate(`/auth/forgot-password`);
  }

  const setupTOTP = async (user: any) => {
    try {
      const totpSecret = await Auth.setupTOTP(user);
      return totpSecret;
    } catch (error) {
      console.error('Error setting up TOTP:', error);
    }
  };

  const generateQRCode = async (email: string, secret: string) => {
    const appName = process.env.REACT_APP_NAME!;
    const otpAuthUrl = `otpauth://totp/${encodeURIComponent(appName)}:${encodeURIComponent(email)}?secret=${secret}&issuer=${encodeURIComponent(appName)}`;
    setOtpAuthUrl(otpAuthUrl);
  }

  const handleClickCopy = async (totpSecret: string) => {
    try {
      await navigator.clipboard.writeText(totpSecret);
    } catch (err) {
      console.error('Failed to copy totp secret: ', err);
    }
  };

  const handleOTPInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setShowVerifyOTPError(false);
    setShowVerifyOTPErrorByUseOnce(false);
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input field if the current one has a value and is not the last one
      if (value && index < 5) {
        otpInputRefs.current[index + 1].focus();
      } else if (index === 5) {
        // Blur the input to remove the cursor when the 6th digit is entered
        otpInputRefs.current[index].blur();
      }
    }
  };

  const handleOTPInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    setShowVerifyOTPError(false);
    setShowVerifyOTPErrorByUseOnce(false);
    if (e.key === 'Backspace') {
      if (otp[index] === '' && index > 0) {
        // Move focus to the previous input field if the current one is empty
        otpInputRefs.current[index - 1].focus();
      } else {
        // Clear the current input and keep the focus
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const handleOTPInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    setShowVerifyOTPError(false);
    setShowVerifyOTPErrorByUseOnce(false);
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    const newOtp = pasteData.split('').map((char, i) => (i < 6 ? char : otp[i]));
    setOtp(newOtp);

    // Focus the next input field after paste, but blur the last one
    pasteData.split('').forEach((_, i) => {
      if (i < 5 && pasteData[i]) {
        otpInputRefs.current[i + 1].focus();
      } else if (i === 5) {
        otpInputRefs.current[i].blur();
      }
    });
  };

  const handleShowSecret = () => {
    setShowSecret(true);
  };

  const onInputChange = (input: string, value: any) => {
    setError("");
    setShowAutoSignedOutMessage(false);
    if (input === "email") {
      setEmail(value)
    } else if (input === "password") {
      setPassword(value)
    }
  };

  const resetToSignInAgain = async () => {
    localStorage.clear();

    const authStatus = await checkAuthStatus();
    if (authStatus) {
      localStorage.clear();

      sessionStorage.removeItem("attemptedRefresh");

      Auth.signOut();
    }

    setOtp(Array(6).fill(""));
    setShowVerifyOTPErrorByUseOnce(false);
    setShowVerifyOTPError(false);
    setShowPrepareError(false);
    setShowExpiredMessage(false);
    setIsTwoFactorStep(false);
  };

  return Logo ? (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
        <>
          <div className="mb-12 flex items-center justify-center">
            <img src={Logo} alt="Payswiftly" width="229px" />
          </div>
          {!isTwoFactorStep &&
            <Card
              className="bg-white p-8 sm:rounded-md  shadow-cs"
            >
              <h4 className="text-2xl font-bold mb-2 text-navy-700">
                Welcome
              </h4>
              <p className="mb-8 text-base text-gray-600">
                Enter your login credentials to continue
              </p>

              <InputField
                disabled={isLoading}
                variant="auth"
                extra="mb-6"
                label="Email"
                placeholder=""
                id="email"
                type="text"
                autoComplete="off"
                value={email}
                readonly={isFirstLogin}
                onChange={(e) => onInputChange("email", e.target.value)}
              />

              <InputField
                disabled={isLoading}
                variant="auth"
                extra="mb-3"
                label="Password"
                placeholder=""
                id="password"
                type="password"
                value={password}
                readonly={isFirstLogin}
                onChange={(e) => onInputChange("password", e.target.value)}
              />

              {isFirstLogin && (
                <InputField
                  disabled={isLoading}
                  variant="auth"
                  extra="mb-3"
                  label="New Password"
                  placeholder="New password"
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={newPasswordError}
                />
              )}

              {error && (
                <p className="flex justify-center text-sm text-red-500">{error}</p>
              )}

              {showAutoSignedOutMessage &&
                <p className="flex justify-center text-sm text-red-500 my-2">
                  You have been automatically signed out due to inactivity
                </p>
              }

              {isFirstLogin ? (
                <Button
                  onClick={handleNewPasswordSubmit}
                  isDisabled={!isNewPasswordValid || isLoading}
                  isLoading={isLoading}
                  loadingText="Please wait..."
                  className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
                >
                  Set your new password
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handleSubmit}
                    isDisabled={!isFormValid || isLoading}
                    isLoading={isLoading}
                    loadingText="Please wait..."
                    className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
                  >
                    Sign In
                  </Button>
                  <div className="mt-3 flex justify-center items-center">
                    <a
                      onClick={isLoading ? undefined : () => navigateToForgotPassword()}
                      className={`text-sm text-navy-700 text-center ${isLoading ? "cursor-not-allowed opacity-50" : "hover:underline hover:cursor-pointer"
                        }`}
                    >
                      Forgot your password?
                    </a>
                  </div>
                </>
              )}
            </Card>
          }
          {isTwoFactorStep &&
            <Card
              className="bg-white p-8 sm:rounded-md shadow-cs text-center"
            >
              <h4 className="text-2xl font-bold mb-4 text-navy-700">
                Two-Factor Authentication
              </h4>
              {isTwoFactorSetupRequired && !showExpiredMessage &&
                <>
                  <>
                    <p className="mb-1 text-sm">
                      You must set up an authentication app like <strong>Authy</strong> or <strong>Google Authenticator</strong> before continuing
                    </p>
                    <div className="flex justify-center items-center rounded-xl p-1">
                      <QRCode
                        value={otpAuthUrl}
                        size={120}
                        className="p-1 bg-white rounded-md shadow-md"
                      />
                    </div>
                    <div className="text-sm mt-2">
                      <div className="mb-4">
                        You can manually enter the secret key into your authenticator app using <strong>Time-based (TOTP)</strong>
                      </div>
                      {!showSecret ? (
                        <span
                          className="cursor-pointer text-green-500 text-sm"
                          onClick={handleShowSecret}
                        >
                          Click to show the secret
                        </span>
                      ) : (
                        <>
                          <div className="flex items-center space-x-2 w-full">
                            <input
                              className="h-8 w-full text-xs outline-none rounded-md"
                              value={totpSecret}
                            />
                            <Tooltip title="Copy TOTP Secret" arrow placement="top">
                              <IconButton
                                id={"button-copy-totpSecret"}
                                onClick={() => handleClickCopy(totpSecret)}
                              >
                                <MdOutlineContentCopy className="h-5 w-5 text-navy-800" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </>
              }

              {!showExpiredMessage &&
                <>
                  <p className="mb-2 mt-3 text-sm">
                    {isTwoFactorSetupRequired ? <>Please wait up to 30 seconds for a new one-time password to be generated, then enter the code below</> : <>Enter the code from your authentication app</>}
                  </p>
                  <div className="flex text-center justify-center items-center w-full mt-2 mb-4 space-x-2">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => {
                          if (el) otpInputRefs.current[index] = el;
                        }}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleOTPInputChange(e, index)}
                        onKeyDown={(e) => handleOTPInputKeyDown(e, index)}
                        onPaste={handleOTPInputPaste}
                        className="w-10 h-12 text-center border border-gray-300 shadow-sm outline-none rounded-md"
                      />
                    ))}
                  </div>
                </>
              }

              {showVerifyOTPError &&
                <p className="flex justify-center text-sm text-red-500 my-2">
                  Invalid Code, please try again
                </p>
              }

              {showVerifyOTPErrorByUseOnce &&
                <p className="flex justify-center text-sm text-red-500 my-2">
                  Invalid Code, it has already been used
                </p>
              }

              {showPrepareError &&
                <p className="flex justify-center text-sm text-red-500 my-2">
                  Something went wrong, please try again later
                </p>
              }

              {showExpiredMessage &&
                <p className="flex justify-center text-sm text-red-500 my-2">
                  Your session has expired due to inactivity
                  <br />
                  Please sign in again
                </p>
              }

              {!showExpiredMessage &&
                <Button
                  onClick={handleOTPSubmit}
                  isDisabled={!isOTPFormValid || isLoading}
                  isLoading={isLoading}
                  loadingText="Please wait..."
                  className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
                >
                  Verify
                </Button>
              }

              {showExpiredMessage &&
                <div className="mt-3 flex justify-center items-center">
                  <a onClick={() => resetToSignInAgain()} className="text-sm text-navy-700 hover:underline hover:cursor-pointer text-center">Sign in again</a>
                </div>
              }
            </Card>
          }
        </>
      </div>
    </div>
  ) : null;
}
