import React from "react";

interface DisplayAmountProps {
  amount: number;
  currency: string;
}

const currencySymbols: Record<string, string> = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  AUD: "$",
  CAD: "$",
  CNY: "¥",
  INR: "₹",
};

const DisplayAmount: React.FC<DisplayAmountProps> = ({ amount, currency = "USD" }) => {
  const formatAmount = (value: number) => {
    const symbol = currencySymbols[currency] || "$";
    return `${symbol}${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  return <span>{formatAmount(amount)}</span>;
};

export default DisplayAmount;
