/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import { Button } from '@chakra-ui/react';
import InputField from "components/fields/InputField";

import { useRouter } from "routes/hooks/useRouter";
import { Auth } from "aws-amplify";

const initialData: {
    current_password: string;
    new_password: string;
    confirm_new_password: string;
} = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
};

const ChangePasswordTab: React.FC<{
    setSwalProps: any;
    isFetching: boolean;
}> = ({ setSwalProps, isFetching }) => {

    const [resetPasswordData, setResetPasswordData] = useState<any>(initialData);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [currentPasswordIncorrect, setCurrentPasswordIncorrect] = useState<boolean>(false);
    const userid = localStorage.getItem("userid");
    const userRole = localStorage.getItem("userRole");
    const username = localStorage.getItem("username");

    const router = useRouter();

    const handleInputChange = async (event: any) => {
        setCurrentPasswordIncorrect(false);
        const { id, value } = event.target;
        setResetPasswordData({ ...resetPasswordData, [id]: value });
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);
            const userInfo = await Auth.currentAuthenticatedUser();
            await Auth.signIn(userInfo.attributes.email, resetPasswordData.current_password);

            try {
                const data = {
                    userid: userid,
                    new_password: resetPasswordData.new_password,
                    userRole: userRole,
                    username: username
                }

                await axios.post(process.env.REACT_APP_API_URL + `/api/user/change-password`, data)
                    .then(function (response) {
                        if (response && response.data?.status === 'ok') {
                            setSwalProps({
                                show: true,
                                icon: "success",
                                title: "Success",
                                html: "Your password has been changed, please sign-in again for security reason",
                                showConfirmButton: true,
                                didClose: async () => {
                                    await Auth.signOut();
                                    localStorage.clear();
                                    router.push("/auth/sign-in");
                                },
                            });
                        } else {
                            setResetPasswordData(initialData);
                            setIsProcessing(false);
                        }
                    })
                    .catch(function (error) {
                        setIsProcessing(false);
                        setSwalProps({
                            show: true,
                            icon: "error",
                            title: "Oops!",
                            html: error.response.data.message,
                            showConfirmButton: true,
                            didClose: () => {
                                setResetPasswordData(initialData);
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    });
            } catch (error: any) {
                setResetPasswordData(initialData);
                setIsProcessing(false);
                setSwalProps({
                    show: true,
                    icon: "error",
                    title: "Oops!",
                    html: "Something went wrong, please try again later.",
                    showConfirmButton: true,
                    didClose: () => {
                        setSwalProps({});
                        setIsProcessing(false);
                    },
                });
            }
        } catch (error: any) {
            setResetPasswordData(initialData);
            setIsProcessing(false);
            setCurrentPasswordIncorrect(true);
        }
    };

    const validatePassword = (password: string): boolean => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
        return regex.test(password);
    };

    const isFormValid = resetPasswordData.current_password !== ""
        && resetPasswordData.new_password !== ""
        && resetPasswordData.confirm_new_password !== ""
        && resetPasswordData.new_password === resetPasswordData.confirm_new_password

    return (
        <>
            <div className="p-7">
                <div className="mb-[20px] text-sm">
                    <div className="mb-6">
                        For security reasons, you must enter your current password to proceed with changing your password.
                    </div>
                    <InputField
                        disabled={isProcessing}
                        variant="auth"
                        extra="mb-3 shadow-sm !w-[50%]"
                        label="Current Password *"
                        placeholder=""
                        id="current_password"
                        type="password"
                        autoComplete="off"
                        value={resetPasswordData.current_password}
                        onChange={handleInputChange}
                    />

                    <div className="flex justify-center"><hr className="mb-5 mt-3 w-full" /></div>

                    <InputField
                        disabled={isProcessing}
                        variant="auth"
                        extra="mb-3 shadow-sm !w-[50%]"
                        label="New Password *"
                        placeholder=""
                        id="new_password"
                        type="password"
                        autoComplete="off"
                        value={resetPasswordData.new_password}
                        onChange={handleInputChange}
                    />

                    <InputField
                        disabled={isProcessing}
                        variant="auth"
                        extra="mb-3 shadow-sm !w-[50%]"
                        label="Confirm New Password *"
                        placeholder=""
                        id="confirm_new_password"
                        type="password"
                        autoComplete="off"
                        value={resetPasswordData.confirm_new_password}
                        onChange={handleInputChange}
                    />

                    {(resetPasswordData.new_password !== resetPasswordData.confirm_new_password) && resetPasswordData.confirm_new_password !== "" && (
                        <p className="flex my-2 text-sm text-red-500">The new password and confirmation password must match</p>
                    )}

                    {currentPasswordIncorrect && (
                        <p className="flex my-2 text-sm text-red-500">Current password is incorrect, please try again</p>
                    )}

                    {!validatePassword(resetPasswordData.new_password) && resetPasswordData.new_password !== "" && (
                        <p className="flex my-2 text-sm text-red-500">The password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&).</p>
                    )}

                </div>
                <div className="flex gap-2 justify-start">
                    <Button
                        disabled={!isFormValid || isProcessing}
                        onClick={() => handleSubmit()}
                        isLoading={isProcessing}
                        loadingText='Processing...'
                        className="btn btn-primary">
                        Change Password
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordTab;
