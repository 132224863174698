/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, useContext } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { Button, Badge } from '@chakra-ui/react'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ProfileContext } from "../../../../store/ProfileProvider";

dayjs.extend(utc);
dayjs.extend(timezone);

type RowData = {
  id: string;
  name: string;
  support_email: string;
  is_active: string;
};

const MerchantList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
}> = ({ isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey }) => {
  const { profileTimeZone } = useContext(ProfileContext);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  interface MerchantStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: MerchantStatusItems[] = [
    { status: "yes", value: 'yes', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
    { status: "no", value: 'no', display: <Badge className={`${statusClasses} status-inactive`}>Inactive</Badge> },
  ];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.name,
    },
    {
      field: "support_email",
      headerName: "Support Email",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.support_email,
    },
    {
      field: "is_active", headerName: "API Status", sortable: false, minWidth: 140,
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const status = params.value as string;
        const statusItem = statusList.find(item => item.status === status);
        return (
          <>
            {statusItem ? statusItem.display : null}
          </>
        );
      }
    },
    {
      field: "actions", headerName: "", sortable: false, flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <Tooltip title="View More Details" arrow placement="right">
            <div className="mr-5">
              <IconButton
                id={"button-detail-" + params?.row?.id}
                onClick={() => handleClickDetail(params.row.id)}
              >
                <AiOutlineFileSearch className="h-5 w-5 text-navy-700" />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const handleClickDetail = (id: any) => {
    navigate(`/${userRole}/merchants/manage/${id}`);
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start text-md',
    row: ''
  };

  return (
    <div>
      <div className="flex flex-wrap mt-6 pb-4 pl-3">
        <h3 className="text-lg text-navy-700 font-bold dark:text-white">
          Merchant Management
        </h3>
      </div>
      <Box
        className="w-full card"
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>
    </div>
  );
};

export default MerchantList;
