/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { Badge, Button } from "@chakra-ui/react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses,
} from "@mui/x-data-grid";
import CreateUserModal from "./modal/CreateUserModal";
import { Auth } from "aws-amplify";
import moment from "moment";
import UserTableSearch from "./UserTableSearch";
import SweetAlert2 from "react-sweetalert2";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import UserUpdateStatusConfirmModal from "../components/modal/UserUpdateStatusConfirmModal";

type User = {
  user_id: string;
  username: string;
  email: string;
  role: string;
  status: string;
  merchant: any;
  subaccount: any;
  created_date: string;
  latest_login: string;
};

const UserTable: React.FC<{
  isLoading: boolean;
  tableData: any;
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
  setTableData: React.Dispatch<React.SetStateAction<any>>;
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentUsername: string;
  currentUserRole: any;
  handleSearch: any;
  handleClear: any;
  initialFilters: any;
  filters: any;
  setFilters: any;
  setIsLoading: any;
  fetchUsers: any;
}> = ({
  isLoading,
  tableData,
  setTableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel,
  setRowCount,
  currentUsername,
  currentUserRole,
  handleSearch,
  handleClear,
  initialFilters,
  filters,
  setFilters,
  setIsLoading,
  fetchUsers,
}) => {
    const [merchants, setMerchants] = useState([]);
    const [subaccounts, setSubaccounts] = useState([]);
    const [isSetSubaccounts, setIsSetSubaccounts] = useState(false);
    const [roleHierarchy, setRoleHierarchy] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [roleOptions, setRoleOptions] = useState<string[]>([]);
    const [newUser, setNewUser] = useState<any>({
      username: "",
      email: "",
      role: "",
    });

    const navigate = useNavigate();
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    const sysUsername = localStorage.getItem("username")?.toLowerCase();
    const subaccountId = localStorage.getItem("subaccountId")?.toLowerCase();

    const [isOpenUpdateStatusConfirmModal, setisOpenUpdateStatusConfirmModal] = useState<boolean>(false);
    const [statusToUpdate, setStatusToUpdate] = useState<string>("");
    const [userDetailToUpdate, setUserDetailToUpdate] = useState(null);

    const resetNewUser = () => {
      setNewUser({
        username: "",
        email: "",
        role: "",
        merchant: "",
        subaccount: "",
      });
    };

    useEffect(() => {
      const fetchMerchants = async () => {
        const fetchedMerchants = await getMerchantOptions();
        setMerchants(fetchedMerchants);
      };

      fetchMerchants();
    }, []);

    useEffect(() => {
      const fetchRoleOptions = async () => {
        const fetchedRoleOptions = await getRoleOptions();
        setRoleOptions(fetchedRoleOptions);
      };

      fetchRoleOptions();
    }, []);

    const getRoleOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/role/get-all-hierarchy-levels`
        );
        const roles = response.data;

        const currentRole = roles.find(
          (role: any) => role.name === currentUserRole
        );

        if (!currentRole) {
          return ["-- Please Select --"];
        }

        const roleOptionsArray = ["-- Please Select --"];

        roles.forEach((role: any) => {
          if (role.hierarchy_level !== 4) {
            roleOptionsArray.push(role.name);
          }
        });

        return roleOptionsArray;
      } catch (error) {
        console.error("Error fetching role options:", error);
        return [];
      }
    };

    const getMerchantOptions = async () => {
      try {
        if (currentUserRole === "SuperAdmin") {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/merchant/get-all-merchants`
          );
          if (response.data.merchants && response.data.merchants.payload) {
            return [
              { id: "", name: "-- Please Select --" },
              ...response.data.merchants.payload,
            ];
          } else {
            console.error("Invalid response format for get-all-merchants");
            return [{ id: "", name: "-- Please Select --" }];
          }
        } else {
          const session = await Auth.currentSession();
          const idToken = session.getIdToken();
          const cognitoId = idToken.payload.sub;

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/merchant/get-merchants-for-user?cognitoId=${cognitoId}`
          );

          if (response.data.merchants && response.data.merchants.payload) {
            return [
              { id: "", name: "-- Please Select --" },
              ...response.data.merchants.payload,
            ];
          } else {
            console.error("Invalid response format for get-merchants-for-user");
            return [{ id: "", name: "-- Please Select --" }];
          }
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    const getSubaccountOptions = async (merchantId: any) => {
      try {
        if (currentUserRole === "SuperAdmin") {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantId}`
          );
          return [
            { id: "", suba_name: "-- Please Select --" },
            ...response.data.subaccounts.payload,
          ];
        } else {
          const session = await Auth.currentSession();
          const idToken = session.getIdToken();
          const cognitoId = idToken.payload.sub;

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/subaccount/get-subaccounts-for-user?cognitoId=${cognitoId}&merchantId=${merchantId}`
          );
          return [
            { id: "", suba_name: "-- Please Select --" },
            ...response.data.subaccounts.payload,
          ];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    useEffect(() => {
      const fetchRoleHierarchy = async () => {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/role/get-all-hierarchy-levels"
        );
        setRoleHierarchy(response.data);
      };

      fetchRoleHierarchy();
    }, []);

    const [selectedUser, setSelectedUser] = useState<any>(null);

    const handleStatusChange = (email: string, username: string, currentStatus: string) => {
      setSelectedUser({ email, username, status: currentStatus });
      setIsStatusModalOpen(true);
    };

    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirmStatusChange = async (
      email: string,
      username: string,
      newStatus: string
    ) => {
      setIsConfirming(true);
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/user/update-user-status`,
          {
            email,
            username,
            status: newStatus,
            userRole: userRole,
            sysUsername: sysUsername,
            subaccountId: subaccountId
          }
        );

        setSwalProps({
          show: true,
          icon: "success",
          title: "Success",
          html: "User status successfully changed!",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsConfirming(false);
            setTableData((prevData: any) =>
              prevData.map((user: any) =>
                user.email === email ? { ...user, status: newStatus } : user
              )
            );
          },
        });
      } catch (error) {
        console.error("Failed to update user status", error);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Failed to change user status. Please try again.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
          },
        });
      }
    };

    const handleInputChange = async (event: any) => {
      const { name, value } = event.target;
      setNewUser({ ...newUser, [name]: value });

      if (name === "merchant") {
        setIsSetSubaccounts(true);
        const fetchedSubaccounts = await getSubaccountOptions(value);
        setSubaccounts(fetchedSubaccounts);
        setIsSetSubaccounts(false);
      }
    };

    const [editingUser, setEditingUser] = useState(null);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "auto" });
    };

    useEffect(() => {
      const storedSelectedUser = localStorage.getItem("selectedUser");
      if (storedSelectedUser) {
        setSelectedUser(JSON.parse(storedSelectedUser));
        setEditingUser(true);
      }
    }, []);

    const handleRoleEditClick = (
      username: string,
      email: string,
      role: string,
      merchant: any,
      subaccount: any,
      created_date: string,
      latest_login: string
    ) => {
      const selectedUser = {
        username,
        email,
        role,
        merchant,
        subaccount,
        created_date,
        latest_login,
      };
      setSelectedUser(selectedUser);
      // localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
      // localStorage.setItem("selectedTabIndex", "0");
      setEditingUser(true);
      scrollToTop();
    };

    const handleGoBack = () => {
      setEditingUser(false);
      localStorage.removeItem("selectedUser");
      localStorage.removeItem("selectedTabIndex");
    };

    const canEdit = (
      currentUserRole: string,
      targetUserRole: string
    ): boolean => {
      const currentUserRoleLevel = roleHierarchy.find(
        (role) => role.name === currentUserRole
      )?.hierarchy_level;
      const targetUserRoleLevel = roleHierarchy.find(
        (role) => role.name === targetUserRole
      )?.hierarchy_level;

      return currentUserRoleLevel > targetUserRoleLevel;
    };

    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [swalProps, setSwalProps] = useState<any>({});

    const handleSubmit = async (e: any) => {
      const { username, email, role, merchant, subaccount } = newUser;

      const usernameRegex = /^[a-z]+$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if(!usernameRegex.test(username)) {
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Username must contain only letters (a-z) with no spaces or special characters.",
          showConfirmButton: true,
          timer: 2000,
          didClose: () => {
            setSwalProps({});
            setIsSubmitting(false);
          },
        });
        return;
      }

      if(!emailRegex.test(email)) {
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Please enter a valid email address (e.g., user@example.com).",
          showConfirmButton: true,
          timer: 2000,
          didClose: () => {
            setSwalProps({});
            setIsSubmitting(false);
          },
        });
        return;
      }

      const userRole = localStorage.getItem("userRole")?.toLowerCase();
      const sysUsername = localStorage.getItem("username")?.toLowerCase();
      
      setIsSubmitting(true);
      try {
        await axios.post(process.env.REACT_APP_API_URL + "/api/user/register", {
          username,
          email,
          role,
          merchantId: merchant,
          subaccountId: subaccount,
          userRole,
          sysUsername,
        });

        setSwalProps({
          show: true,
          icon: "success",
          title: "Success",
          html: "User successfully created!",
          showConfirmButton: false,
          timer: 2000,
          didClose: () => {
            handleClear();
            setSwalProps({});
            setIsSubmitting(false);
          },
        });
      } catch (error) {
        console.error("Error during user registration: ", error);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Failed to register. Please try again.",
          showConfirmButton: true,
          timer: 2000,
          didClose: () => {
            setSwalProps({});
            setIsSubmitting(false);
          },
        });
      }

      setNewUser({
        username: "",
        email: "",
        role: "",
      });

      setShowForm(false);
    };

    interface UserStatusItems {
      status: string;
      value: string;
      display: ReactElement;
    }

    const statusClasses = 'status';

    const statusList: UserStatusItems[] = [
      { status: "Inactive", value: 'inactive', display: <Badge className={`${statusClasses} status-inactive`}>Inactive</Badge> },
      { status: "Active", value: 'active', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
    ];

    const formatDate = (dateString: string) => {
      return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY - hh:mm A");
    };

    const columns: GridColDef[] = [
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams<User, string>) => {
          const isCurrentUser = params.row.username === currentUsername;
          return (
            <span className={isCurrentUser ? "font-bold" : ""}>
              {params.value}
            </span>
          );
        },
      },
      {
        field: "username",
        headerName: "Username",
        flex: 1,
        minWidth: 220,
        sortable: false,
        renderCell: (params: GridRenderCellParams<User, string>) => {
          const isCurrentUser = params.row.username === currentUsername;
          return (
            <span className={isCurrentUser ? "font-bold" : ""}>
              {params.value}
            </span>
          );
        },
      },
      {
        field: "latest_login",
        headerName: "Latest Login",
        flex: 1,
        minWidth: 220,
        sortable: false,
        valueFormatter: (params) => {
          const dateString = params.value as string;
          if (!dateString) return "-";
          return formatDate(dateString);
        },
      },
      {
        field: "status",
        headerName: "",
        flex: 1,
        minWidth: 140,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<User, string>) => {
          const status = params.value as string;
          const statusItem = statusList.find((item) => item.status === status);
          return statusItem ? statusItem.display : null;
        },
      },
      {
        field: "actions",
        headerName: " ",
        flex: 1,
        minWidth: 220,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<User, string>) => (
          <div className="flex items-center">
            {/* {canEdit(currentUserRole, params.row.role) && ( */}
              <>
                <Tooltip title="View More Details" arrow placement="top">
                  <IconButton
                    disabled={isLoading}
                    id={"button-detail-" + params?.row?.user_id}
                    onClick={() => navigate(`/${userRole}/user-management/${params.row.user_id}`)}
                  >
                    <AiOutlineFileSearch className="h-5 w-5 text-navy-700" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={params.row.status === "Active" ? "Deactivate" : "Activate"} arrow placement="top">
                  <IconButton
                    disabled={isLoading}
                    id={"button-change-status-" + params?.row?.user_id}
                    onClick={() => handleUpdateStatus(params.row.status === "Active" ? "inactive" : "active", params?.row?.user_id)}
                  >
                    {params.row.status === "Active" ? <MdOutlineDoNotDisturbAlt className="h-5 w-5 text-red-500" /> : <AiOutlineCheckCircle className="h-5 w-5 text-green-500" />}
                  </IconButton>
                </Tooltip>
              </>
            {/* )} */}
          </div>
        ),
      },
    ];

    const customDataGridStyles: Partial<GridClasses> = {
      root: 'mui-table-custom',
      columnHeader: 'text-start text-md',
      row: ''
    };

    const handleUpdateStatus = (statusToUpdate: string, user_id: any) => {
      const user = tableData.filter((x: any) => x.user_id === user_id)[0];
      setUserDetailToUpdate(user);
      setStatusToUpdate(statusToUpdate);
      showUpdateStatusConfirmModal();
    };

    const showUpdateStatusConfirmModal = () => {
      setisOpenUpdateStatusConfirmModal(true);
    }

    const closeUpdateStatusConfirmModal = () => {
      setisOpenUpdateStatusConfirmModal(false);
    }

    const triggerReloadList = async () => {
      setUserDetailToUpdate(null);
      setStatusToUpdate("");
      setIsLoading(true);
      await fetchUsers(true);
    }

    return (
      <div>

        {userDetailToUpdate &&
          <UserUpdateStatusConfirmModal setSwalProps={setSwalProps} triggerReload={triggerReloadList} isOpen={isOpenUpdateStatusConfirmModal} onClose={() => closeUpdateStatusConfirmModal()} userToUpdate={userDetailToUpdate} statusToUpdate={statusToUpdate} />
        }
        <SweetAlert2 {...swalProps} />

        <div className="mt-3"></div>
        <>
          <div className="flex justify-start">
            <Button
              onClick={() => setShowForm(true)}
              className="btn btn-action"
            >
              <FaPlus />
              &nbsp;Create User
            </Button>
          </div>

          <UserTableSearch
            initialFilters={initialFilters}
            filters={filters}
            setFilters={setFilters}
            onSearch={handleSearch}
            onClear={handleClear}
            isLoading={isLoading}
            isConfirming={isConfirming}
          />

          <div className="flex flex-wrap mt-6 pb-4 pl-3">
            <h3 className="text-lg text-navy-700 font-bold dark:text-white">
              Users
            </h3>
          </div>
          <Box
            className="w-full card"
          >
            <DataGrid
              sx={{
                '& .MuiDataGrid-cell': {
                  padding: '8px 16px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '8px 16px',
                },
                "& .MuiTablePagination-input": {
                  marginRight: "-20px"
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none"
                },
              }}
              loading={isLoading}
              rows={tableData || []}
              columns={columns}
              getRowId={(row) => (row ? row.email : undefined)}
              rowCount={rowCount}
              sortingMode="server"
              paginationMode="server"
              pageSizeOptions={[10, 25, 50, 100]}
              rowHeight={48}
              columnHeaderHeight={48}
              paginationModel={{ page: page, pageSize: pageSize }}
              onPaginationModelChange={(newValue) =>
                setPaginationModel(newValue)
              }
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              classes={customDataGridStyles}
              getCellClassName={(params) => {
                if (params.row.username === currentUsername) {
                  return "text-green-500";
                }
                return "";
              }}
              autoHeight
            />
          </Box>

          <CreateUserModal
            showForm={showForm}
            setShowForm={setShowForm}
            handleSubmit={handleSubmit}
            newUser={newUser}
            handleInputChange={handleInputChange}
            roleOptions={roleOptions}
            merchants={merchants}
            subaccounts={subaccounts}
            resetNewUser={resetNewUser}
            isSubmitting={isSubmitting}
            isSetSubaccounts={isSetSubaccounts}
            setSubaccounts={setSubaccounts}
          />
        </>
      </div>
    );
  };

export default UserTable;
