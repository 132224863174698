/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import { Button } from '@chakra-ui/react';

const GeneralTab: React.FC<{
    data: any;
    setSubaccountDetails: any;
    setSwalProps: any;
    isOtherTabUpdating: boolean;
    setIsUpdating: any;
}> = ({ data, setSubaccountDetails, setSwalProps, isOtherTabUpdating, setIsUpdating }) => {

    const [isLocalUpdating, setIsLocalUpdating] = useState<boolean>(false);
    const [isInvalidURL, setIsInvalidURL] = useState<boolean>(false);

    const isURL = (str: string): boolean => {
        const pattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?(\?.*)?(#.*)?$/i;
        return pattern.test(str);
    };

    const handleInputChange = async (event: any) => {
        setIsInvalidURL(false);
        const { id, value } = event.target;
        setSubaccountDetails({ ...data, [id]: value });
        if (id === "postback_url") {
            if (!isURL(value)) {
                setIsInvalidURL(true);
            }
        }
    };

    const isFormValid = data !== null ? (
        data.postback_url
    ) : false;

    const handleSubmit = async () => {
        if (isFormValid) {

            const postData = {
                ...data,
                fn: "update-settings",
                username: localStorage.getItem("username"),
                userRole: localStorage.getItem("userRole")
            };

            try {
                setIsLocalUpdating(true);
                setIsUpdating(true);
                await axios.post(process.env.REACT_APP_API_URL + `/api/subaccount/action`,
                    postData
                ).then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: `Settings has been updated`,
                            showConfirmButton: false,
                            timer: 2000,
                            didClose: () => {
                                setIsLocalUpdating(false);
                                setIsUpdating(false);
                                setSwalProps({});
                            },
                        });
                    } else {
                        setIsLocalUpdating(false);
                        setIsUpdating(false);
                        setSwalProps({
                            show: true,
                            icon: "error",
                            title: "Oops!",
                            html: response.data.message,
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsLocalUpdating(false);
                                setIsUpdating(false);
                            },
                        });
                    }
                }).catch(function (error) {
                    setIsLocalUpdating(false);
                    setIsUpdating(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsLocalUpdating(false);
                            setIsUpdating(false);
                        },
                    });
                });
            } catch (error) {
                setIsLocalUpdating(false);
                setIsUpdating(false);
                setSwalProps({
                    show: true,
                    icon: "error",
                    title: "Oops!",
                    html: "Something went wrong, please try again later.",
                    showConfirmButton: true,
                    didClose: () => {
                        setSwalProps({});
                        setIsLocalUpdating(false);
                        setIsUpdating(false);
                    },
                });
            }
        }
    };

    return (
        <>
            <div className="p-7">
                {data !== null ? (
                    <>
                        <div className="flex flex-wrap justify-between text-sm text-navy-700 dark:text-white">
                            <div className="w-1/3">
                                <>
                                    <InputField
                                        disabled={isLocalUpdating || isOtherTabUpdating}
                                        variant="auth"
                                        extra="mb-3 shadow-sm number-input"
                                        label="Postback URL *"
                                        placeholder=""
                                        id="postback_url"
                                        type="text"
                                        autoComplete="off"
                                        value={data.postback_url}
                                        onChange={handleInputChange}
                                        error={isInvalidURL ? "Invalid URL format. Please enter a valid url, e.g. https://example.com" : undefined}
                                    />

                                    <div className="flex flex-wrap justify-start">
                                        <div className="mt-4">
                                            <Button
                                                onClick={() => handleSubmit()}
                                                isDisabled={!isFormValid || isLocalUpdating || isOtherTabUpdating || isInvalidURL}
                                                isLoading={isLocalUpdating}
                                                loadingText='Processing...'
                                                className="rounded-md bg-green-600 px-3 py-2.5 text-sm font-medium text-white transition duration-200 hover:bg-green-700 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
                        <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
                    </>
                )}
            </div>
        </>
    );
};

export default GeneralTab;
