/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import SweetAlert2 from "react-sweetalert2";
import { Button } from '@chakra-ui/react';
import DisplayAmount from "../shared/DisplayAmount";

const PayoutCancelConfirmModal: React.FC<{
    payoutToCancel: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
}> = ({ isOpen, onClose, triggerReload, payoutToCancel }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [swalProps, setSwalProps] = useState<any>({});
    const userRole = localStorage.getItem("userRole");
    const subaccount_id = localStorage.getItem("subaccountId");

    const closeModal = async () => {
        onClose();
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const data = {
                username: localStorage.getItem("username"),
                userRole: userRole,
                subaccount_id: payoutToCancel.subaccount.id
            }

            await axios.put(process.env.REACT_APP_API_URL + `/api/payout/cancel/${payoutToCancel.id}`, data)
                .then(function (response) {
                    triggerReload();
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Payout has been cancel",
                            showConfirmButton: true,
                            didClose: () => {                                
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const fieldsRendering = (fields: Record<string, any>) => {
        const renderInput = (fieldName: string, fieldValue: any) => {
            const capitalizeLabel = (label: string) => {
                let modifiedLabel = label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiedLabel = "BSB";
                        break;
                    case "bic":
                        modifiedLabel = "BIC";
                        break;
                    case "iban":
                        modifiedLabel = "IBAN";
                        break;
                }

                return modifiedLabel;
            };

            return (
                <div className="mb-1" key={fieldName}>
                    <span className="mr-1 font-bold">{capitalizeLabel(fieldName)}:</span> {fieldValue}
                </div>
            );
        };

        return Object.entries(fields).map(([key, value]) => renderInput(key, value));
    };

    return (
        <>
            { payoutToCancel &&
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to cancel the payout?</h1>
                            <div className="mb-[20px] text-sm">
                                <div className="mb-8">
                                    Please confirm if you wish to cancel the current payout. Cancelling this payout will discard all the details, and the transaction will not be processed.
                                </div>                                
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Email:</span> {payoutToCancel.receiver.email}
                                </div>
                                {fieldsRendering(payoutToCancel.receiver_account.method_details)}
                                <div className="mb-1 mt-3">
                                    <span className="mr-1 font-bold">Amount:</span> <DisplayAmount amount={Number(payoutToCancel.amount)} currency={payoutToCancel.asset} /> {payoutToCancel.asset}
                                </div>
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Note:</span> {!payoutToCancel.note || payoutToCancel.note === "" ? "-" : payoutToCancel.note}
                                </div>
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Reference:</span> {!payoutToCancel.reference || payoutToCancel.reference === "" ? "-" : payoutToCancel.reference}
                                </div>
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    isDisabled={isProcessing}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    isLoading={isProcessing}
                                    loadingText='Processing...'
                                    className="btn btn-primary">
                                    Confirm
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal> }

            <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

            </SweetAlert2>
        </>
    );
};

export default PayoutCancelConfirmModal;
