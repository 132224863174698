// Custom components
import React from "react";

function DynamicInputField(props: {
  id: string;
  label: string;
  extra: string;
  placeholder?: string;
  variant: string;
  state?: string;
  name?: string;
  disabled?: boolean;
  readonly?: boolean;
  type?: string;
  autoComplete?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  helptext?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  labelAsLink?: string;
  maxLength?: number;
  minLength?: number;
  required: boolean;
}) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    name,
    disabled,
    readonly,
    autoComplete,
    value,
    onChange,
    error,
    helptext,
    onKeyDown,
    labelAsLink,
    maxLength,
    minLength,
    required
  } = props;

  return (
    <div>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 ${variant === "auth" ? "font-medium" : "font-bold"
          }`}
      >
        {(labelAsLink && labelAsLink !== "") ? (
          <a
            rel="noreferrer"
            href={labelAsLink}
            target="_blank"
            className="underline hover:text-green-500"
          >
            {label}
          </a>
        ) : (
          label
        )}
      </label>
      <input
        required={required}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readonly}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={`flex h-10 w-full text-primary items-center rounded-md mt-2 border border-gray-300 bg-white  p-4 shadow-sm outline-none ${extra} ${disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:bg-darkPrimary dark:!border-white/10"
          }`}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {helptext && <p className={"text-sm text-gray-500 " + (error ? '' : 'mb-3')}>{helptext}</p>}
      {error && <p className={"text-sm text-red-500 mb-3"}>{error}</p>}
    </div>
  );
}

export default DynamicInputField;
