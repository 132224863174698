import { Navigate } from "react-router-dom";

import MainDashboard from "views/admin/default";

import SignIn from "views/auth/SignIn";
import SubaccountSelection from "views/auth/SubaccountSelection";
import ForgotPassword from "views/auth/ForgotPassword";
import AccountConfirmation from "views/public/AccountConfirmation";
import NotFound from "views/public/NotFound";
import Error from "views/public/Error";
import ExpiredLink from "views/public/ExpiredLink";

import { MdLock } from "react-icons/md";

import {
  AiOutlineStock,
  AiOutlineUserSwitch,
  AiOutlineSetting
} from "react-icons/ai";
import {
  PiUsersThree,
  PiUserCircle,
  PiCurrencyCircleDollar,
  PiStack,
  PiCreditCard,
} from "react-icons/pi";

// import { MdOutlineCurrencyExchange } from "react-icons/md";

import { FaRegUser } from "react-icons/fa";

import { GrTransaction } from "react-icons/gr";
import { FiActivity } from "react-icons/fi";

import UserManagement from "views/admin/user";
import UserDetail from "views/admin/user/UserDetail";
import Payout from "views/admin/payout";
import Transaction from "views/admin/transaction";
// import Fee from "views/admin/fee";
import CreatePayout from "views/admin/payout/CreatePayout";
import MerchantSelection from "views/auth/MerchantSelection";
import Batch from "views/admin/batch";
import BatchDetail from "views/admin/batch/BatchDetail";
// import BatchFailedReport from "views/admin/batch/BatchFailedReport";
import Receiver from "views/admin/receiver";
import CreateReceiver from "views/admin/receiver/CreateReceiver";
import ReceiverDetail from "views/admin/receiver/ReceiverDetail";
import AccountManagement from "views/admin/account";
import AccountDetail from "views/admin/account/AccountDetail";
import ActivityLogs from "views/admin/activitylog";
import Profile from "views/admin/profile";
import MerchantManagement from "views/admin/merchant";
import MerchantDetail from "views/admin/merchant/MerchantDetail";
import Settings from "views/admin/settings";

const useRoutes = (userPermissions: any | null) => {

  const userRole = localStorage.getItem("userRole");
  const merchantId = localStorage.getItem("merchantId");
  const subaccountId = localStorage.getItem("subaccountId");

  const permissionKey = `${merchantId}-${subaccountId}`;

  const authRoutes = [
    {
      isSideBar: false,
      name: "Select Merchant",
      layout: "/auth",
      path: "merchant-selection",
      icon: <MdLock className="h-6 w-6" />,
      component: <MerchantSelection />,
    },
    {
      isSideBar: false,
      name: "Select Subaccount",
      layout: "/auth",
      path: "subaccount-selection",
      icon: <MdLock className="h-6 w-6" />,
      component: <SubaccountSelection />,
    },
    {
      isSideBar: false,
      name: "Sign In",
      layout: "/auth",
      path: "sign-in",
      icon: <MdLock className="h-6 w-6" />,
      component: <SignIn />,
    },
    {
      isSideBar: false,
      name: "Forgot Password",
      layout: "/auth",
      path: "forgot-password",
      icon: <></>,
      component: <ForgotPassword />,
    }
  ];

  const publicRoutes = [
    {
      isSideBar: false,
      name: "Payout Confirmation",
      layout: "/",
      path: "confirm/:token",
      icon: <></>,
      component: <AccountConfirmation />,
    },
    {
      isSideBar: false,
      name: "Not Found",
      layout: "/",
      path: "404",
      icon: <></>,
      component: <NotFound />,
    },
    {
      isSideBar: false,
      name: "Link Expired",
      layout: "/",
      path: "expired/:subaccountid",
      icon: <></>,
      component: <ExpiredLink />,
    },
    {
      isSideBar: false,
      name: "Internal Error",
      layout: "/",
      path: "500",
      icon: <></>,
      component: <Error />,
    }
  ];

  const getRoutesByPermission = () => {
    const routes = [];

    if (userRole === "SuperAdmin") {

      routes.push({
        isSideBar: true,
        name: "Dashboard",
        layout: `/${userRole?.toLowerCase() || ""}`,
        path: "dashboard",
        icon: <AiOutlineStock className="h-6 w-6" />,
        component: <MainDashboard />,
      });

      routes.push({
        isSideBar: true,
        name: "Payouts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiCurrencyCircleDollar className="h-6 w-6" />,
        path: "payouts",
        component: <Payout userPermissions={userPermissions} />,
      });

      routes.push({
        isSideBar: true,
        name: "Transactions",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <GrTransaction className="h-5 w-6" />,
        path: "transactions",
        component: <Transaction userPermissions={userPermissions} />,
      });

      // routes.push({
      //   isSideBar: true,
      //   name: "Fees",
      //   layout: `/${userRole?.toLowerCase() || ""}`,
      //   icon: <MdOutlineCurrencyExchange className="h-5 w-6" />,
      //   path: "fees",
      //   component: <Fee userPermissions={userPermissions} />,
      // });

      routes.push({
        isSideBar: true,
        name: "Batches",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiStack className="h-6 w-6" />,
        path: "batch",
        component: <Batch userPermissions={userPermissions} />,
      });

      routes.push({
        isSideBar: false,
        name: "Batch Detail",
        layout: `/superadmin`,
        icon: <></>,
        path: "batch/:id",
        component: <BatchDetail userPermissions={userPermissions} />,
      });

      routes.push({
        isSideBar: true,
        name: "Merchants",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiUserCircle className="h-6 w-6" />,
        path: "merchants",
        component: <Navigate to={`/${userRole?.toLowerCase() || ""}/merchants/manage`} replace />,
        hasSubMenu: true,
        subMenuItems: [
          {
            name: "Merchants",
            layout: `/${userRole?.toLowerCase() || ""}`,
            path: "merchants/manage",
          },
          {
            name: "Subaccounts",
            layout: `/${userRole?.toLowerCase() || ""}`,
            path: "merchants/subaccounts",
          },
        ]
      });

      routes.push({
        isSideBar: false,
        name: "Merchants",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "merchants/manage",
        component: <MerchantManagement />,
      });

      routes.push({
        isSideBar: false,
        name: "Merchants",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "merchants/manage/:merchant_id",
        component: <MerchantDetail />,
      });

      routes.push({
        isSideBar: false,
        name: "Subaccounts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "merchants/subaccounts",
        component: <AccountManagement />,
      });

      routes.push({
        isSideBar: false,
        name: "Subaccounts",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "merchants/subaccounts/:subaccount_id",
        component: <AccountDetail />,
      });

      routes.push({
        isSideBar: true,
        name: "Receivers",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <AiOutlineUserSwitch className="h-6 w-6" />,
        path: "receiver",
        component: <Receiver userPermissions={userPermissions} />,
      });

      routes.push({
        isSideBar: false,
        name: "Receivers",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "receiver/:id",
        component: <ReceiverDetail userPermissions={userPermissions} />,
      });

      routes.push({
        isSideBar: true,
        name: "Activity Logs",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <FiActivity className="h-6 w-6" />,
        path: "activity-logs",
        component: <ActivityLogs />,
      });

      routes.push({
        isSideBar: true,
        name: "User Management",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <PiUsersThree className="h-6 w-6" />,
        path: "user-management",
        component: <UserManagement />,
      });

      routes.push({
        isSideBar: false,
        name: "User Management",
        layout: `/${userRole?.toLowerCase() || ""}`,
        icon: <></>,
        path: "user-management/:user_id",
        component: <UserDetail />,
      });

      routes.push({
        isSideBar: false,
        name: "My Profile",
        layout: `/${userRole?.toLowerCase() || ""}`,
        path: "my-profile",
        icon: <FaRegUser className="h-6 w-6" />,
        component: <Profile />,
      });

    } else if (userPermissions && userPermissions[permissionKey]) {

      if (userPermissions[permissionKey].viewDashboard) {
        routes.push({
          isSideBar: true,
          name: "Dashboard",
          layout: `/${userRole?.toLowerCase() || ""}`,
          path: "dashboard",
          icon: <AiOutlineStock className="h-6 w-6" />,
          component: <MainDashboard/>,
        });
      }

      if (userPermissions[permissionKey].createPayout) {
        routes.push({
          isSideBar: true,
          name: "Create Payout",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiCreditCard className="h-6 w-6" />,
          path: "create-payout",
          component: <CreatePayout />,
        });
      }
      if (userPermissions[permissionKey].viewPayout) {
        routes.push({
          isSideBar: true,
          name: "Payouts",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiCurrencyCircleDollar className="h-6 w-6" />,
          path: "payouts",
          component: <Payout userPermissions={userPermissions[permissionKey]} />,
        });
      }
      
      if (userPermissions[permissionKey].viewTransaction) {
        routes.push({
          isSideBar: true,
          name: "Transactions",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <GrTransaction className="h-5 w-6" />,
          path: "transactions",
          component: <Transaction userPermissions={userPermissions[permissionKey]} />,
        });
      }

      if (userPermissions[permissionKey].viewBatch) {

        routes.push({
          isSideBar: true,
          name: "Batches",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiStack className="h-6 w-6" />,
          path: "batch",
          component: <Batch userPermissions={userPermissions[permissionKey]} />,
        });

        routes.push({
          isSideBar: false,
          name: "Batch Detail",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "batch/:id",
          component: <BatchDetail userPermissions={userPermissions[permissionKey]} />,
        });
      }

      if (userPermissions[permissionKey].viewReceiver) {
        routes.push({
          isSideBar: true,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <AiOutlineUserSwitch className="h-6 w-6" />,
          path: "receiver",
          component: <Receiver userPermissions={userPermissions[permissionKey]} />,
        });
        routes.push({
          isSideBar: false,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "receiver/create",
          component: <CreateReceiver />,
        });
        routes.push({
          isSideBar: false,
          name: "Receivers",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "receiver/:id",
          component: <ReceiverDetail userPermissions={userPermissions[permissionKey]} />,
        });
      }

      if (userPermissions[permissionKey].viewUserManagement) {
        routes.push({
          isSideBar: true,
          name: "User Management",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <PiUsersThree className="h-6 w-6" />,
          path: "user-management",
          component: <UserManagement />,
        });
        routes.push({
          isSideBar: false,
          name: "User Management",
          layout: `/${userRole?.toLowerCase() || ""}`,
          icon: <></>,
          path: "user-management/:user_id",
          component: <UserDetail />,
        });
      }

      if (userPermissions[permissionKey].viewSettings) {
        routes.push({
          isSideBar: true,
          name: "Settings",
          layout: `/${userRole?.toLowerCase() || ""}`,
          path: "settings",
          icon: <AiOutlineSetting className="h-6 w-6" />,
          component: <Settings />,
        });
      }

      routes.push({
        isSideBar: false,
        name: "My Profile",
        layout: `/${userRole?.toLowerCase() || ""}`,
        path: "my-profile",
        icon: <FaRegUser className="h-6 w-6" />,
        component: <Profile />,
      });
    }

    return routes;
  };

  const routes: any = {
    public: publicRoutes,
    auth: authRoutes,
    ...(userRole
      ? { [userRole.toLowerCase() || ""]: getRoutesByPermission() }
      : {}),
  };

  return { routes, getRoutesByPermission };
};

export default useRoutes;
