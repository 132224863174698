/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Select, Button, Badge, Divider, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { FaAngleLeft } from "react-icons/fa";

import SweetAlert2 from "react-sweetalert2";
import { BsPersonCheck } from "react-icons/bs";
import { Tooltip, IconButton } from "@mui/material";

import ReceiverDetailTab from "./components/ReceiverDetailTab";
import ReceiverAccountsTab from "./components/ReceiverAccountsTab";
import ReceiverPayoutHistoryTab from "./components/ReceiverPayoutHistoryTab";
import ReceiverResendKYCConfirmModal from "./components/ReceiverResendKYCConfirmModal";
import { useParams, useNavigate } from "react-router-dom";

const ReceiverDetail = ({ userPermissions }: { userPermissions: any }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenResendKYCConfirmModal, setIsOpenResendKYCConfirmModal] = useState<boolean>(false);
  const [receiverDetailForEdit, setReceiverDetailForEdit] = useState(null);
  const [currentTab, setCurrentTab] = useState<string>('details');
  const [countries, setCountries] = useState<any>(null);
  const [dialcodeList, setDialcodeList] = useState<any>(null);
  const [swalProps, setSwalProps] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      await fetchReceiverDetail(id);
    };

    fetchData();
  }, []);

  const fetchReceiverDetail = async (receiverId: string) => {
    try {

      setReceiverDetailForEdit(null);
      setIsLoading(true);

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/receiver/${receiverId}`
      );

      let receiverData = response.data.receiver?.payload;

      setReceiverDetailForEdit(receiverData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: CustomerStatusItems[] = [
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "completed", value: 'completed', display: <Badge className={`${statusClasses} status-completed`}>Completed</Badge> },
    { status: "active", value: 'active', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
  ];

  const statusDisplay = (status: string) => {
    const statusItem = statusList.find(item => item.status === status);
    return statusItem ? statusItem.display : null;
  };

  const fetchCountries = async () => {
    try {

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/datasource/countries`
      );

      let countries = response?.data;
      let dialcodeList: any = [];
      if (countries.length > 0) {
        countries.forEach((c: any) => {
          if (c.dialcode !== null) {
            if (c.dialcode in dialcodeList) {
              dialcodeList[c.dialcode] = dialcodeList[c.dialcode] + ', ' + c.country_name;
            } else {
              dialcodeList[c.dialcode] = '(+' + c.dialcode + ') ' + c.country_name;
            }
          }
        });
      }

      const sortedDialcodeList: any = [];
      Object.keys(dialcodeList).sort().forEach((key: any) => {
        sortedDialcodeList[key] = dialcodeList[key];
      });

      setCountries(countries);
      setDialcodeList(sortedDialcodeList);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const handleClickBack = () => {
    navigate(`/${userRole}/receiver`);
  }

  const handleResendKYC = (id: any) => {
    showResendKYCConfirmModal();
  };

  const showResendKYCConfirmModal = () => {
    setIsOpenResendKYCConfirmModal(true);
  }

  const closeResendKYCConfirmModal = () => {
    setIsOpenResendKYCConfirmModal(false);
  }

  const triggerReloadDetail = async () => {
    setIsLoading(true);
    await fetchReceiverDetail(id);
  }

  return (
    <>
      <Card className="mt-6 p-8 card">
        {!isLoading && receiverDetailForEdit !== null && countries !== null && dialcodeList !== null ? (
          <>

            <ReceiverResendKYCConfirmModal
              setSwalProps={setSwalProps}
              triggerReload={triggerReloadDetail}
              isOpen={isOpenResendKYCConfirmModal}
              receiverToResendKYC={receiverDetailForEdit}
              onClose={() => closeResendKYCConfirmModal()}
            />

            <div className="flex items-center mb-3">
              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                Receiver {!isLoading && receiverDetailForEdit !== null ? (<>{`(${receiverDetailForEdit.id})`}</>) : (<></>)}
              </h4>
              <div className="ml-auto flex items-center">
                <Button
                  onClick={() => handleClickBack()}
                  className="rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-200"
                >
                  <FaAngleLeft />&nbsp;Return to Receivers
                </Button>
              </div>
            </div>
            <hr className="mb-6 mt-6" />
            <div className="flex flex-wrap">
              <div className="mb-3 text-sm font-bold text-navy-700 dark:text-white">
                <div className="mb-3 flex items-center">
                  <span className="mr-1">KYC Status:</span>
                  {!isLoading && receiverDetailForEdit !== null ? (statusDisplay(receiverDetailForEdit.kyc_status)) : (<></>)}
                  {(receiverDetailForEdit.kyc_status === 'pending' || receiverDetailForEdit.kyc_status === 'failed') && userRole === 'superadmin' &&
                    <Tooltip title="Resend KYC Link" arrow placement="top">
                      <IconButton
                        className="ml-2"
                        id={"button-resend-kyc-" + receiverDetailForEdit.id}
                        onClick={() => handleResendKYC(receiverDetailForEdit.id)}
                      >
                        <BsPersonCheck className="h-6 w-6 text-navy-700" />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
                <div className={`${userRole === 'superadmin' ? "mb-3" : ""}`}><span className="mr-1">Status:</span>{!isLoading && receiverDetailForEdit !== null ? (statusDisplay(receiverDetailForEdit.status)) : (<></>)}</div>
                {userRole === 'superadmin' && receiverDetailForEdit.merchant &&
                  <div className="mb-3"><span className="mr-1">Merchant:</span><span className="font-normal">{!isLoading && receiverDetailForEdit !== null ? receiverDetailForEdit.merchant.name : (<></>)}</span></div>
                }
                {userRole === 'superadmin' && receiverDetailForEdit.subaccount &&
                  <div><span className="mr-1">Subaccount:</span><span className="font-normal">{!isLoading && receiverDetailForEdit !== null ? receiverDetailForEdit.subaccount.name : (<></>)}</span></div>
                }
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}
      </Card>

      <Tabs className="mt-6 card">
        <TabList className="flex rounded-tl-custom rounded-tr-custom">
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || receiverDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Details</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || receiverDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Accounts</strong>
          </Tab>
          <Tab className={`child-tab px-6 py-3 bg-white text-navy-700 rounded-tl-custom rounded-tr-custom ${isLoading || receiverDetailForEdit === null ? 'pointer-events-none opacity-50' : ''}`}>
            <strong>Payout History</strong>
          </Tab>
        </TabList>
        <TabPanels className="border-t border-gray-200 bg-white rounded-bl-custom rounded-br-custom">
          <TabPanel className="p-8 rounded-bl-custom rounded-br-custom">
            <ReceiverDetailTab detail={receiverDetailForEdit} setSwalProps={setSwalProps} countries={countries} dialcodeList={dialcodeList} userPermissions={userPermissions}/>
          </TabPanel>
          <TabPanel className="">
            <ReceiverAccountsTab detail={receiverDetailForEdit} userPermissions={userPermissions} />
          </TabPanel>
          <TabPanel className="">
            <ReceiverPayoutHistoryTab detail={receiverDetailForEdit} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default ReceiverDetail;
