import React, { createContext, useReducer, ReactNode } from "react";

// Define types for context values
interface ProfileContextType {
  profileTimeZone: any;
  setProfileTimeZone: (payload: any) => void;
}

// Define initial state type
interface InitialStateType {
  profileTimeZone: any;
}

// Define action type
type ActionType = {
  type: any;
  payload: any;
};

// Create context with initial empty object
export const ProfileContext = createContext<ProfileContextType | null>(null);

const initialState: InitialStateType = {
  profileTimeZone: localStorage.getItem("time_zone") || "UTC"
};

const profileReducer = (state: InitialStateType, action: ActionType) => {
  switch (action.type) {
    case "SET_PROFILE_TIME_ZONE":
      return {
        ...state,
        profileTimeZone: action.payload,
      };
    default:
      return state;
  }
};

export const ProfileProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [profileState, profileDispatch] = useReducer(
    profileReducer,
    initialState
  );

  const { profileTimeZone } = profileState;

  const setProfileTimeZone = (payload: any) =>
    profileDispatch({ type: "SET_PROFILE_TIME_ZONE", payload });

  return (
    <ProfileContext.Provider value={{ profileTimeZone, setProfileTimeZone }}>
      {children}
    </ProfileContext.Provider>
  );
};
