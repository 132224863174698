/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, ReactElement } from "react";
import { Select, Button, Badge, Divider, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const DetailsTab: React.FC<{
  data: any;
}> = ({ data }) => {
  const [details, setDetails] = useState<any>(null);

  interface MerchantStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: MerchantStatusItems[] = [
    { status: "yes", value: 'yes', display: <Badge className={`${statusClasses} status-active`}>Enabled</Badge> },
    { status: "no", value: 'no', display: <Badge className={`${statusClasses} status-inactive`}>Disabled</Badge> },
  ];

  const statusDisplay = (status: string) => {
    const statusItem = statusList.find(item => item.status === status);
    return statusItem ? statusItem.display : null;
  };

  useEffect(() => {
    setDetails(data);
  }, [data]);

  return (
    <div className="p-7">
      {details !== null ? (
        <>
          <div className="flex flex-wrap justify-between">
            <div className="w-full">
              <div>
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Name
                </label>
                <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                  {details.name}
                </div>
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Support Email
                </label>
                <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                  {details.support_email}
                </div>
                <hr className="my-4 border-t border-gray-200" />
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Restricted IPs
                </label>
                <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                  {details.restricted_ips}
                </div>
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Batch Postback
                </label>
                <div className="flex h-10 w-full items-center bg-white/0 text-sm mb-3 dark:!border-white/10 dark:text-white">
                  {statusDisplay(details.is_batch_postback)}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
        </>
      )}
    </div>
  );
};

export default DetailsTab;
