/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import MerchantList from "./components/MerchantList";
import MerchantSearch from "./components/MerchantSearch";
import { FaPlus } from "react-icons/fa";
import { Button } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";

interface Filters {
  [key: string]: any;
}

const rowPerPage = 10;

const initialFilters: Filters = {
  name: ""
};

interface PayloadType {
  [key: string]: any;
}

const Merchant = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); 
      await fetchMerchants({ page: 0, limit: rowPerPage, lastkey: "", ...filters });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const fetchMerchants = async (payload: PayloadType = {}) => {
    try {
      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        with_balance: true
      };

      if(payload?.name !== "" && payload?.name !== undefined) {
        queryObject.name = payload?.name
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/merchant/get-all-merchants?${queryString}`
      );

      let newData = response.data.merchants?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);

      let newLastKey = response.data.merchants?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      ...filters
    };

    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchMerchants(newFilters);
  };

  // const handleClickCreate = () => {
  //   navigate(`/${userRole}/merchant-management/create`);
  // };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    setCurrentLastKey("");
    fetchMerchants({ page: 0, limit: rowPerPage, lastkey: "", ...filters });
  };

  const handleSearchClear = () => {
    handleResetTable();
  };

  const handleResetTable = () => {
    setIsLoading(true);
    setFilters(initialFilters);
    setLastKeys([]);
    setCurrentLastKey("");
    setPage(0)
    fetchMerchants({ page: 0, limit: rowPerPage, lastkey: "", ...initialFilters });
  };

  return (
    <>
      {/* {(userRole === 'admin' || userRole === 'merchant') &&
        <div className="flex justify-start mt-6 mb-3">
          <Button
            onClick={() => handleClickCreate()}
            className="rounded-xl bg-green-500 px-3 py-2.5 text-sm font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
          >
            <FaPlus />&nbsp;Create Merchant
          </Button>
        </div>
      } */}
      <MerchantSearch
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
      />
      <MerchantList
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
      />
    </>
  );
};

export default Merchant;
