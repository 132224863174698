/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useContext } from "react";
import { Box } from "@mui/material";
import { Button, Badge } from '@chakra-ui/react'
import { FaPlus } from "react-icons/fa";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import CreateReceiverAccountModal from "./CreateReceiverAccountModal";
import UpdateReceiverAccountModal from "./UpdateReceiverAccountModal";
import ReceiverAccountDetailsModal from "../../shared/ReceiverAccountDetailsModal";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineEdit, AiOutlineInfoCircle } from "react-icons/ai";
import SweetAlert2 from "react-sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { BsFillSendCheckFill } from "react-icons/bs";
import ResendAccountConfirmModal from "./ResendAccountConfirmModal";
import { ProfileContext } from "../../../../store/ProfileProvider";

dayjs.extend(utc);
dayjs.extend(timezone);

type RowData = {
  id: string;
  created_at: string;
  asset: string;
  method_name: string;
  confirm_status: string;
};

const ReceiverAccountList: React.FC<{
  userPermissions: any
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  receiverId: string;
  triggerReloadReceiverAccountList(): void;
}> = ({ userPermissions, isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey, receiverId, triggerReloadReceiverAccountList }) => {
  
  const { profileTimeZone } = useContext(ProfileContext);
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [isOpenCreateReceiverAccountModal, setIsOpenCreateReceiverAccountModal] = useState<boolean>(false);
  const [isOpenUpdateReceiverAccountModal, setIsOpenUpdateReceiverAccountModal] = useState<boolean>(false);
  const [receiverAccountDetailToUpdate, setReceiverAccountDetailToUpdate] = useState<any>(null);
  
  const [isOpenReceiverAccountDetailModal, setIsOpenReceiverAccountDetailModal] = useState<boolean>(false);
  const [receiverAccountDetailToDisplay, setReceiverAccountDetailToDisplay] = useState<any>(null);

    const [isOpenResendAccountConfirmModal, setIsOpenResendAccountConfirmModal] = useState<boolean>(false);
    const [receiverAccountDetailForResendConfirm, setReceiverAccountDetailForResendConfirm] = useState(null);

  const [swalProps, setSwalProps] = useState<any>({});
  
  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }
  
  const statusClasses = 'status';

  const statusList: CustomerStatusItems[] = [
    { status: "unconfirmed", value: 'unconfirmed', display: <Badge className={`${statusClasses} status-unconfirmed`}>Unconfirmed</Badge> },
    { status: "sent_mail", value: 'sent_mail', display: <Badge className={`${statusClasses} status-sent_mail`}>Email Sent</Badge> },
    { status: "confirmed", value: 'confirmed', display: <Badge className={`${statusClasses} status-confirmed`}>Confirmed</Badge> },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 350,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        params.row.id,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      flex: 1,
      minWidth: 175,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "asset", headerName: "Asset", minWidth: 80, flex: 1, sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.asset
    },
    {
      field: "method_name", headerName: "Method", flex: 1, minWidth: 80, sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.method_name
    },
    {
      field: "confirm_status", headerName: "Confirm Status", flex: 1, minWidth: 140, sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const customerStatus = params.value as string;
        const statusItem = statusList.find(item => item.status === customerStatus);
        return statusItem ? statusItem.display : null;
      }
    },
    {
      field: "actions", headerName: "", sortable: false, flex: 1, minWidth: 140,
      renderCell: (params) => {
        return (
          <>
            {/* {
              userRole === "superadmin" &&
              <Tooltip title="Update" arrow placement="top">
                <div className="mr-5">
                  <IconButton
                    id={"button-update-" + params?.row?.id}
                    onClick={() => handleClickUpdate(params.row.id)}
                  >
                    <AiOutlineEdit className="h-5 w-5 text-navy-700" />
                  </IconButton>
                </div>
              </Tooltip>
            } */}
            <Tooltip title="Details" arrow placement="top">
              <IconButton
                id={"button-details-" + params?.row?.id}
                onClick={() => handleClickDetails(params.row.id)}
              >
                <AiOutlineInfoCircle className="h-5 w-5 text-navy-700" />
              </IconButton>
            </Tooltip>
            {params?.row?.confirm_status === 'sent_mail' && userRole === 'superadmin' &&
              <Tooltip title="Resend Confirmation Link" arrow placement="top">
                <IconButton
                  id={"button-resend-account-confirm-" + params?.row?.id}
                  onClick={() => handleResendAccount(params.row.id)}
                >
                  <BsFillSendCheckFill className="h-5 w-5 text-navy-700 opacity-90" />
                </IconButton>
              </Tooltip>
            }
          </>
        );
      },
    },
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(profileTimeZone).format("DD MMM YY - hh:mm A");
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start',
    row: ''
  };

  const showAddAccountModal = () => {
    setIsOpenCreateReceiverAccountModal(true);
  }

  const closeAddAccountModal = () => {
    setIsOpenCreateReceiverAccountModal(false);
  }

  const handleClickUpdate = (id: any) => {
    const receiverAccount = tableData.filter((x: any) => x.id === id)[0];
    setReceiverAccountDetailToUpdate(receiverAccount);
    showUpdateAccountModal();
  };

  const showUpdateAccountModal = () => {
    setIsOpenUpdateReceiverAccountModal(true);
  }

  const closeUpdateAccountModal = () => {
    setReceiverAccountDetailToUpdate(null);
    setIsOpenUpdateReceiverAccountModal(false);
  }

  const handleClickDetails = (id: any) => {
    const receiverAccount = tableData.filter((x: any) => x.id === id)[0];
    setReceiverAccountDetailToDisplay(receiverAccount);
    showAccountDetailsModal();
  };

  const showAccountDetailsModal = () => {
    setIsOpenReceiverAccountDetailModal(true);
  }

  const closeAccountDetailsModal = () => {
    setReceiverAccountDetailToDisplay(null);
    setIsOpenReceiverAccountDetailModal(false);
  }

  const triggerReloadListForUpdate = () => {
    setReceiverAccountDetailToUpdate(null);
    triggerReloadReceiverAccountList();
  }

  const handleResendAccount = (id: any) => {
    const receiverAccount = tableData.filter((x: any) => x.id === id)[0];
    setReceiverAccountDetailForResendConfirm(receiverAccount);
    showResendAccountConfirmModal();
  };

  const showResendAccountConfirmModal = () => {
    setIsOpenResendAccountConfirmModal(true);
  }

  const closeResendAccountConfirmModal = () => {
    setReceiverAccountDetailForResendConfirm(null);
    setIsOpenResendAccountConfirmModal(false);
  }

  const triggerReloadListForResendConfirm = () => {
    setReceiverAccountDetailForResendConfirm(null);
    triggerReloadReceiverAccountList();
  }

  return (
    <div className="p-8">

      {
        receiverId !== null ?
          <>
            <CreateReceiverAccountModal setSwalProps={setSwalProps} triggerReloadReceiverAccountList={triggerReloadReceiverAccountList} receiverId={receiverId} isOpen={isOpenCreateReceiverAccountModal} onClose={() => closeAddAccountModal()} />
            { receiverAccountDetailToUpdate &&
              <UpdateReceiverAccountModal setSwalProps={setSwalProps} triggerReloadReceiverAccountList={triggerReloadListForUpdate} receiverAccountDetail={receiverAccountDetailToUpdate} isOpen={isOpenUpdateReceiverAccountModal} onClose={() => closeUpdateAccountModal()} />
            }
            { receiverAccountDetailForResendConfirm &&
              <ResendAccountConfirmModal setSwalProps={setSwalProps} triggerReload={triggerReloadListForResendConfirm} receiverAccountToResendConfirm={receiverAccountDetailForResendConfirm} isOpen={isOpenResendAccountConfirmModal} onClose={() => closeResendAccountConfirmModal()} />
            }
            { receiverAccountDetailToDisplay &&
              <ReceiverAccountDetailsModal detail={receiverAccountDetailToDisplay} isOpen={isOpenReceiverAccountDetailModal} onClose={() => closeAccountDetailsModal()} />
            }
          </>
          : null
      }

      <div className="flex mb-6">
        {userPermissions.addReceiverAccount &&
          <Button
            isDisabled={isLoading}
            onClick={() => showAddAccountModal()}
            className="btn btn-action"
          >
            <FaPlus />&nbsp;Add New Account
          </Button>
        }
      </div>
      <Box
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={40}
          columnHeaderHeight={40}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>      

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
    </div>
  );
};

export default ReceiverAccountList;