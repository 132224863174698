// MerchantDropdown.tsx
import React from "react";
import { Spinner } from "@chakra-ui/spinner";

const MerchantDropdown = ({ isDisabled, newUser, handleInputChange, merchants }: any) => {
  const handleOptionClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleInputChange({
      target: { name: "merchant", value: event.target.value },
    });
  };

  return (
    <div>
      <select
        id="merchant"
        defaultValue={newUser.merchant}
        onChange={handleOptionClick}
        className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm"
        disabled={merchants === null || merchants.length === 0 || isDisabled}
      >
        {merchants === null ? (
          <option>Retreiving...</option>
        ) : merchants.length === 0 ? (
          <option>Retreiving...</option>
        ) : (
          <>
            {merchants.map((merchant: any) => (
              <option key={merchant.id} value={merchant.id}>
                {merchant.name}
              </option>
            ))}
          </>
        )}
      </select>
      {merchants === null && (
        <div className="mt-2 flex justify-center">
          <Spinner size="sm" />
        </div>
      )}
    </div>
  );
};

export default MerchantDropdown;
